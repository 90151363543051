import { Avatar } from '@mui/material';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';

export default function PreviewImage({ imgSrc }: any) {
  return (
    <>
      {imgSrc ? (
        <Avatar
          src={imgSrc}
          variant="rounded"
          sx={{ width: '100px', height: '100px', objectFit: 'cover' }}
        />
      ) : (
        <CircularIndeterminate />
      )}
    </>
  );
}
