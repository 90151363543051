import { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import {
  MainWrapper,
  TitleWrapper,
  Title,
  InfosWrapper,
  InfoMainWrapper,
  InfoTitle,
  ItemTitle,
  DateWrapper,
  DescriptionWrapper,
  ExperienceWrapper,
  ExperienceLogo,
  ExperienceInfoWrapper,
  Name,
  Position,
  DataLocation,
  VideoWrapper,
  Wrapper,
  AvatarWrapper,
} from './styles';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import ReactPlayer from 'react-player';
import { PlayerIconWrapper } from '../../../../../../components/Card/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTranslation } from 'react-i18next';
import { formatDateWithNumbers } from '../../../../../../utils/formaters/formatDate';
import digitalBookBackground from '../../../../../../assets/images/digital_book_background.png';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

export default function MoreInfos() {
  //hooks
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [showControls, setShowControls] = useState<boolean>(false);

  //constants
  const userVideo = user?.files?.find((cv) => cv.fileTypeId === 2);
  const userDigitalBook = user?.files?.find((cv) => cv.fileTypeId === 5);

  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t('more_info.title')}</Title>
      </TitleWrapper>
      <InfosWrapper>
        <InfoMainWrapper>
          <InfoTitle>{t('basic_info.video_presentation')}</InfoTitle>
          {userVideo ? (
            <VideoWrapper>
              {!showControls && (
                <PlayerIconWrapper onClick={() => setShowControls(true)}>
                  <PlayArrowIcon fontSize='large' sx={{ color: 'white' }} />
                </PlayerIconWrapper>
              )}
              <ReactPlayer
                url={userVideo.filePath}
                controls={showControls}
                width='100%'
                height='120px'
                playing={showControls}
                onEnded={() => setShowControls(false)}
              />
            </VideoWrapper>
          ) : (
            <ItemTitle>{t('no_video_provided')}</ItemTitle>
          )}
        </InfoMainWrapper>

        <InfoMainWrapper>
          <InfoTitle>{t('digital_book')}</InfoTitle>
          {userDigitalBook ? (
            <a
              href={userDigitalBook.filePath}
              target='_blank'
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                border: '1px solid #F4F4F4',
                borderRadius: '10px',
                height: '200px',
                cursor: 'pointer',
                background: `url(${digitalBookBackground})`,
                backgroundSize: '100% 200px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <VisibilityRoundedIcon sx={{ fill: '#72C2CC' }} />
              <p
                style={{
                  color: '#72C2CC',
                  fontFamily: 'Montserrat',
                }}
              >
                {t('digital_book')}
              </p>
            </a>
          ) : (
            <ItemTitle>{t('no_digital_book_provided')}</ItemTitle>
          )}
        </InfoMainWrapper>

        <InfoMainWrapper>
          <InfoTitle>{t('more_info.experiences')}</InfoTitle>
          {user && user.workExperience && user.workExperience.length > 0 ? (
            user.workExperience.map((experience, index) => {
              return (
                <ExperienceWrapper key={index}>
                  {experience?.companyLogo ? (
                    <ExperienceLogo src={experience.companyLogo} />
                  ) : (
                    <AvatarWrapper>
                      <BusinessCenterRoundedIcon sx={{ color: 'white' }} />
                    </AvatarWrapper>
                  )}
                  <ExperienceInfoWrapper>
                    <Name>{experience.enterpriseName}</Name>
                    <Position>{experience.position}</Position>
                    <DataLocation>
                      {formatDateWithNumbers(
                        experience.startMonth,
                        experience.startYear,
                        experience.endMonth,
                        experience.endYear
                      )}
                      &nbsp; | {experience.location}
                    </DataLocation>
                  </ExperienceInfoWrapper>
                </ExperienceWrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t(
                'editProfile.edit_candidate_profile.experience.no_experiences'
              )}
            </ItemTitle>
          )}
        </InfoMainWrapper>
        <InfoMainWrapper>
          <InfoTitle>{t('more_info.educations')}</InfoTitle>
          {user && user.educations && user.educations.length > 0 ? (
            user.educations.map((education, index) => {
              return (
                <Wrapper key={index}>
                  <ItemTitle>
                    {education.fieldOfStudy} | {education.educationName}
                  </ItemTitle>
                  <DateWrapper>
                    {formatDateWithNumbers(
                      education.startMonth,
                      education.startYear,
                      education.endMonth,
                      education.endYear
                    )}
                  </DateWrapper>
                  <DescriptionWrapper>
                    {education.description}
                  </DescriptionWrapper>
                </Wrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t('editProfile.edit_candidate_profile.education.no_educations')}
            </ItemTitle>
          )}
        </InfoMainWrapper>
        <InfoMainWrapper>
          <InfoTitle>{t('more_info.accomplishments')}</InfoTitle>
          {user && user.accomplishments && user.accomplishments.length > 0 ? (
            user?.accomplishments.map((accomplishment, index) => {
              return (
                <Wrapper key={index}>
                  <ItemTitle>{accomplishment.accomplishmentName}</ItemTitle>
                  <DateWrapper>
                    {formatDateWithNumbers(
                      accomplishment.startMonth,
                      accomplishment.startYear,
                      accomplishment.endMonth,
                      accomplishment.endYear
                    )}
                  </DateWrapper>
                  <DescriptionWrapper>
                    {accomplishment.description}
                  </DescriptionWrapper>
                </Wrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t(
                'editProfile.edit_candidate_profile.accomplishment.no_accomplishments'
              )}
            </ItemTitle>
          )}
        </InfoMainWrapper>
        <InfoMainWrapper>
          <InfoTitle>{t('more_info.certifications')}</InfoTitle>
          {user && user.certifications && user.certifications.length > 0 ? (
            user.certifications.map((certification, index) => {
              return (
                <Wrapper key={index}>
                  <ItemTitle>{certification.name}</ItemTitle>
                  <DateWrapper>
                    {formatDateWithNumbers(
                      certification.startMonth,
                      certification.startYear,
                      certification.endMonth,
                      certification.endYear
                    )}
                  </DateWrapper>
                  <DescriptionWrapper>
                    {certification.description}
                  </DescriptionWrapper>
                </Wrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t(
                'editProfile.edit_candidate_profile.certification.no_certifications'
              )}
            </ItemTitle>
          )}
        </InfoMainWrapper>
      </InfosWrapper>
    </MainWrapper>
  );
}
