import LoadingButton from '@mui/lab/LoadingButton';
import { colors } from './../../../../../../../lib/theme';
import { Typography, Button } from '@mui/material';
import styled from 'styled-components';

const { businessCardBg, plan_border, text_blue } = colors;

export const PlansWrapper = styled.div`
  display: grid;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const PlanWrapper = styled.div`
  border: 1px solid ${plan_border};
  border-radius: 10px;
  padding: 1rem;
`;

export const PlanName = styled(Typography)`
  font-size: 14px;
  font-family: 'Impact';
`;

export const PlanDescription = styled(Typography)`
  font-size: 12px;
  color: ${businessCardBg};
`;

export const PlanPrice = styled(Typography)`
  font-size: 22px;
  font-weight: bold;
`;

export const PlanCredits = styled(Typography)`
  margin-left: 0.2rem;
  font-size: 14px;
`;

export const PlanDetails = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
`;

export const PlanPayButton = styled(LoadingButton)<{ name: string }>`
  text-transform: none;
  margin-top: 1rem;
  background-color: ${(props) => (props.name === 'Pro' ? text_blue : '')};
  color: ${(props) => (props.name === 'Pro' ? 'white' : 'black')};
  width: 100%;
  border: ${(props) => props.name !== 'Pro' && ' 1px solid #9D9D9D'};
`;

export const FieldSet = styled.fieldset`
  padding: 0.5rem 1rem;
  border: 1px solid #487bd9;
  border-radius: 10px;
  display: flex;
  margin-top: -0.9rem;
  flex-direction: column;
`;

export const Legend = styled.legend`
  background: #487bd9;
  border-radius: 3px;
  text-align: center;
  color: white;
  padding: 0.5rem;
  font-family: 'Montserrat';
  font-size: 10px;
  text-transform: uppercase;
`;

export const ExclusiveVAT = styled(Typography)`
  font-family: 'Georgia';
  color: #999a99;
  font-size: 12px;
  width: 90%;
`;
