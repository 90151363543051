import {
  MainWrapper,
  TitleWrapper,
  Title,
  InfosWrapper,
  InfoMainWrapper,
  InfoTitle,
  ItemTitle,
  DateWrapper,
  DescriptionWrapper,
  ExperienceWrapper,
  ExperienceLogo,
  ExperienceInfoWrapper,
  Name,
  Position,
  DataLocation,
  Wrapper,
  AvatarWrapper,
} from '../../../student-dashboard/ProfileInfo/MoreInfos/styles';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import { useTranslation } from 'react-i18next';
import { formatDateWithNumbers } from '../../../../../../utils/formaters/formatDate';

export default function MoreInformation({ data }: any) {
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t('more_info.title')}</Title>
      </TitleWrapper>
      <InfosWrapper>
        <InfoMainWrapper>
          <InfoTitle>{t('more_info.experiences')}</InfoTitle>
          {data && data?.workExperience?.length > 0 ? (
            data?.workExperience?.map((experience: any) => {
              return (
                <ExperienceWrapper>
                  {experience?.companyLogo ? (
                    <ExperienceLogo src={experience.companyLogo} />
                  ) : (
                    <AvatarWrapper>
                      <BusinessCenterRoundedIcon sx={{ color: 'white' }} />
                    </AvatarWrapper>
                  )}
                  <ExperienceInfoWrapper>
                    <Name>{experience.enterpriseName}</Name>
                    <Position>{experience.position}</Position>
                    <DataLocation>
                      {formatDateWithNumbers(
                        experience.startMonth,
                        experience.startYear,
                        experience.endMonth,
                        experience.endYear
                      )}
                      &nbsp; | {experience.location}
                    </DataLocation>
                  </ExperienceInfoWrapper>
                </ExperienceWrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t(
                'editProfile.edit_candidate_profile.experience.no_experiences'
              )}
            </ItemTitle>
          )}
        </InfoMainWrapper>
        <InfoMainWrapper>
          <InfoTitle>{t('more_info.educations')}</InfoTitle>
          {data && data?.educations?.length > 0 ? (
            data?.educations?.map((education: any) => {
              return (
                <Wrapper>
                  <ItemTitle>
                    {education.fieldOfStudy} | {education.educationName}
                  </ItemTitle>

                  <DateWrapper>
                    {formatDateWithNumbers(
                      education.startMonth,
                      education.startYear,
                      education.endMonth,
                      education.endYear
                    )}
                  </DateWrapper>
                  <DescriptionWrapper>
                    {education.description}
                  </DescriptionWrapper>
                </Wrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t('editProfile.edit_candidate_profile.education.no_educations')}
            </ItemTitle>
          )}
        </InfoMainWrapper>
        <InfoMainWrapper>
          <InfoTitle>{t('more_info.accomplishments')}</InfoTitle>
          {data && data?.accomplishments?.length > 0 ? (
            data?.accomplishments?.map((accomplishment: any) => {
              return (
                <Wrapper>
                  <ItemTitle>{accomplishment.accomplishmentName}</ItemTitle>
                  <DateWrapper>
                    {formatDateWithNumbers(
                      accomplishment.startMonth,
                      accomplishment.startYear,
                      accomplishment.endMonth,
                      accomplishment.endYear
                    )}
                  </DateWrapper>
                  <DescriptionWrapper>
                    {accomplishment.description}
                  </DescriptionWrapper>
                </Wrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t(
                'editProfile.edit_candidate_profile.accomplishment.no_accomplishments'
              )}
            </ItemTitle>
          )}
        </InfoMainWrapper>
        <InfoMainWrapper>
          <InfoTitle>Certifications</InfoTitle>
          {data && data?.certifications?.length > 0 ? (
            data?.certifications?.map((certification: any) => {
              return (
                <Wrapper>
                  <ItemTitle>{certification.name}</ItemTitle>
                  <DateWrapper>
                    {formatDateWithNumbers(
                      certification.startMonth,
                      certification.startYear,
                      certification.endMonth,
                      certification.endYear
                    )}
                  </DateWrapper>
                  <DescriptionWrapper>
                    {certification.description}
                  </DescriptionWrapper>
                </Wrapper>
              );
            })
          ) : (
            <ItemTitle>
              {t(
                'editProfile.edit_candidate_profile.certification.no_certifications'
              )}
            </ItemTitle>
          )}
        </InfoMainWrapper>
      </InfosWrapper>
    </MainWrapper>
  );
}
