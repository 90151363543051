import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { FlexBetween, FlexBox } from '../Flexbox';
import { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import { Notifications } from '@mui/icons-material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import { EmptyView } from '../EmptyView/EmptyView';
import { useFetchNotificationQuery } from '../../rq/hooks/useQueries';
import {
  useReadNotificationMutation,
  useReadNotificationsMutation,
} from '../../rq/hooks/useNotification.mutation';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Date, NotificationDescription, NotificationTitle } from './styles';
import { NotificationModel } from '../../interfaces/Notification.model';
import { AuthContext } from '../../lib/context/AuthContext';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(isToday);

export const NotificationsPopover: FC = () => {
  const [pageSize, setPageSize] = useState<number>(5);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);

  // hooks
  const { pathname } = useLocation();

  // react query
  const { data = [], refetch, isLoading } = useFetchNotificationQuery();
  const { mutate: readAllNotifications } = useReadNotificationsMutation();
  const { mutate: readNotification } = useReadNotificationMutation();

  // effects
  useEffect(() => {
    refetch();
  }, [pathname]);

  useEffect(() => {
    if (!isLoading && data.length !== 0) setNotifications(data);
  }, [data, isLoading]);

  // handlers
  const handleFetchMoreOrLess = () => {
    if (pageSize === 5) setPageSize(10);
    else setPageSize(5);
  };
  const handleReadNotifications = () => readAllNotifications();
  const handleReadNotification = (id: number, isRead: boolean) => () => {
    if (isRead) return;
    readNotification(id);
  };
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setOpen((state) => !state);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  // constants
  const allNotifications = notifications
    .sort((a, b) =>
      dayjs(b.insertedDate).isAfter(dayjs(a.insertedDate)) ? 1 : -1
    )
    .sort((a, b) => +a.isRead - +b.isRead)

    .slice(0, pageSize);
  const readNotifications = notifications.filter(
    (notification) => notification.isRead
  );
  const unreadNotifications = notifications.filter(
    (notification) => !notification.isRead
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);
  const notificationType = [
    'Reached100Views',
    'Reached50Likes',
    'Reached10Stars',
  ];

  const handleNotification = (notification: any) => {
    if (notificationType.some((v) => notification.includes(v))) {
      navigate(`/${role}/profile/notification/${notification}`);
    }
  };

  return (
    <Box>
      <div
        style={{
          backgroundColor: '#F5F5F5',
          borderRadius: '50%',
        }}
      >
        <IconButton onClick={handleClick}>
          <Badge
            color="error"
            variant="dot"
            invisible={unreadNotifications.length === 0}
          >
            <Notifications sx={{ color: 'black', fontSize: '15px' }} />
          </Badge>
        </IconButton>
      </div>

      <Menu
        sx={{ marginTop: '50px' }}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            overflow: 'hidden',
          },
        }}
        MenuListProps={{
          sx: {
            maxWidth: 400,
          },
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FlexBetween p={1.5} borderBottom="1px solid #ddd">
          <Typography fontWeight="bold" fontSize={{ xs: 15, lg: 20 }}>
            {t('notifications.title')}
          </Typography>
          <Button
            variant="text"
            disabled={unreadNotifications.length === 0}
            onClick={handleReadNotifications}
            sx={{
              textTransform: 'none',
              color: 'black',
            }}
          >
            {t('notifications.mark_all_as_read')}
          </Button>
        </FlexBetween>

        <EmptyView condition={notifications.length !== 0}>
          <FlexBox
            p="15px 0 20px 10px"
            flexDirection="column"
            sx={{ maxHeight: pageSize === 10 ? 600 : 650, overflowY: 'scroll' }}
          >
            {allNotifications.map((notification, index) => {
              return (
                <Box key={notification.id}>
                  {unreadNotifications.length !== 0 && index === 0 && (
                    <FlexBox alignItems="center" gap={2} pl={3} pb={1}>
                      <Typography fontSize={15}>
                        {t('notifications.new')}
                      </Typography>
                      <Badge
                        sx={{
                          color: 'red',
                        }}
                        badgeContent={unreadNotifications.length}
                        componentsProps={{
                          badge: {
                            style: {
                              backgroundColor: '#e3f1f8',
                              borderRadius: 5,
                            },
                          },
                        }}
                      />
                    </FlexBox>
                  )}
                  {readNotifications.length !== 0 &&
                    notification.isRead &&
                    !allNotifications[index - 1]?.isRead && (
                      <FlexBox alignItems="center" gap={2} pl={3} pb={1} pt={2}>
                        <Typography fontSize={15}>
                          {t('notifications.earlier')}
                        </Typography>
                        <Badge
                          sx={{
                            color: '#59AED5',
                          }}
                          badgeContent={readNotifications.length}
                          componentsProps={{
                            badge: {
                              style: {
                                backgroundColor: '#e3f1f8',
                                borderRadius: 5,
                              },
                            },
                          }}
                        />{' '}
                      </FlexBox>
                    )}

                  <FlexBox
                    component={MenuItem}
                    onClick={handleReadNotification(
                      notification.id,
                      notification.isRead
                    )}
                    p={2}
                    gap={2}
                    {...(!notification.isRead && { bgcolor: '#f8fcfd' })}
                  >
                    <Avatar src={notification.senderProfilePicture || ''} />
                    <FlexBox
                      flexDirection="column"
                      flex={1}
                      onClick={() =>
                        handleNotification(notification.notificationType)
                      }
                    >
                      <NotificationTitle
                        fontWeight="bold"
                        whiteSpace="break-spaces"
                      >
                        {notification.title}
                      </NotificationTitle>
                      <NotificationDescription
                        fontWeight="semi-bold"
                        whiteSpace="break-spaces"
                      >
                        {notification.description}
                      </NotificationDescription>
                    </FlexBox>
                    <FlexBox flexDirection="column" gap={2} marginLeft="auto">
                      <Date>
                        {dayjs(notification.insertedDate).isYesterday()
                          ? 'Yesterday'
                          : dayjs().isToday()
                          ? dayjs
                              .utc(notification.insertedDate)
                              .format('h:mm A')
                          : dayjs
                              .utc(notification.insertedDate)
                              .format('DD MMM')}
                      </Date>
                      {!notification.isRead && (
                        <Badge
                          sx={{
                            width: 10,
                            height: 10,
                            backgroundColor: '#72c2cc',
                            borderRadius: 100,
                            alignSelf: 'flex-end',
                          }}
                          variant="dot"
                        />
                      )}
                    </FlexBox>
                  </FlexBox>
                </Box>
              );
            })}
          </FlexBox>

          <Box display="flex" justifyContent="center" py={2}>
            <Button
              variant="text"
              style={{ fontSize: 16 }}
              sx={{ textTransform: 'none', color: 'black' }}
              onClick={handleFetchMoreOrLess}
            >
              {pageSize === 5
                ? t('notifications.view_more')
                : t('notifications.view_less')}
            </Button>
          </Box>
        </EmptyView>
      </Menu>
    </Box>
  );
};
