import { Grid, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { BusinessInputs } from '../../../../interfaces/types/User';
import SectorOfCompanySelect from '../SectorOfCompanySelect/SectorOfCompanySelect';
import { useBusinessTypeQuery } from '../../../../rq/hooks/useQueries';
import { InputField } from '../../InputField/InputField';
import { useTranslation } from 'react-i18next';

export default function BusinessBasicInformation() {
  const { data: businessTypes } = useBusinessTypeQuery();

  const {
    register,
    formState: {
      errors: { name, numberOfWorkers },
    },
  } = useFormContext<BusinessInputs>();

  //   constants

  const businessType =
    businessTypes?.map((businessType) => ({
      label: businessType.name,
      value: businessType.id,
    })) || [];

  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={12} sm={12}>
        <InputField
          label={t('basic_info.company_name')}
          type="input"
          fullWidth
          size="small"
          {...register('name')}
          fieldError={name}
        />
      </Grid>
      <SectorOfCompanySelect
        label={t('basic_info.sector_of_activity')}
        data={businessType}
      />
      <Grid item xs={12} sm={6}>
        <InputField
          label={t('register.business.number_of_workers')}
          type="number"
          fullWidth
          size="small"
          {...register('numberOfWorkers')}
          fieldError={numberOfWorkers}
        />
      </Grid>
    </Grid>
  );
}
