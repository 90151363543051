import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { PlayerIconWrapper } from './styles';
import {
  Icon,
  InfosWrapper,
  InfoWrapper,
  Location,
  Name,
  NoIconWrapper,
  TargetedVideoWrapper,
  VideoWrapper,
} from './styles';
import { Person } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function RightSideSingleBusiness({ data }: any) {
  const navigate = useNavigate();
  const [showControls, setShowControls] = useState<boolean>(false);
  return (
    <TargetedVideoWrapper>
      <InfosWrapper
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/candidate/profile/${data.candidateId}`)}
      >
        {data.profilePicture ? (
          <Icon src={data.profilePicture} />
        ) : (
          <NoIconWrapper>
            <Person sx={{ color: 'white' }} />
          </NoIconWrapper>
        )}
        <InfoWrapper>
          <Name>{data.firstName}</Name>
          <Location>
            {data.professions.map((prof: any) => prof).join(', ')}
          </Location>
        </InfoWrapper>
      </InfosWrapper>
      {/* <PositionWrapper></PositionWrapper> */}
      <VideoWrapper>
        {!showControls && (
          <PlayerIconWrapper onClick={() => setShowControls(true)}>
            <PlayArrowIcon fontSize="large" sx={{ color: 'white' }} />
          </PlayerIconWrapper>
        )}
        <ReactPlayer
          url={data.video}
          controls={showControls}
          width="100%"
          height="100px"
          playing={showControls}
          // onPlay={handleAddVideoView}
          onEnded={() => setShowControls(false)}
        />
      </VideoWrapper>
    </TargetedVideoWrapper>
  );
}
