import { Link, NavLink } from 'react-router-dom';
import { Card, Typography, Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../lib/theme';

const {
  businessCardBg,
  position_text_color,
  personCardBg,
  linkBackgroundColor,
} = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  border-radius: 20px;
`;

export const MainInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const AvatarWrapper = styled.div<{
  isCandidate: boolean;
}>`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: ${(props) => (props.isCandidate ? '50%' : '10px')};
  padding: 1rem;
`;

export const NameWrapper = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  font-family: 'Montserrat';
`;

export const PositionWrapper = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 12px;
  text-align: center;
  color: ${position_text_color};
`;

export const LinksListWrapper = styled.div`
  margin-top: 0.5rem;
  padding: 0 1rem;
`;

export const NoLinksListWrapper = styled.div`
  margin-top: 0.5rem;
  border-bottom: 1px solid ${businessCardBg};
  color: ${position_text_color};
  padding: 0 1rem;
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 15px;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 0.5rem 0 0.5rem 0;
  &:hover {
    background-color: ${linkBackgroundColor};
    color: ${personCardBg};
  }
  &.active {
    font-weight: bold;
    background-color: ${linkBackgroundColor};
    color: ${personCardBg};
  }
`;

export const StyledNoNavLink = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 15px;
  justify-content: space-between;
  padding: 0.5rem;
`;

export const Image = styled.img<{ isCandidate: boolean }>`
  border: 1px solid transparent;
  border-radius: ${(props) => (props.isCandidate ? '50%' : '10px')};
  width: 70px;
  height: 70px;
  object-fit: cover;
`;

export const CreditsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  padding: 0.5rem;
  background-color: rgba(114, 194, 204, 0.08);
  margin: 1rem;
  border-radius: 10px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0;
    width: 100%;
    align-items: normal;
  }
`;

export const CreditTitle = styled(Typography)`
  font-size: 10px;
`;

export const CreditValue = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`;

export const UpdateCvButton = styled(Button)`
  text-transform: none;
  color: white;
  background-color: ${businessCardBg};
  &:hover {
    background-color: ${businessCardBg};
  }
  margin: 0 1rem;
  border-radius: 20px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
    border-radius: 10px;
  }
`;
