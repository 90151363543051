import { NameWrapper, LocationWrapper, VideoWrapper } from './style';
import icon from '../../../assets/icons/my_first_job_icon.png';
import { Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlexBox } from '../../Flexbox';
import { useGetAllBusinessCardsQuery } from '../../../rq/hooks/useQueries';
import ReactPlayer from 'react-player';
import BusinessIcon from '@mui/icons-material/Business';
import CardSkeleton from '../../CardSkeleton/CardSkeleton';

export default function JobsAds() {
  /**
   * hooks
   */
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllBusinessCardsQuery(0, null, null, {
    keepPreviousData: true,
  });

  if (isLoading) return <CardSkeleton />;

  return (
    <FlexBox
      bgcolor="#1A2948"
      flexDirection="column"
      height="100vh"
      py={5}
      px={{ xs: 5, lg: 15 }}
      gap={4}
    >
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => navigate('/home')}
      >
        <img src={icon} />
      </Box>
      <FlexBox flexDirection="column" gap={5}>
        {data?.data?.slice(0, 2)?.map((job: any, index: any) => (
          <Box key={index}>
            <FlexBox alignItems="center" gap={2}>
              {job.profilePicture ? (
                <Avatar variant="rounded" src={job.profilePicture} />
              ) : (
                <Avatar variant="rounded">
                  <BusinessIcon />
                </Avatar>
              )}
              <FlexBox flexDirection="column">
                <NameWrapper>{job.name}</NameWrapper>
                <LocationWrapper>{job.country}</LocationWrapper>
              </FlexBox>
            </FlexBox>
            <VideoWrapper>
              <ReactPlayer
                url={job.video}
                controls
                width="100%"
                height="200px"
              />
            </VideoWrapper>
          </Box>
        ))}
      </FlexBox>
    </FlexBox>
  );
}
