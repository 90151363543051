import { Typography } from '@mui/material';
import { colors } from './../../../lib/theme';
import styled from 'styled-components';

const { businessCardBg } = colors;

export const Title = styled(Typography)`
  font-family: 'Impact';
  font-size: 30px;
  text-align: center;
  color: ${businessCardBg};
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 16px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 1rem;
  }
`;

export const Description = styled(Typography)`
  font-size: 20px;
  white-space: pre-line;
  text-align: center;
  font-weight: bold;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 14px;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 3rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: flex;
    grid-gap: 0;
    flex-direction: column;
  }
`;

export const AccountCreationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px; // margin-top: 3rem;
`;
