import { Button, Typography } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { FileName } from '../UploadCV/styles';
import { useTranslation } from 'react-i18next';

export default function UploadVideo() {
  const {
    register,
    watch,
    formState: {
      errors: { video },
    },
  } = useFormContext();
  const file = watch('video')?.[0];
  const { t } = useTranslation();

  return (
    <>
      <Typography component='p' className='font-montserrat font-bold mb-2'>
        {t('auth.sign_up.video_presentation')}
      </Typography>
      <Typography variant='subtitle2' className='font-montserrat mb-2'>
        {t('auth.sign_up.post_video')}
      </Typography>
      <Button variant='outlined' component='label' startIcon={<FileUpload />}>
        {t('auth.sign_up.upload_button')}
        <input
          hidden
          accept='video/mp4,video/x-m4v,video/*'
          type='file'
          {...register('video')}
        />
      </Button>
      {video ? (
        <Typography sx={{ color: 'red' }} variant='caption' display='block'>
          <>{video?.message}</>
        </Typography>
      ) : file ? (
        <FileName>{file.name}</FileName>
      ) : (
        ''
      )}
    </>
  );
}
