import { Card, Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../lib/theme';

const {
  businessCardBg,
  position_text_color,
  text_gray,
  discard_button_color,
  text_blue,
  personCardBg,
} = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${businessCardBg};
`;

export const Title = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

export const VideoWrapper = styled(Box)`
  margin-top: 1rem;
  position: relative;
  video {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 20px;
  }
`;

export const PlayerIconWrapper = styled(Box)`
  position: absolute;
  z-index: 10;
  color: white;
  display: inline-block;
  padding: 5px;
  top: 35%;
  left: 45%;
  background: #000000;
  opacity: 0.7;
  border-radius: 50%;
  cursor: pointer;
`;

export const FormPart = styled.div`
  display: grid;
  width: 100%;
  margin-top: 1rem;
  &:not(:first-child) {
    align-items: center;
  }
  grid-template-columns: 120px 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const FormPropWrapper = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

export const FormWrapper = styled.div`
  border-bottom: 1px solid ${businessCardBg};
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

export const AvatarWrapper = styled.div`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: 50%;
  padding: 1rem;
`;

export const Avatar = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const NameWrapper = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
`;

export const PositionWrapper = styled(Typography)`
  font-size: 12px;
  color: ${text_gray};
`;

export const DescriptionWrapper = styled(Typography)`
  font-size: 12px;
`;
