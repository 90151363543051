import * as yup from 'yup';

const FILE_SIZE = 50000000;
const FILE_SIZE_VIDEO = 150000000;

export const RegisterCandidateFormSchema = (t: any) =>
  yup.object({
    firstName: yup.string().required(t('formErrors.required')),
    lastName: yup.string().required(t('formErrors.required')),
    email: yup
      .string()
      .email(t('formErrors.email'))
      .required(t('formErrors.required')),
    password: yup
      .string()
      .required(t('formErrors.required'))
      .min(8, t('formErrors.password.short'))
      .matches(/[0-9]/, t('formErrors.password.number'))
      .matches(/[a-z]/, t('formErrors.password.lowerCase'))
      .matches(/[A-Z]/, t('formErrors.password.upperCase'))
      .matches(/[^\w]/, t('formErrors.password.symbol')),
    phoneNumber: yup.string().required(t('formErrors.required')),
    profilePicture: yup
      .mixed()
      .test('required', t('formErrors.profile_picture'), (value) => {
        return value;
      })
      .test('fileSize', t('formErrors.large_file'), (value) => {
        return value && value?.size <= FILE_SIZE;
      }),
    address: yup.string().notRequired(),
    postalCode: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    CandidateSelectedEducations: yup.array().min(1, t('formErrors.required')),
    CandidateSelectedLanguages: yup.array().min(1, t('formErrors.required')),
    professions: yup.array().notRequired(),
    cv: yup
      .mixed()
      .test('required', t('formErrors.upload_CV'), (value) => {
        return value && value.length;
      })
      .test('fileSize', t('formErrors.large_file'), (value) => {
        return value && value?.[0]?.size <= FILE_SIZE;
      }),
    digitalBook: yup
      .mixed()
      .notRequired()
      .test('fileSize', t('formErrors.large_file'), (value) => {
        if (!value || !value?.[0]) return true;
        return value && value?.[0]?.size <= FILE_SIZE;
      }),
    video: yup
      .mixed()
      .notRequired()
      .test('fileSize', t('formErrors.large_file'), (value) => {
        if (!value || !value?.[0]) return true;
        return value && value?.[0]?.size <= FILE_SIZE_VIDEO;
      }),
    receiveNews: yup.bool().required(t('formErrors.required')),
    acceptTerms: yup.boolean().oneOf([true], t('formErrors.terms')),
  });

export const RegisterBusinessFormSchema = (t: any) =>
  yup.object({
    name: yup.string().required(t('formErrors.required')),
    profilePicture: yup
      .mixed()
      .test('required', t('formErrors.profile_picture'), (value) => {
        return value;
      })
      .test('fileSize', t('formErrors.large_file'), (value) => {
        return value && value?.size <= FILE_SIZE;
      }),
    password: yup
      .string()
      .required(t('formErrors.required'))
      .min(8, t('formErrors.password.short'))
      .matches(/[0-9]/, t('formErrors.password.number'))
      .matches(/[a-z]/, t('formErrors.password.lowerCase'))
      .matches(/[A-Z]/, t('formErrors.password.upperCase'))
      .matches(/[^\w]/, t('formErrors.password.symbol')),
    email: yup
      .string()
      .email(t('formErrors.email'))
      .required(t('formErrors.required')),
    address: yup.string().required(t('formErrors.required')),
    postalCode: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    phoneNumber: yup.string().required(t('formErrors.required')),
    businessTypeId: yup.string().ensure().required(t('formErrors.required')),
    numberOfWorkers: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    vat: yup.string().required(t('formErrors.required')),
    video: yup
      .mixed()
      .test('required', t('formErrors.upload_video'), (value) => {
        return value && value.length;
      })
      .test('fileSize', t('formErrors.large_file'), (value) => {
        return value && value?.[0]?.size <= FILE_SIZE_VIDEO;
      }),
    receiveNews: yup.bool().required(t('formErrors.required')),
    acceptTerms: yup.bool().required(t('formErrors.required')),
  });

export const EditProfileFormSchema = (t: any) =>
  yup.object({
    firstName: yup.string().required(t('formErrors.required')),
    lastName: yup.string().required(t('formErrors.required')),
    professionId: yup
      .array()
      .min(1, t('formErrors.one_position'))
      .max(2, t('formErrors.two_positions'))
      .required(t('formErrors.required')),
    skillId: yup
      .array()
      .min(1, t('formErrors.one_position'))
      .max(2, t('formErrors.two_positions'))
      .required(t('formErrors.required')),
    postalCode: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    phoneNumber: yup.string().required(t('formErrors.required')),
  });
