import { Nullable } from './../../../../../../interfaces/types/Nullable';
import { UserModel } from './../../../../../../interfaces/types/User';
import { useTranslation } from 'react-i18next';
import cv_icon from '../../../../../../assets/icons/cv-icon.png';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import { useContext } from 'react';

export default function useUserData() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const regex = /[^\/]+$/;
  const infoProps = [
    {
      id: 1,
      title: `${t('basic_info.full_name')}`,
      value: `${user?.firstName}  ${user?.lastName}`,
    },
    {
      id: 3,
      title: `${t('basic_info.job_type')}`,
      value: user?.profession?.map((wE: any) => wE.professionName).join(', '),
    },
    { id: 4, title: `${t('basic_info.country')}`, value: user?.country },
    { id: 5, title: 'Email', value: user?.email },
    { id: 6, title: 'Phone', value: user?.phoneNumber },
    {
      id: 7,
      title: 'CV',
      value: user?.files
        ?.find((file) => file.fileTypeId === 1)
        ?.filePath.match(regex),
      icon: cv_icon,
    },
    { id: 8, title: `${t('basic_info.gender')}`, value: user?.gender },
  ];
  const businessUserProps = [
    { id: 1, title: `${t('basic_info.company_name')}`, value: user?.company },
    {
      id: 2,
      title: `${t('basic_info.contact_phone')}`,
      value: user?.contactNumber,
    },
    {
      id: 3,
      title: `${t('basic_info.company_site')}`,
      value: user?.companySite,
    },
    { id: 4, title: `${t('basic_info.country')}`, value: user?.country },
    { id: 5, title: `${t('basic_info.email')}`, value: user?.email },
    { id: 6, title: `${t('basic_info.phone')}`, value: user?.phoneNumber },
  ];
  return [infoProps, businessUserProps];
}
