import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../../../components/BootstrapDialog/BootstrapDialog';
import { DialogContent } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  PayInfo,
  PayInfos,
  PriceTitle,
  SuccessMessage,
  Title,
  Value,
} from './styles';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';

export default function ConfirmModal({
  setOpenSuccessModal,
  openSuccessModal,
  price,
  points,
}: any) {
  const queryClient = useQueryClient();
  const handleClose = () => {
    setOpenSuccessModal(false);
    queryClient.fetchQuery(['sideBarBusinessProfile']);
  };
  const { t } = useTranslation();
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={openSuccessModal}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          height: '200px',
          width: '300px',
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: 20,
        }}
      >
        <CheckIcon
          fontSize="large"
          sx={{
            backgroundColor: '#20BE79',
            color: 'white',
            borderRadius: '50%',
            boxShadow: '0rem 0rem 1rem #20BE79',
            padding: '0.5rem',
          }}
        />
        <SuccessMessage>{t('payment.success')}</SuccessMessage>
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <PayInfos>
          <PayInfo>
            <Title>{t('payment.date')}</Title>
            <Value>
              {date}/{month}/{year}
            </Value>
          </PayInfo>
          <PayInfo>
            <PriceTitle>
              {t('payment.amount')}
              <span style={{ fontSize: '8px' }}>{t('payment.with_vat')}</span>
            </PriceTitle>
            <Value>
              <span style={{ fontWeight: 'bold' }}>{price}€</span> /
              <span style={{ fontSize: '10px' }}>{points}Cr.</span>
            </Value>
          </PayInfo>
        </PayInfos>
      </DialogContent>
    </BootstrapDialog>
  );
}
