import { Box, Typography, Button } from '@mui/material';
import styled from 'styled-components';

export const FiltersWrapper = styled(Box)`
  margin-left: 0.5rem;
`;
export const FilterWrapper = styled(Box)`
  width: 100%;
`;
export const FilterLabel = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

export const Filter = styled(Box)`
  margin-bottom: 0.5rem;
`;

export const FilterApplyButton = styled(Button)`
  margin-top: 1rem;
  text-transform: none;
  color: white;
  background: #72c2cc;
  width: 100%;
  &:hover {
    background: #72c2cc;
  }
  border-radius: 10px;
`;
