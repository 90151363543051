import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import MainSharedLayout from './pages/MainSharedLayout';
import AboutPage from './pages/About/AboutPage';
import Subscription from './pages/Subscription/Subscription';
import './index.css';
import { AuthProvider } from './lib/context/AuthContext';
import StudentLeftSideNavigation from './modals/components/layouts/student-dashboard/StudentLeftSideNavigation';
import BusinessLeftSideNavigation from './modals/components/layouts/business-dashboard/BusinessLeftSideNavigation';
import ProfileInfo from './modals/components/layouts/student-dashboard/ProfileInfo';
import EditStudentProfile from './modals/components/layouts/student-dashboard/EditStudentProfile/EditStudentProfile';
import { RequireAuthRoute } from './components/RequireAuthRoute/RequireAuthRoute';
import { RequireNonAuthRoute } from './components/RequireAuthRoute/RequireNonAuthRoute';
import LikedVideos from './pages/CandidatePages/LikedVideos/LikedVideos';
import InterestedOnYouPage from './pages/CandidatePages/InterestedOnYou/InterestedOnYou';
import EditBusinessProfile from './modals/components/layouts/business-dashboard/EditBusinessProfile/EditBusinessProfile';
import SavedProfilesPage from './pages/BusinessPages/SavedProfiles/SavedProfils';
import HistoryOfPackagePage from './pages/BusinessPages/HistoryOfPackage/HistoryOfPackage';
import BusinessViewStudent from './modals/components/layouts/business-dashboard/BusinessViewStudent/BusinessViewStudent';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import CreditsPurchase from './modals/components/Register/CreditsPurchase/CreditsPurchase';
import SharedCandidateCard from './modals/components/layouts/business-dashboard/BusinessViewStudent/SharedCandidateCard/SharedCandidateCard';
import SharedBusinessCard from './components/SharedBusinessCard/SharedBusinessCard';
import SearchBusinessPage from './pages/SearchBusiness/SearchBusiness';
import SearchCandidatePage from './pages/SearchCandidatePage/SearchCandidatePage';
import FeedCandidate from './pages/CandidatePages/Feed/FeedCandidate';
import FeedBusiness from './pages/CandidatePages/Feed/FeedBusiness';
import NotificationPage from './pages/NotificationPage/NotificationPage';
import LoginPage from './pages/Auth/LoginPage';
import RegisterPage from './pages/Auth/RegisterPage';
import RegisterBusinessPage from './pages/Auth/RegisterBusiness';
import RegisterStudentPage from './pages/Auth/RegisterStudent';
import ConfirmEmailPage from './pages/Auth/ConfirmEmailPage';
import ForgotPasswordPage from './pages/Auth/ForgotPassword';
import VerifyEmailPage from './pages/Auth/VerifyEmail';
import CreateNewPasswordPage from './pages/Auth/CreateNewPasswordPage';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<MainSharedLayout />}>
            <Route element={<RequireAuthRoute allowedRole={['Candidate']} />}>
              <Route
                path="/candidate/profile"
                element={<StudentLeftSideNavigation />}
              >
                <Route path="overview" element={<ProfileInfo />} />
                <Route path="settings" element={<EditStudentProfile />} />
                <Route path="liked-profiles" element={<LikedVideos />} />
                <Route
                  path="interested-on-you"
                  element={<InterestedOnYouPage />}
                />
                <Route path="feed" element={<FeedCandidate />} />
                <Route
                  path="notification/:type"
                  element={<NotificationPage />}
                />
              </Route>
            </Route>
            <Route element={<RequireAuthRoute allowedRole={['Business']} />}>
              <Route
                path="/business/profile"
                element={<BusinessLeftSideNavigation />}
              >
                <Route path="overview" element={<ProfileInfo />} />
                <Route path="settings" element={<EditBusinessProfile />} />
                <Route path="liked-profiles" element={<LikedVideos />} />
                <Route path="saved-profiles" element={<SavedProfilesPage />} />
                <Route path="packets" element={<HistoryOfPackagePage />} />
                <Route path="feed" element={<FeedBusiness />} />
                <Route path="subscription-plans" element={<Subscription />} />
              </Route>
              <Route path="credits-purchase" element={<CreditsPurchase />} />
              <Route path="notification/:type" element={<NotificationPage />} />

              <Route
                path="candidate/profile/:id"
                element={<BusinessViewStudent />}
              />
            </Route>

            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route
              path="candidate-shared/profile/:id"
              element={<SharedCandidateCard />}
            />
            <Route
              path="business-shared/profile/:id"
              element={<SharedBusinessCard />}
            />
            <Route path="business/search" element={<SearchBusinessPage />} />
            <Route path="candidate/search" element={<SearchCandidatePage />} />
          </Route>

          <Route element={<RequireNonAuthRoute />}>
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/register/student" element={<RegisterStudentPage />} />
            <Route
              path="/register/business"
              element={<RegisterBusinessPage />}
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/confirm-email" element={<ConfirmEmailPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/verify-email" element={<VerifyEmailPage />} />
            <Route path="/new-password" element={<CreateNewPasswordPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
