import { useTranslation } from 'react-i18next';
import RightSideStudentItem from './RightSideStudentItem';
import { MainWrapper, TitleWrapper, CompaniesWrapper } from './styles';

export default function RightSideStudent({ data }: any) {
  const { t } = useTranslation();

  return (
    <MainWrapper>
      <TitleWrapper>{t('right_side.companies')}</TitleWrapper>
      <CompaniesWrapper>
        {data?.top5Company?.map((company: any, index: any) => (
          <RightSideStudentItem key={index} company={company} />
        ))}
      </CompaniesWrapper>
    </MainWrapper>
  );
}
