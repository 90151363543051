import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BusinessInputs } from '../../../../interfaces/types/User';

export default function SectorOfCompanySelect({
  label,
  data,
  registerForm,
  ...rest
}: any) {
  const {
    setValue,
    getValues,
    register,
    formState: {
      errors: { businessTypeId },
    },
  } = useFormContext<BusinessInputs>();

  const [sector, setSector] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    const { target } = event;
    setSector(target.value);
    setValue('businessTypeId', +target.value);
  };

  return (
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth size="small" error={!!businessTypeId}>
        <InputLabel id="test-select-label">{label}</InputLabel>
        <Select
          variant="outlined"
          defaultValue=""
          value={sector}
          labelId="test-select-label"
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          {...rest}
        >
          {data.map((item: any) => (
            <MenuItem key={item.id} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {businessTypeId && (
          <Typography sx={{ color: 'red' }} variant="caption" display="block">
            {businessTypeId.message}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
}
