import { Typography } from '@mui/material';
import styled from 'styled-components';

export const CeoWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const InfoWrapper = styled.div``;
export const Avatar = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
`;

export const Name = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
`;
export const Position = styled(Typography)`
  font-size: 14px;
`;

export const MainWrapper = styled.div`
  display: inline-grid;
  margin-top: 1rem;
  grid-template-columns: 500px 1fr;
  grid-gap: 200px;
  &:nth-child(2) {
    order: 1;
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: inline-flex;
    flex-direction: column;
    grid-gap: 10px;
  }
`;
