import { useTranslation } from 'react-i18next';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import VerifyEmail from '../../modals/components/ForgotPassword/VerifyEmail/VerifyEmail';

export default function VerifyEmailPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      title={t('auth.verify_email.title')}
      content={<VerifyEmail />}
    />
  );
}
