import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const SharedVideoWrapper = styled(Box)`
  background: #eafafb;
  border-radius: 10px;
  padding: 1rem;
`;

export const SharedVideoTitle = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
export const SharedVideoDescription = styled(Typography)`
  font-size: 12px;
  color: #9d9d9d;
  text-align: center;
`;
