import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../lib/context/AuthContext';
import Navbar from '../../modals/components/Navbar/Navbar';
import { AppBarWrapper } from './styles';

export default function Header() {
  const location = useLocation();
  const homePage = location.pathname === '/home';
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setSmall(window.pageYOffset > 150)
      );
    }
  }, []);

  return (
    <AppBarWrapper homePage={homePage} small={small}>
      <Navbar />
    </AppBarWrapper>
  );
}
