import * as yup from 'yup';

export const LoginFormSchema = (t: any) =>
  yup.object({
    email: yup
      .string()
      .email(t('formErrors.email'))
      .required(t('formErrors.required')),
    password: yup.string().required(t('formErrors.required')),
  });
