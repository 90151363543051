import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams, useSearchParams } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import {
  useGetAllBusinessCardsInfiniteQuery,
  useGetAllCandidatesCardsInfiniteQuery,
} from '../../rq/hooks/useQueries';
import Card from '../Card/Card';
import { FetchDiv, NoCard, TypoFetch } from '../Card/styles';
import CardSkeleton from '../CardSkeleton/CardSkeleton';
import CircularIndeterminate from '../CircularIndeterminate/CircularIndeterminate';
import SearchVideos from '../SearchVideos/SearchVideos';

export default function SearchCandidate() {
  const [searchParams] = useSearchParams();
  const searchWord: any = searchParams.get('searchWord');
  const [search, setSearch] = useState<any>(searchWord);
  const [results, setResults] = useState<number | undefined>(0);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const {
    refetch,
    status,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetAllCandidatesCardsInfiniteQuery(debouncedSearchTerm, {
    getNextPageParam: (lastPage: any, pages: any) => {
      if (lastPage.metaData.pageNumber < lastPage.metaData.pageCount) {
        return lastPage.metaData.pageNumber + 1;
      }
    },
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (debouncedSearchTerm) {
      setResults(data?.pages[0].data.length);
    } else {
      setResults(0);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(
    () => {
      (async () => {
        if (debouncedSearchTerm) {
          setIsSearching(true);
          await refetch();
          setIsSearching(false);
        } else {
          await refetch();
          setIsSearching(false);
        }
      })();
    },
    [debouncedSearchTerm]
    // Only call effect if debounced search term changes
  );

  const { t } = useTranslation();

  return (
    <>
      <SearchVideos
        setSearch={setSearch}
        results={results}
        isSearching={isSearching}
        search={search}
      />
      {status === 'loading' ? (
        <CircularIndeterminate />
      ) : (
        <>
          {data?.pages.map((card, i) => (
            <Fragment key={i}>
              {card.data.length > 0 ? (
                card.data.map((l, index) => (
                  <Card
                    key={index}
                    profilePicture={l.profilePicture}
                    candidateUserId={l.candidateUserId}
                    candidateId={l.candidateId}
                    professions={l.profession}
                    businessId={l.businessId}
                    businessUserId={l.businessUserId}
                    name={l.name}
                    lastName={l.lastName}
                    country={l.country}
                    video={l.video}
                    videoViews={l.videoViews}
                    likes={l.likes}
                    saves={l.saves}
                    isMyFavorite={l.isMyFavorite}
                    likedFromMe={l.likedFromMe}
                    videoId={l.videoId}
                    boughtFromCurrentUser={l.boughtFromCurrentUser}
                    email={l.email}
                    phoneNumber={l.phoneNumber}
                    refetch={refetch}
                  />
                ))
              ) : (
                <NoCard>{t('card.no_cards')}</NoCard>
              )}
            </Fragment>
          ))}
          {data && data?.pages[0].data.length > 0 && (
            <FetchDiv ref={ref} onClick={() => fetchNextPage()}>
              {isFetchingNextPage ? (
                <CardSkeleton />
              ) : hasNextPage ? (
                <TypoFetch>{t('card.search.load_more')}</TypoFetch>
              ) : (
                <TypoFetch>{t('card.search.nothing_more')}</TypoFetch>
              )}
            </FetchDiv>
          )}
        </>
      )}
    </>
  );
}
