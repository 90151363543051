import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { changeUserLangauge } from '../mutations/changeUserLanguage';

export const useChangeLanguageMutation = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    ['change-language'],
    (id: number) => changeUserLangauge(id),
    options
  );
};
