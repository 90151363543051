import { Container } from '@mui/material';
import React from 'react';
import { Description, Title } from '../styles';
import {
  Avatar,
  CeoWrapper,
  InfoWrapper,
  MainWrapper,
  Name,
  Position,
} from './styles';
import ceoImage from '../../../../assets/images/team/Erwin.jpg';
import { useTranslation } from 'react-i18next';

export default function Goal() {
  const { t } = useTranslation();
  return (
    <Container>
      <MainWrapper>
        <CeoWrapper>
          <Avatar src={ceoImage} />
          <InfoWrapper>
            <Name>Erwin Schellens</Name>
            <Position>CEO & Founder MyFirstJob</Position>
          </InfoWrapper>
        </CeoWrapper>

        <Description style={{ fontSize: '20px' }}>
          {t('about.goal')}
        </Description>
      </MainWrapper>
    </Container>
  );
}
