import { Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../../lib/theme';

const { text_gray } = colors;

export const MainInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const SkillsWrapper = styled.div`
  align-self: start;
  display: flex;
`;

export const SkillsTitle = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  align-self: start;
  color: ${text_gray};
`;

export const SkillWrapper = styled.div`
  border: 1px solid #dde1e5;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem 0;
  font-family: 'Montserrat';
  font-size: 12px;
`;
