import { Box, Grid, Paper, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import JobsAds from '../../../../components/sharedComponents/JobsAds/JobsAds';
import { CodeField, DidntGetCode, ConfirmCodeButton } from './styles';
import { useForm, FormProvider } from 'react-hook-form';
import { ConfirmEmailModel } from '../../../../interfaces/types/User';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  useConfirmEmail,
  useResendConfirmationCode,
} from '../../../../rq/hooks/useAuth.mutation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ConfirmEmail() {
  const [error, setError] = useState<any>('');
  const [codeSent, setCodeSent] = useState<any>('');
  const navigate = useNavigate();
  const validationSchema = yup.object({
    confirmationCode: yup.string().required('Required'),
  });
  const methods = useForm<ConfirmEmailModel>({
    resolver: yupResolver(validationSchema),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: {
      errors: { confirmationCode },
    },
  } = methods;

  // react mutations

  const { mutate: confirmEmail, isLoading } = useConfirmEmail();
  const {
    mutate: resendCode,
    error: resendError,
    data: resendData,
  } = useResendConfirmationCode();

  const [searchParams] = useSearchParams();
  const email: any = searchParams.get('email');

  const onSubmit = async (data: ConfirmEmailModel) => {
    confirmEmail(
      { email: email, confirmationCode: data.confirmationCode },
      {
        onSuccess: (res) => navigate('/login'),
        onError: (res) => setError(res),
      }
    );
  };

  const resendEmailConfirmationCode = () => {
    resendCode(
      { email: email },
      {
        onSuccess: (res) => setCodeSent(res.successMessage),
        // setCodeSent(res.successMessage);
        onError: (res) => console.log(res),
      }
    );
  };

  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: { xs: 2, lg: 12 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              component='h1'
              variant='h5'
              align='left'
              fontFamily='Impact'
            >
              {t('auth.confirmation_code.title')}
            </Typography>
            <Typography
              variant='caption'
              align='left'
              sx={{ fontFamily: 'Montserrat' }}
            >
              {t('auth.confirmation_code.description')}&nbsp;
              <b>{email}</b>
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                mt: 1,
                alignItems: 'center',
              }}
            >
              <CodeField
                placeholder={t('auth.confirmation_code.input_placeholder')}
                type='input'
                fullWidth
                size='small'
                {...register('confirmationCode')}
                fieldError={confirmationCode}
              />
              {error && (
                <Typography
                  sx={{ color: 'red' }}
                  variant='caption'
                  display='block'
                >
                  {error}
                </Typography>
              )}
              <ConfirmCodeButton
                loading={isLoading}
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </ConfirmCodeButton>
              <Grid container>
                <Grid item>
                  <DidntGetCode
                    variant='text'
                    onClick={() => resendEmailConfirmationCode()}
                  >
                    {t('auth.confirmation_code.null_code')}
                  </DidntGetCode>
                  {codeSent && (
                    <Typography sx={{ color: 'blue' }} variant='caption'>
                      {codeSent}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
