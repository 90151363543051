import { Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../lib/theme';

const { text_gray } = colors;

export const IconsWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

export const IconEditWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${text_gray};
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  transform: translate(25%, -25%);
`;

export const IconClearWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${text_gray};
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  transform: translate(25%, 25%);
  &:hover {
    color: red;
    cursor: pointer;
  }
`;

export const AllowedTypes = styled(Typography)`
  position: absolute;
  font-family: 'Montserrat';
  font-size: 10px;
  color: ${text_gray};
`;
