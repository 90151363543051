import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import {
  MainWrapper,
  Title,
  TitleWrapper,
  VideoWrapper,
  PlayerIconWrapper,
} from '../../styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function VideoPresentation({ video }: any) {
  const { t } = useTranslation();
  const [showControls, setShowControls] = useState<boolean>(false);
  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t('basic_info.video_presentation')}</Title>
      </TitleWrapper>
      <VideoWrapper>
        {!showControls && (
          <PlayerIconWrapper onClick={() => setShowControls(true)}>
            <PlayArrowIcon fontSize="large" sx={{ color: 'white' }} />
          </PlayerIconWrapper>
        )}
        <ReactPlayer
          url={video}
          controls={showControls}
          width="100%"
          height="120px"
          playing={showControls}
          onEnded={() => setShowControls(false)}
        />
      </VideoWrapper>
    </MainWrapper>
  );
}
