import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Button,
  Grid,
  DialogProps,
  InputLabel,
} from '@mui/material';
import { UseFieldArrayAppend, useForm } from 'react-hook-form';
import { UserInputs } from '../../../../interfaces/types/User';
import { useTranslation } from 'react-i18next';
import Select from '../../../../components/Select/Select';
import { ILinguisticKnowledgeForm } from '../../../../interfaces/types/LinguisticForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinguisticKnowledgeFormSchema } from '../../../../validators/LanguageInformationForm.validator';

interface LinguisticKnowledgeFormDialogProps extends DialogProps {
  appendLinguisticKnowledge: UseFieldArrayAppend<
    UserInputs,
    'CandidateSelectedLanguages'
  >;
  languages: any;
  languageSkillLevels: any;
  onDialogClose: () => void;
}

export default function LanguageKnowledge({
  appendLinguisticKnowledge,
  languages,
  languageSkillLevels,
  onDialogClose,
  ...rest
}: LinguisticKnowledgeFormDialogProps) {
  const { t } = useTranslation();
  // hook form
  const {
    watch,
    reset,
    clearErrors,
    trigger,
    register,
    getValues,
    formState: { errors },
  } = useForm<ILinguisticKnowledgeForm>({
    mode: 'onChange',
    resolver: yupResolver(LinguisticKnowledgeFormSchema(t)),
  });

  // functions
  const clearForm = () => {
    reset();
    clearErrors();
  };

  const handleDialogClose = () => {
    clearForm();
    onDialogClose();
  };

  const handleTriggerForm = async () => {
    const triggered = await trigger(['languageId', 'languageSkillLevelId']);

    if (triggered) {
      appendLinguisticKnowledge({
        languageId: getValues('languageId'),
        languageSkillLevelId: getValues('languageSkillLevelId'),
      });
      clearForm();
      onDialogClose();
    }
  };

  return (
    <Dialog
      fullWidth
      onClose={() => console.log(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontFamily: 'Montserrat' }}>
        {t('auth.sign_up.language_skills')}
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ mt: 1 }}>
          <FormControl fullWidth size="small" sx={{ mb: '20px' }}>
            <InputLabel id="demo-simple-select-label">
              {t('auth.sign_up.linguistic_knowledge')}
            </InputLabel>
            <Select
              label={t('auth.sign_up.linguistic_knowledge')}
              value={watch('languageId') ?? ''}
              options={languages}
              error={!!errors.languageId}
              fieldError={errors.languageId}
              {...register('languageId')}
            />
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              {t('auth.sign_up.select_level')}
            </InputLabel>

            <Select
              label={t('auth.sign_up.select_level')}
              value={watch('languageSkillLevelId') ?? ''}
              options={languageSkillLevels}
              error={!!errors.languageSkillLevelId}
              fieldError={errors.languageSkillLevelId}
              {...register('languageSkillLevelId')}
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t('auth.sign_up.cancel')}</Button>
        <Button onClick={handleTriggerForm}>{t('auth.sign_up.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}
