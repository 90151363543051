import {
  createContext,
  useState,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import {
  AuthContextProps,
  DecodedTokenProps,
} from '../../interfaces/types/AuthContext';
import { Nullable } from '../../interfaces/types/Nullable';
import { UserModel } from '../../interfaces/types/User';
import decode from 'jwt-decode';
import {
  useMyBusinessProfileQuery,
  useMyProfileQuery,
} from '../../rq/hooks/useQueries';
import {
  writeToStorage,
  readFromStorage,
  removeFromStorage,
  validateAuthToken,
} from '../../utils/storage/storage';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import { useTranslation } from 'react-i18next';

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  role: null,
  languageId: null,
  setLanguageId: (_languageId: number) => null,
  isAuthenticated: false,
  loading: false,
  login: (_token: string) => null,
  logout: () => null,
  setUser: (_user: UserModel) => null,
});

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!readFromStorage('auth-token')
  );
  const [user, setUser] = useState<Nullable<UserModel>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [role, setRole] = useState<string | null>(null);
  const [languageId, setLanguageId] = useState<number | null>(null);
  const token = readFromStorage('auth-token');
  const navigate = useNavigate();
  const initialTokenCheckRef = useRef(true);
  const { i18n } = useTranslation();

  const {
    data: candidateData,
    refetch: refetchCandidate,
    isLoading: isGettingCandidateProfileLoading,
  } = useMyProfileQuery({
    enabled: role === 'Candidate',
    onSuccess: (data) => {
      setUser(data);
      setLoading(false);
    },
  });

  const {
    data: businessData,
    refetch: refetchBusiness,
    isLoading: isGettingBusinessProfileLoading,
  } = useMyBusinessProfileQuery({
    enabled: role === 'Business',
    onSuccess: (data) => {
      setUser(data);
      setLoading(false);
    },
  });

  const login = useCallback((token: any) => {
    setLoading(true);
    writeToStorage('auth-token', token.token);
    setIsAuthenticated(true);
    const decodedToken: DecodedTokenProps = decode(token.token);
    setRole(decodedToken.role.toLowerCase());
    if (token.userIdentity.languageId === 1) {
      setLanguageId(1);
      i18n.changeLanguage('en-GB');
    } else if (token.userIdentity.languageId === 2) {
      setLanguageId(2);
      i18n.changeLanguage('fr-FR');
    } else {
      setLanguageId(3);
      i18n.changeLanguage('nl-NL');
    }
    navigate(`/home`);
  }, []);

  const logout = useCallback(() => {
    delete axiosInstance.defaults.headers.common.Authorization;
    removeFromStorage('auth-token');
    setLoading(false);
    setIsAuthenticated(false);
    setUser(null);
    setRole(null);
    removeFromStorage('lang');
    navigate(`/home`);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
    }
  }, [
    candidateData,
    businessData,
    refetchCandidate,
    refetchBusiness,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (token) {
      const decodedToken: DecodedTokenProps = decode(token);
      setRole(decodedToken.role);
    }
  }, [token]);

  useLayoutEffect(() => {
    if (initialTokenCheckRef.current) {
      const isTokenValid = validateAuthToken(
        readFromStorage('auth-token') || ''
      );
      setIsAuthenticated(isTokenValid);
    }
    initialTokenCheckRef.current = false;
  }, [logout]);

  useEffect(() => {
    const token = readFromStorage('auth-token');
    if (token && !validateAuthToken(token)) {
      logout();
    }
  }, [logout]);

  const userLanguage = readFromStorage('i18nextLng');

  useEffect(() => {
    if (userLanguage === 'en-GB') {
      setLanguageId(1);
    } else if (userLanguage === 'fr-FR') {
      setLanguageId(2);
    } else {
      setLanguageId(3);
    }
  }, [userLanguage]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        role,
        languageId,
        setLanguageId,
        isAuthenticated,
        loading:
          loading &&
          (isGettingCandidateProfileLoading || isGettingBusinessProfileLoading),
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
