import { Person } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AvatarWrapper,
  MainWrapper,
  Avatar,
  NameWrapper,
  PositionWrapper,
  DescriptionWrapper,
} from '../../../styles';
import {
  MainInfoWrapper,
  SkillsWrapper,
  SkillsTitle,
  SkillWrapper,
} from './styles';

export default function LeftSideData({ data }: any) {
  const {
    profilePicture,
    firstName,
    lastName,
    profession,
    description,
    skills,
  } = data;
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <MainInfoWrapper>
        {profilePicture ? (
          <Avatar src={profilePicture} />
        ) : (
          <AvatarWrapper>
            <Person sx={{ color: 'white' }} />
          </AvatarWrapper>
        )}
        <NameWrapper>
          {firstName} {lastName}
        </NameWrapper>
        <PositionWrapper>
          {profession?.map((prof: any) => prof.professionName).join(', ')}
        </PositionWrapper>
        <DescriptionWrapper>{description}</DescriptionWrapper>
        {skills.length > 0 && (
          <>
            <SkillsTitle>{t('left_side_bar.skills')}</SkillsTitle>
            <SkillsWrapper>
              {skills &&
                skills?.map((skill: any) => (
                  <SkillWrapper key={skill.skillId}>
                    {skill.skillName}
                  </SkillWrapper>
                ))}
            </SkillsWrapper>
          </>
        )}
      </MainInfoWrapper>
    </MainWrapper>
  );
}
