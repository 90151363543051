import { useContext, useEffect } from 'react';
import { AuthContext } from '../../../../../../../lib/context/AuthContext';
import { InputField } from '../../../../../InputField/InputField';
import { useForm, FormProvider } from 'react-hook-form';
import {
  MainWrapper,
  TitleWrapper,
  Wrapper,
  FormPropWrapper,
  ProfileWrapper,
  ButtonsWrapper,
  SaveChangesButton,
  BpCheckedIcon,
  BpIcon,
} from './styles';
import { EditProfileDetailsModel } from '../../../../../../../interfaces/types/User';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditProfileFormSchema } from '../../../../../../../validators/Register.validator';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  useAddEditProfilePicture,
  useDeleteMyProfilePicture,
  useEditProfileDetails,
} from '../../../../../../../rq/hooks/useEditProfile.mutation';
import { RadioProps } from '@mui/material/Radio';
import {
  useGetAllCountriesQuery,
  useGetAllSkillsQuery,
  useProfessionsQuery,
} from '../../../../../../../rq/hooks/useQueries';
import { useTranslation } from 'react-i18next';
import { DiscardButton } from '../../styles';
import toast from 'react-hot-toast';
import MultiSelect from '../../../../../../../components/MultiSelect/MultiSelect';
import Select from '../../../../../../../components/Select/Select';
import { useQueryClient } from '@tanstack/react-query';
import { GenericProfileUpload } from '../../../../../../../components/GenericProfileUpload/GenericProfileUpload';

export default function EditProfileDetails() {
  //hooks
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  // react mutation
  const { mutate: editProfileDetails, isLoading } = useEditProfileDetails({
    onSuccess: () => {
      toast.success(`${t('successMessage.no_admin_verification')}`);
      queryClient.fetchQuery(['profile']);
      queryClient.fetchQuery(['sideBarProfile']);
    },
  });
  const { mutate: addEditProfilePicture, isLoading: editIsLoading } =
    useAddEditProfilePicture();

  // react queries
  const { data: countries = [] } = useGetAllCountriesQuery();
  const { data: skills = [] } = useGetAllSkillsQuery();
  const { data: professions = [] } = useProfessionsQuery();

  // hook form
  const {
    trigger,
    watch,
    getValues,
    setValue: setProfilePicture,
    // reset,
    formState: { errors: profilePictureErrors },
  } = useForm<{
    photo: File | string | null;
  }>({
    // resolver: joiResolver(ProfilePictureSchema),
    defaultValues: {
      photo: user?.profilePicture || '',
    },
  });

  const methods = useForm<EditProfileDetailsModel>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      professionId: user?.profession?.map((prof) => prof.professionId),
      skillId: user?.skills?.map((skill) => skill.skillId),
      postalCode: user?.postalCode,
      countryId: user?.countryId,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      genderId: user?.genderId,
    },
    resolver: yupResolver(EditProfileFormSchema(t)),
  });

  // destructures
  const {
    register,
    setValue,
    reset,
    clearErrors,
    handleSubmit,
    formState: {
      isDirty,
      errors: {
        firstName,
        lastName,
        phoneNumber,
        professionId,
        skillId,
        postalCode,
      },
    },
  } = methods;

  // handlers
  const onSubmit = async (data: EditProfileDetailsModel) => {
    const formData: any = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('countryId', data.countryId);
    if (data.genderId) {
      formData.append('genderId', data.genderId);
    }
    formData.append('skillId', JSON.stringify(data.skillId));
    formData.append('professionId', JSON.stringify(data.professionId));
    formData.append('phoneNumber', data.phoneNumber);
    formData.append('postalCode', data.postalCode);

    editProfileDetails(formData);
  };

  const {
    mutate: deleteProfilePicture,
    isLoading: deleteProfilePictureLoading,
  } = useDeleteMyProfilePicture({
    onSuccess: () => {
      toast.success('Deletion was successful');
      queryClient.fetchQuery(['profile']);
      queryClient.fetchQuery(['sideBarProfile']);
    },
  });

  // handlers
  const handleSelectProfilePicture = async (file: File | string | null) => {
    if (!file) deleteProfilePicture();
    else {
      setProfilePicture('photo', file, { shouldValidate: true });
      const triggered = await trigger('photo');
      if (!triggered) return;

      const formData = new FormData();
      if (file) formData.append('photo', file);
      addEditProfilePicture(formData as unknown as File, {
        onSuccess: () => {
          queryClient.fetchQuery(['profile']);
          queryClient.fetchQuery(['sideBarProfile']);
        },
      });
    }
  };

  const handleSelectProfessions = (selectedItems: number[]) => {
    setValue('professionId', selectedItems, { shouldDirty: true });
  };
  const handleSelectSkills = (selectedItems: number[]) => {
    setValue('skillId', selectedItems, { shouldDirty: true });
  };

  const clearForm = () => {
    reset();
    clearErrors();
  };

  useEffect(() => {
    reset({
      professionId: user?.profession?.map((prof) => prof.professionId),
      skillId: user?.skills?.map((skill) => skill.skillId),
      countryId: user?.countryId,
    });
  }, [reset, user?.countryId]);

  function BpRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainWrapper>
          <TitleWrapper>{t('editProfile.title')}</TitleWrapper>
          <ProfileWrapper>
            {user && (
              <>
                <Wrapper>
                  <FormPropWrapper>{t('editProfile.avatar')}</FormPropWrapper>
                  <GenericProfileUpload
                    deleteLoading={deleteProfilePictureLoading}
                    updateLoading={editIsLoading}
                    selectedFile={getValues('photo')}
                    onSelect={handleSelectProfilePicture}
                    canDelete={!!watch('photo')}
                  />
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>
                    {t('editProfile.edit_candidate_profile.full_name')}
                  </FormPropWrapper>
                  <div style={{ display: 'grid', gridAutoFlow: 'column' }}>
                    <InputField
                      placeholder={t('editProfile.edit_candidate_profile.name')}
                      type="input"
                      variant="standard"
                      fullWidth
                      defaultValue={user.firstName}
                      InputProps={{
                        sx: {
                          backgroundColor: '#F4F7F9',
                          borderRadius: '10px',
                          padding: '0 10px',
                        },
                        disableUnderline: true,
                      }}
                      {...register('firstName')}
                      fieldError={firstName}
                      sx={{ pr: 1 }}
                    />
                    <InputField
                      placeholder={t(
                        'editProfile.edit_candidate_profile.last_name'
                      )}
                      variant="standard"
                      InputProps={{
                        sx: {
                          backgroundColor: '#F4F7F9',
                          borderRadius: '10px',
                          padding: '0 10px',
                        },
                        disableUnderline: true,
                      }}
                      type="input"
                      fullWidth
                      defaultValue={user.lastName}
                      {...register('lastName')}
                      fieldError={lastName}
                    />
                  </div>
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>
                    {t('editProfile.edit_candidate_profile.job_type')}
                  </FormPropWrapper>
                  <MultiSelect
                    fieldError={professionId}
                    options={professions}
                    items={
                      user?.profession?.map((prof) => prof.professionId) ?? []
                    }
                    onSelect={handleSelectProfessions}
                    disableUnderline
                    variant="standard"
                    inputProps={{
                      sx: {
                        backgroundColor: '#F4F7F9',
                        borderRadius: '10px',
                        padding: '3px 10px',
                      },
                    }}
                  />
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>
                    {t('editProfile.edit_candidate_profile.skills')}
                  </FormPropWrapper>
                  <MultiSelect
                    fieldError={skillId}
                    options={skills}
                    items={user?.skills?.map((skill) => skill.skillId) ?? []}
                    onSelect={handleSelectSkills}
                    disableUnderline
                    variant="standard"
                    inputProps={{
                      sx: {
                        backgroundColor: '#F4F7F9',
                        borderRadius: '10px',
                        padding: '3px 10px',
                      },
                    }}
                  />
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>
                    {t('editProfile.postal_code')}
                  </FormPropWrapper>
                  <InputField
                    type="input"
                    placeholder={t('editProfile.postal_code')}
                    fullWidth
                    defaultValue={user?.postalCode}
                    variant="standard"
                    InputProps={{
                      sx: {
                        backgroundColor: '#F4F7F9',
                        borderRadius: '10px',
                        padding: '0 10px',
                      },
                      disableUnderline: true,
                    }}
                    size="small"
                    {...register('postalCode')}
                    fieldError={postalCode}
                  />
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>{t('editProfile.country')}</FormPropWrapper>
                  <Select
                    defaultValue={user.countryId ?? ''}
                    options={countries}
                    variant="standard"
                    disableUnderline
                    inputProps={{
                      sx: {
                        backgroundColor: '#F4F7F9',
                        borderRadius: '10px',
                        padding: '3px 10px',
                      },
                    }}
                    {...register('countryId')}
                  />
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>{t('editProfile.email')}</FormPropWrapper>
                  <InputField
                    type="input"
                    disabled
                    fullWidth
                    defaultValue={user.email}
                    variant="standard"
                    InputProps={{
                      sx: {
                        backgroundColor: '#F4F7F9',
                        borderRadius: '10px',
                        padding: '0 10px',
                      },
                      disableUnderline: true,
                    }}
                    size="small"
                    {...register('email')}
                  />
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>
                    {t('editProfile.edit_candidate_profile.phone')}
                  </FormPropWrapper>
                  <InputField
                    placeholder="Phone"
                    variant="standard"
                    type="input"
                    InputProps={{
                      sx: {
                        backgroundColor: '#F4F7F9',
                        borderRadius: '10px',
                        padding: '0 10px',
                      },
                      disableUnderline: true,
                    }}
                    fullWidth
                    defaultValue={user.phoneNumber}
                    size="small"
                    {...register('phoneNumber')}
                  />
                </Wrapper>
                <Wrapper>
                  <FormPropWrapper>
                    {t('editProfile.edit_candidate_profile.gender')}
                  </FormPropWrapper>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="genderId"
                      defaultValue={user.genderId}
                    >
                      <FormControlLabel
                        value={2}
                        control={<BpRadio />}
                        label="Female"
                        {...register('genderId')}
                      />
                      <FormControlLabel
                        value={1}
                        control={<BpRadio />}
                        label="Male"
                        {...register('genderId')}
                      />
                      <FormControlLabel
                        value={3}
                        control={<BpRadio />}
                        label="Other"
                        {...register('genderId')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Wrapper>
              </>
            )}
          </ProfileWrapper>
          <ButtonsWrapper>
            <DiscardButton
              disabled={!isDirty}
              variant="contained"
              onClick={clearForm}
            >
              {t('discard')}
            </DiscardButton>
            <SaveChangesButton
              disabled={!isDirty}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {t('save_changes')}
            </SaveChangesButton>
          </ButtonsWrapper>
        </MainWrapper>
      </form>
    </FormProvider>
  );
}
