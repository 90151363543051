import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../lib/theme';

const { text_blue, businessCardBg } = colors;

export const CustomedButton = styled(LoadingButton)<{
  isSavingButton: boolean;
  isNavigateButton: boolean;
}>`
  text-transform: none;
  background-color: ${(props) =>
    props.isSavingButton ? text_blue : businessCardBg};
  width: ${(props) => (props.isSavingButton ? '150px' : '200px')};
  height: ${(props) => (props.isSavingButton ? '40px' : '30px')};
  &:hover {
    background-color: ${(props) =>
      props.isSavingButton ? text_blue : businessCardBg};
  }
  border-radius: ${(props) => (props.isNavigateButton ? '10px' : '20px')};
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
    border-radius: 10px;
  }
`;
