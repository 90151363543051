import { Card, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../../lib/theme';

const { businessCardBg, position_text_color } = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
`;

export const TitleWrapper = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid ${businessCardBg};
`;
export const InfoWrapper = styled.div`
  margin-top: 1rem;
`;
export const IconWrapper = styled.img`
  width: 30px;
  height: 40px;
`;
