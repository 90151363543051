import {
  UserInputs,
  UserLoginInputs,
  BusinessInputs,
  ConfirmEmailModel,
  ForgotPasswordModel,
  ConfirmEmailType,
  ResetPasswordCodeValidModel,
  ResetPasswordModel,
} from './../../interfaces/types/User';
import axios from '../../utils/axios';
import { AxiosError } from 'axios';

export type MyExpectedResponseType = {
  errors: {
    code: string;
    message: string;
  }[];
};

export type ErrorResponseResetPassword = {
  errors: {
    Email: string[];
    ResetPasswordCode: string[];
    ConfirmNewPassword: string[];
  };
};
export const registerMutation = async (payload: UserInputs) => {
  try {
    const { data } = await axios.post('account/signup/candidate', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (err) {
    const error = err as AxiosError<MyExpectedResponseType>;
    throw error.response?.data.errors[0].message;
  }
};

export const loginMutation = async (payload: UserLoginInputs) => {
  // try {
  const { data } = await axios.post('account/signin', payload);
  return data.data;
  // }
  // catch (err) {
  //   const error = err as AxiosError<MyExpectedResponseType>;
  //   throw error.response?.data.errors[0];
  // }
};

export const registerBusinessMutation = async (payload: BusinessInputs) => {
  try {
    const { data } = await axios.post('account/signup/business', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (err) {
    const error = err as AxiosError<MyExpectedResponseType>;
    throw error.response?.data.errors[0].message;
  }
};

export const confirmEmailMutation = async (payload: ConfirmEmailModel) => {
  try {
    const { data } = await axios.post('account/confirmAccount', payload);
    return data.data;
  } catch (err) {
    const error = err as AxiosError<MyExpectedResponseType>;
    throw error.response?.data.errors[0].message;
  }
};

export const resendConfirmationCodeMutation = async (
  payload: ConfirmEmailType
) => {
  try {
    const { data } = await axios.post(
      'account/resendConfirmationCode',
      payload
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MyExpectedResponseType>;
    throw error.response?.data.errors[0].message;
  }
};

export const forgotPasswordMutation = async (payload: ForgotPasswordModel) => {
  await axios.post('account/forgotPassword', payload);
};

export const isResetPasswordCodeValid = async (
  payload: ResetPasswordCodeValidModel
) => {
  try {
    const data = await axios.post('account/isResetPasswordCodeValid', payload);
    return data;
  } catch (err) {
    const error = err as AxiosError<MyExpectedResponseType>;
    throw error.response?.data.errors[0].message;
  }
};

export const resetPassword = async (payload: ResetPasswordModel) => {
  try {
    const { data } = await axios.post('account/resetPassword', payload);
    return data;
  } catch (err) {
    const error = err as AxiosError<ErrorResponseResetPassword>;
    if (error.response?.data.errors.ConfirmNewPassword)
      throw error.response?.data.errors.ConfirmNewPassword[0];
    if (error.response?.data.errors.ResetPasswordCode)
      throw error.response?.data.errors.ResetPasswordCode[0];
  }
};
