import { useTranslation } from 'react-i18next';
import { useStripeCheckoutQuery } from '../../../../../../../rq/hooks/useQueries';
import {
  PlanWrapper,
  PlanName,
  PlanPrice,
  PlanPayButton,
  PlanCredits,
  PlanDetails,
  FieldSet,
  Legend,
} from './styles';
import { useContext, useState, useEffect } from 'react';
import StripePayment from '../StripePayment/StripePayment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentContext } from '../../../../../../../lib/context/PaymentContext';
import { STRIPE } from '../../../../../../../config/app';
import { ExclusiveVAT } from './styles';

const stripePromise = loadStripe(STRIPE);

export default function PlanCard({ plan }: any) {
  const { name, points, price, id, priceWithVat } = plan;
  const { t } = useTranslation();
  const [openPayModal, setOpenPayModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setOpenPayModal(true);
  };
  const { clientSecret, setClientSecret } = useContext(PaymentContext);

  const options = {
    clientSecret,
  };

  const { data, isLoading, isSuccess } = useStripeCheckoutQuery(id, {
    enabled: openPayModal,
  });

  useEffect(() => {
    if (data) {
      setClientSecret(data);
    }
  }, [isSuccess]);
  return (
    <>
      {name === 'Pro' ? (
        <FieldSet>
          <Legend>{t('most_popular')}</Legend>
          <PlanName style={{ color: '#487BD9' }}>{name}</PlanName>
          <ExclusiveVAT>{t('credits.price_without_vat')}</ExclusiveVAT>

          <PlanDetails>
            <PlanPrice style={{ color: '#487BD9' }}>€{price}/</PlanPrice>
            <PlanCredits style={{ color: '#487BD9' }}>{points} Cr.</PlanCredits>
          </PlanDetails>
          <PlanPayButton
            variant="contained"
            onClick={handleOpenModal}
            name={name}
          >
            {t('plans.choose_plan')}
          </PlanPayButton>
        </FieldSet>
      ) : (
        <PlanWrapper>
          <PlanName>{name}</PlanName>
          <ExclusiveVAT>{t('credits.price_without_vat')}</ExclusiveVAT>
          <PlanDetails>
            <PlanPrice>€{price}/</PlanPrice>
            <PlanCredits>{points} Cr.</PlanCredits>
          </PlanDetails>
          <PlanPayButton onClick={handleOpenModal} name={name}>
            {t('plans.choose_plan')}
          </PlanPayButton>
        </PlanWrapper>
      )}
      {clientSecret && !isLoading && (
        <Elements stripe={stripePromise} options={options} key={clientSecret}>
          <StripePayment
            planId={id}
            openPayModal={openPayModal}
            setOpenPayModal={setOpenPayModal}
            price={price}
            points={points}
            priceWithVat={priceWithVat}
          />
        </Elements>
      )}
    </>
  );
}
