import { Paper, Box, Grid } from '@mui/material';
import { BusinessRounded, PersonRounded } from '@mui/icons-material';
import JobsAds from '../../../components/sharedComponents/JobsAds/JobsAds';
import {
  LinkBusiness,
  LinkStudent,
  Paragraph,
  SignUpTypo,
  SignUpDesc,
  SignUpTitleWrapper,
  HaveAnAccount,
  ConnectYourself,
  Image,
} from './styles';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import businessLogo from '../../../assets/images/register_business.png';
import studentLogo from '../../../assets/images/register_student.png';

export default function Register() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <SignUpTitleWrapper>
            <SignUpTypo variant='h5'> {t('auth.sign_up.title')}</SignUpTypo>
            <SignUpDesc>{t('auth.sign_up.how_to_register')}</SignUpDesc>
          </SignUpTitleWrapper>
          <Box
            sx={{
              my: { xs: 2, md: 8 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <LinkBusiness to='/register/business'>
              <Image src={businessLogo} />
              <Paragraph>{t('auth.sign_up.business')}</Paragraph>
            </LinkBusiness>
            <LinkStudent to='/register/student'>
              <Image src={studentLogo} />
              <Paragraph>{t('auth.sign_up.student')}</Paragraph>
            </LinkStudent>
          </Box>
          <HaveAnAccount>
            {t('auth.sign_up.already_have_account')}&nbsp;
            <ConnectYourself to='/login'>
              {t('auth.login.login_button')}
            </ConnectYourself>
          </HaveAnAccount>
        </Grid>
      </Grid>
      <Outlet />
    </>
  );
}
