import React, { useContext } from 'react';
import { MainWrapper } from '../styles';
import { TitleWrapper } from '../MotivationLetter/styles';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeactivateButton } from '../ProfileDetails/EditProfileDetails/styles';
import { useDeactivateMyAccount } from '../../../../../../rq/hooks/useEditProfile.mutation';
import { AuthContext } from '../../../../../../lib/context/AuthContext';

const DeactivateMyAccount = () => {
  /**
   * local state
   */
  const [checked, setChecked] = React.useState(false);

  /**
   * hooks
   */
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);

  const { mutate: deactivateAccount, isLoading: deactivateAccountLoading } =
    useDeactivateMyAccount({ onSuccess: () => logout() });

  /**
   * handlers
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return (
    <MainWrapper>
      <TitleWrapper>{t('editProfile.deactivate.title')}</TitleWrapper>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={t('editProfile.deactivate.confirm')}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <DeactivateButton
          disabled={!checked}
          loading={deactivateAccountLoading}
          onClick={deactivateAccount}
        >
          {t('editProfile.deactivate.title')}
        </DeactivateButton>
      </Box>
    </MainWrapper>
  );
};

export default DeactivateMyAccount;
