import { DialogContent } from '@mui/material';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../BootstrapDialog/BootstrapDialog';

export default function PdfViewer({ open, setOpen, file, name }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        {name}
      </BootstrapDialogTitle>

      <DialogContent dividers sx={{ width: { xs: 320, md: 520 } }}>
        {file ? (
          <iframe
            title={file}
            src={`https://docs.google.com/gview?url=${encodeURIComponent(
              file
            )}&embedded=true`}
            width='100%'
            height={420}
          />
        ) : (
          'Loading'
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
