import { Button } from '@mui/material';
import { colors } from '../../lib/theme';
import { CustomedButton } from './styles';

export default function CustomButton({
  type,
  name,
  onClick,
  isSavingButton,
  isNavigateButton,
  isLoading,
  ...props
}: any) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: isSavingButton ? 'end' : 'center',
        // padding: '0 1rem',
      }}
    >
      <CustomedButton
        variant="contained"
        onClick={onClick}
        type={type}
        isSavingButton={isSavingButton}
        isNavigateButton={isNavigateButton}
        loading={isLoading}
        {...props}
      >
        {name}
      </CustomedButton>
    </div>
  );
}
