// react
import { ReactElement, useContext } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../lib/context/AuthContext';

// contexts

export const RequireNonAuthRoute = (): ReactElement => {
  // hooks
  const location = useLocation();
  const { isAuthenticated, role } = useContext(AuthContext);

  return isAuthenticated ? (
    <Navigate
      to={{ pathname: `/${role}/profile/feed` }}
      state={{ from: location }}
    />
  ) : (
    <Outlet />
  );
};
