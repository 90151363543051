import { Fragment, useContext } from 'react';
import {
  MainWrapper,
  MainInfoWrapper,
  AvatarWrapper,
  NameWrapper,
  PositionWrapper,
  LinksListWrapper,
  NoLinksListWrapper,
  StyledNavLink,
  Image,
  StyledNoNavLink,
  CreditsWrapper,
  CreditTitle,
  CreditValue,
  UpdateCvButton,
} from './styles';
import { Person, BusinessRounded } from '@mui/icons-material';
import { useLinkList, useNoLinkList } from './lists';
import { FlexBox } from '../../modals/components/flex-box';
import { useTranslation } from 'react-i18next';
import CustomButton from '../CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../lib/context/AuthContext';

export default function LeftSideBar({ data }: any) {
  const [noLinkList] = useNoLinkList();
  const [linkList, businessLink] = useLinkList();
  const { t } = useTranslation();
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <MainWrapper>
      <MainInfoWrapper>
        {!data?.profilePicture ? (
          <AvatarWrapper isCandidate={data?.candidateId}>
            {data?.candidateId ? (
              <Person sx={{ color: 'white' }} />
            ) : (
              <BusinessRounded sx={{ color: 'white' }} />
            )}
          </AvatarWrapper>
        ) : (
          <Image src={data.profilePicture} isCandidate={data?.candidateId} />
        )}
        <NameWrapper>
          {data?.candidateId ? data?.firstName : data?.name}
        </NameWrapper>
        <PositionWrapper>
          {data?.candidateId ? data?.professions.join(', ') : data?.country}
        </PositionWrapper>
      </MainInfoWrapper>
      {data?.candidateId ? (
        <>
          <UpdateCvButton onClick={() => navigate(`/${role}/profile/settings`)}>
            {t('left_side_bar.update_cv')}
          </UpdateCvButton>
          <NoLinksListWrapper>
            {noLinkList.map((item: any) => (
              <StyledNoNavLink key={item.id}>
                <FlexBox alignItems="center" gap={1}>
                  <item.icon style={{ fontSize: '12px' }} />
                  <span>{item.title}</span>
                </FlexBox>
                <span>{item?.count}</span>
              </StyledNoNavLink>
            ))}
          </NoLinksListWrapper>
          <LinksListWrapper>
            {linkList.map((item: any) => (
              <StyledNavLink key={item.id} to={item.href}>
                <FlexBox alignItems="center" gap={1}>
                  <item.icon style={{ fontSize: '12px' }} />
                  <span>{item.title}</span>
                </FlexBox>
              </StyledNavLink>
            ))}
          </LinksListWrapper>
        </>
      ) : (
        <>
          <CreditsWrapper>
            <CreditTitle>{t('left_side_bar.your_credits')}</CreditTitle>
            <CreditValue>{data?.credits} Cr.</CreditValue>
            <CustomButton
              onClick={() => navigate(`/${role}/profile/packets`)}
              isSavingButton={false}
              name={t('left_side_bar.buy_credits')}
            />
          </CreditsWrapper>
          <LinksListWrapper>
            {businessLink.map((item: any) => (
              <StyledNavLink key={item.id} to={item.href}>
                <FlexBox alignItems="center" gap={1}>
                  <item.icon fontSize="small" />
                  <span>{item.title}</span>
                </FlexBox>
              </StyledNavLink>
            ))}
          </LinksListWrapper>
        </>
      )}
    </MainWrapper>
  );
}
