import * as yup from 'yup';

export const NewPasswordSchema = (t: any) =>
  yup.object({
    newPassword: yup
      .string()
      .required(t('formErrors.required'))
      .min(8, t('formErrors.password.short'))
      .matches(/[0-9]/, t('formErrors.password.number'))
      .matches(/[a-z]/, t('formErrors.password.lowerCase'))
      .matches(/[A-Z]/, t('formErrors.password.upperCase'))
      .matches(/[^\w]/, t('formErrors.password.symbol')),
    confirmNewPassword: yup
      .string()
      .required(t('formErrors.required'))
      .min(8, t('formErrors.password.short'))
      .matches(/[0-9]/, t('formErrors.password.number'))
      .matches(/[a-z]/, t('formErrors.password.lowerCase'))
      .matches(/[A-Z]/, t('formErrors.password.upperCase'))
      .matches(/[^\w]/, t('formErrors.password.symbol'))
      .oneOf([yup.ref('newPassword'), null], t('formErrors.match_password')),
  });
