import { colors } from './../../lib/theme';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Button, Box } from '@mui/material';
import styled from 'styled-components';
import contact_us_bck from '../../assets/icons/contact-us-background.png';

const { text_blue } = colors;

export const ContactUsWrapper = styled.div`
  background: linear-gradient(
      to right,
      rgba(13, 19, 24, 0.65),
      rgba(13, 19, 24, 0.65)
    ),
    url(${contact_us_bck});
  background-repeat: no-repeat;
  height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    height: 150vh;
  }
`;

export const InfoWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-top: 10px;
    gap: 0;
  }
`;

export const InfoTitleWrapper = styled(Typography)`
  font-size: 34px;
  color: white;
  font-style: normal;
  font-weight: 700;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 16px;
  }
`;

export const InfoDescWrapper = styled(Typography)`
  font-size: 14px;
  color: white;
  font-weight: 400;
`;

export const ContactsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 90px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-top: 0;
    grid-gap: 0px;
  }
`;
export const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  margin-top: 2rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-top: 1rem;
  }
`;
export const Email = styled(Typography)`
  margin-left: 1rem;
  color: white;
  font-size: 14px;
  cursor: pointer;
`;

export const Vat = styled(Typography)`
  margin-left: 1rem;
  color: white;
  font-size: 14px;
`;

export const ContactFormWrapper = styled.div`
  border: 1px solid transparent;
  margin-top: 64px;
  width: 80%;
  float: right;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
  }
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const FormInputTitle = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

export const FormsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: flex;
    grid-gap: 0;
    flex-direction: column;
  }
`;

export const SendMessageButton = styled(LoadingButton)`
  width: 100%;
  text-transform: none;
  margin-top: 1rem;
  background-color: ${text_blue};
`;

export const IconsWrapper = styled(Box)`
  display: flex;
  margin-top: 1rem;
`;
