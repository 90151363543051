import { useTranslation } from 'react-i18next';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import CreateNewPassword from '../../modals/components/ForgotPassword/CreateNewPassword/CreateNewPassword';

export default function CreateNewPasswordPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      title={t('auth.create_new_password.title')}
      content={<CreateNewPassword />}
    />
  );
}
