import { useTranslation } from 'react-i18next';
import { UserModel } from '../../../../../../interfaces/types/User';

export default function useCandidateData(data: any) {
  const { t } = useTranslation();
  const infoProps = [
    {
      id: 1,
      title: `${t('editProfile.edit_candidate_profile.full_name')}`,
      value: `${data?.firstName}  ${data?.lastName}`,
    },
    {
      id: 2,
      title: `${t('editProfile.edit_candidate_profile.job_type')}`,
      value: data?.profession
        ?.map((prof: any) => prof.professionName)
        .join(', '),
    },
    {
      id: 3,
      title: `${t('editProfile.country')}`,
      value: data?.country,
    },
    { id: 4, title: `${t('editProfile.email')}`, value: data?.email },
  ];
  return infoProps;
}
