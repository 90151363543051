import { Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../lib/theme';

const { text_gray } = colors;

export const FileName = styled.span`
  font-family: 'Montserrat';
  color: ${text_gray};
  margin-left: 1rem;
`;
export const CvRequired = styled.span`
  font-family: 'Montserrat';
  color: red;
  margin-left: 1rem;
`;
