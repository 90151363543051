import { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { PageTemplateProps } from './PageTemplate.props';

export default function PageTemplate({
  title,
  content,
  ...rest
}: PageTemplateProps): ReactElement {
  return (
    <div>
      <Helmet title={`${title} - My First Job`} />
      {content}
    </div>
  );
}
