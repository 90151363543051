import { useTranslation } from 'react-i18next';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import ConfirmEmail from '../../modals/components/Register/ConfirmEmail/ConfirmEmail';

export default function ConfirmEmailPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate title={t('auth.confirm_email')} content={<ConfirmEmail />} />
  );
}
