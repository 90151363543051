import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import { MainWrapper, Title, TitleWrapper } from '../../../styles';
import UploadVideoPresentation from './UploadVideoPresentation/UploadVideoPresentation';

export default function VideoPresentation() {
  const { user } = useContext(AuthContext);
  const video = user?.videoPresentation;
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t('editProfile.video_presentation')}</Title>
      </TitleWrapper>
      <UploadVideoPresentation video={video} />
    </MainWrapper>
  );
}
