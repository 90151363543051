import { ChangeEvent, FC, ReactElement } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { Clear, PhotoCamera } from '@mui/icons-material';
import { useFilePreview } from '../../hooks/useFilePreview';

interface IGenericProfileUpload {
  selectedFile: File | string | null;
  error?: boolean;
  helperText?: string;
  canDelete?: boolean;
  onSelect: (file: File | null) => void;
  deleteLoading?: boolean;
  updateLoading?: boolean;
}

export const GenericProfileUpload: FC<IGenericProfileUpload> = ({
  selectedFile,
  helperText,
  error,
  canDelete,
  onSelect,
  deleteLoading,
  updateLoading,
}): ReactElement => {
  // hooks
  const { image, onSetImage } = useFilePreview(selectedFile);

  // handlers
  const handleFileSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files && files.length > 0) onSelect(files[0]);
  };

  const handleDeleteProfilePicture = () => {
    onSetImage(null);
    onSelect(null);
  };
  return (
    <>
      <Box position="relative">
        <Button
          component="label"
          disableRipple
          disableTouchRipple
          disableFocusRipple
        >
          {updateLoading ? (
            <CircularProgress />
          ) : (
            <>
              <input
                hidden
                accept="image/png, image/gif, image/jpeg"
                type="file"
                onChange={handleFileSelect}
              />
              <Avatar
                src={image ?? ''}
                variant="rounded"
                sx={{ width: '100px', height: '100px', opacity: 0.5 }}
              />
              {image && (
                <Box
                  display="flex"
                  position="absolute"
                  sx={{ color: 'white' }}
                  flexDirection="column"
                  alignItems="center"
                >
                  <PhotoCamera fontSize="small" />
                  <Typography fontSize="8px">Change Photo</Typography>
                </Box>
              )}
            </>
          )}
        </Button>
        {canDelete && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 85,
              color: '#979bad',
              boxShadow: '0 0 10px #808080',
              border: '1px solid white',
              borderRadius: '50%',
              backgroundColor: 'white',
              transform: 'translate(25%, 25%)',
              cursor: 'pointer',
            }}
          >
            {deleteLoading ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                color="default"
                aria-label="upload picture"
                component="label"
                size="small"
                onClick={handleDeleteProfilePicture}
              >
                <Clear sx={{ width: 15, height: 15 }} />
              </IconButton>
            )}
          </Box>
        )}
        {error && (
          <Typography fontSize={12} maxWidth={200} color="error">
            {helperText}
          </Typography>
        )}
      </Box>
    </>
  );
};
