import { useTranslation } from 'react-i18next';
import {
  InfoWrapper,
  InfoTitleWrapper,
  InfoDescWrapper,
  ContactsWrapper,
  ContactWrapper,
  Email,
  Vat,
  IconsWrapper,
} from '../styles';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import instaLogo from '../../../assets/icons/instagram-icon.png';
import fbLogo from '../../../assets/icons/facebook-icon.png';
import linkedInLogo from '../../../assets/icons/linkedin_icon.png';
import ytLogo from '../../../assets/icons/youtube_icon.png';

export default function ContactUsInfos() {
  const { t } = useTranslation();
  return (
    <InfoWrapper>
      <div>
        <InfoTitleWrapper>{t('contact_us.get_in_touch')}</InfoTitleWrapper>
        <InfoDescWrapper>{t('contact_us.description')}</InfoDescWrapper>
      </div>
      <ContactsWrapper>
        <div>
          <ContactWrapper>
            <EmailIcon sx={{ color: '#72C2CC' }} />
            <Email
              onClick={(e) => {
                window.location.href = 'mailto:info@my1job.be';
                e.preventDefault();
              }}
            >
              info@my1job.be
            </Email>
          </ContactWrapper>
          <ContactWrapper>
            <PlaceIcon sx={{ color: '#72C2CC' }} />
            <Email
              onClick={() =>
                window.open(
                  'https://www.google.com/maps/place/Steenweg+op+Ukkel+143,+1650+Beersel,+Belgique/@50.7702333,4.3100972,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3c5f71fb0ed4f:0xac41c5161c546ada!8m2!3d50.7702333!4d4.3122859',
                  '_blank'
                )
              }
            >
              Steenweg op Ukkel 143 - 1650 Beersel
            </Email>
          </ContactWrapper>
          <ContactWrapper>
            <p style={{ color: '#72C2CC', fontFamily: 'Montserrat' }}>VAT</p>
            <Vat>BE 0694.871.178</Vat>
          </ContactWrapper>
        </div>
        <IconsWrapper>
          <img
            style={{ marginRight: '1rem', cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.instagram.com/myfirstjob01/', '_blank')
            }
            src={instaLogo}
          />
          <img
            style={{ marginRight: '1rem', cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.facebook.com/my1job.be', '_blank')
            }
            src={fbLogo}
          />
          <img
            style={{ marginRight: '1rem', cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://www.linkedin.com/company/89610471/admin/',
                '_blank'
              )
            }
            src={linkedInLogo}
          />
          <img
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://www.youtube.com/channel/UCXQw5DktyC03RsGBKEHt0_w',
                '_blank'
              )
            }
            src={ytLogo}
          />
        </IconsWrapper>
      </ContactsWrapper>
    </InfoWrapper>
  );
}
