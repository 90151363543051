import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BusinessFeedCards from '../../../components/FeedCards/BusinessFeedCards';
import PageTemplate from '../../../components/PageTemplate/PageTemplate';

export default function FeedBusiness() {
  const { t } = useTranslation();
  return (
    <Grid item lg={6} md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate
        title={t('left_side_bar.feed')}
        content={<BusinessFeedCards />}
      />
    </Grid>
  );
}
