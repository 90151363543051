import * as yup from 'yup';

export const LinguisticKnowledgeFormSchema = (t: any) =>
  yup.object({
    languageId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    languageSkillLevelId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
  });
