import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { Description, PrivacyTitle, SubTitle, Title, Wrapper } from './styles';
import { DialogContent } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrivacyPolicy({ name, color }: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  return (
    <>
      <PrivacyTitle onClick={handleClickOpen} color={color}>
        {name}
      </PrivacyTitle>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#72C2CC' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Déclaration de confidentialité
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Wrapper>
            <Title>Déclaration de confidentialité</Title>
            <Description>
              <p>
                Merci de votre visite sur notre plateforme digitale. MyFirstJob
                (ou My1Job) attache une importance particulière à la protection
                et à la confidentialité de vos données.
              </p>
              <br />
              <p>
                Dans ce qui suit, nous vous informons du traitement des données
                personnelles en relation avec les services que nous offrons sous
                www.my1job.be ou d’autres sites web ou applications (ci-après
                dénommés collectivement « plateformes ») qui intègrent cette
                déclaration de confidentialité.
              </p>
              <br />
              <p>
                On entend par données personnelles toutes les informations
                relatives à une personne physique identifiée ou identifiable
                (art. 4 n° 1 RGPD). Cela inclut des informations telles que
                votre nom, votre adresse e-mail, votre vidéo de présentation
                digitale votre adresse postale ou votre numéro de téléphone. Les
                informations qui ne sont pas directement associées à votre
                identité, comme le nombre d’utilisateurs d’un site Internet,
                n’entrent pas dans cette catégorie
              </p>
            </Description>
            <Title>Déclaration de confidentialité</Title>
            <SubTitle>
              Qui est responsable du traitement de vos données à caractère
              personnel ?
            </SubTitle>
            <Description>
              <p>
                Le responsable du traitement (ci-après dénommé « MyFirstJob » ou
                « My1Job » ou « nous ») au sens du règlement général sur la
                protection des données ainsi que des autres lois nationales des
                États membres sur la protection des données et des autres
                réglementations sur la protection des données applicables est :
              </p>
              <br />
              <p>My First Job SPRL/BVBA</p>
              <br />
              <p>Steenweg op Ukkel</p>
              <p> 1650 Beersel</p>
              <p> Belgium </p>
              <br />
              <p>Tel: 0478 55 63 37</p>
              <br /> E-mail : erwin.schellens@hotmail.com
            </Description>
            <SubTitle>
              2. Coordonnées du délégué à la protection des données
            </SubTitle>
            <Description>
              Vous pouvez contacter notre CEO concernant la protection des
              données par e-mail à erwin.schellens@hotmail.com
            </Description>
            <SubTitle>
              3. Finalités et bases juridiques du traitement et durée de
              conservation
            </SubTitle>
            <Description>
              Nous traitons les données à caractère personnel à des fins
              diverses, qui peuvent être regroupées de manière générale comme
              suit :
            </Description>
            <ul>
              <li>
                Utilisation générale de nos plateformes et de nos services, dans
                le cadre de laquelle notre traitement des données est fondé sur
                un intérêt légitime (poursuite des intérêts de notre entreprise)
                ou sur votre consentement
              </li>
              <li>
                Utilisation d’un agent de recherche automatique ou d’un compte
                MyFirstJob, dans le cadre de laquelle notre traitement des
                données est fondé sur un contrat.
              </li>
              <li>
                Traitement d’informations sur les entreprises et leurs employés,
                dans le cadre duquel notre traitement des données est fondé sur
                un intérêt légitime, un contrat ou sur une obligation légale.
              </li>
            </ul>
            <Description>
              Les données à caractère personnel de la personne concernée sont
              conservées aussi longtemps que la finalité existe mais ceci pour
              une durée de maximum 18 mois afin de garantir la finalité du
              business modèle. Pour plus de détails, voir la version longue de
              la déclaration de confidentialité.
            </Description>
            <SubTitle>
              4. Destinataires ou catégories de destinataires de données à
              caractère personnel
            </SubTitle>
            <Description>
              Nous avons recours à des sous-traitants. Par conséquent, ceux-ci
              peuvent recevoir des données à caractère personnel. En outre, nous
              pouvons également transmettre ou fournir des données à des tiers
              dans le cadre de votre consentement ou d’un contrat conclu avec
              vous, afin qu’ils puissent recevoir des données comme décrit dans
              le présent document.
            </Description>
            <SubTitle>
              5. Transfert de données vers des pays hors UE ou EEE
            </SubTitle>
            <Description>
              Dans certains cas, nous transférons des informations personnelles
              vers des pays en dehors de l’UE ou de l’EEE (appelés pays tiers).
              Cela peut être le cas si vous postulez à un poste et que
              l’employeur potentiel est basé dans un pays tiers. En outre, nous
              avons recours à des sous-traitants ou, dans le cadre d’un intérêt
              légitime, à des prestataires de services qui traitent les données
              parfois dans des pays tiers
            </Description>
            <SubTitle>
              6. Durée de conservation des données à caractère personnel
            </SubTitle>
            <Description>
              D’une manière générale, les données à caractère personnel sont
              conservées aussi longtemps que la finalité existe mais cas échéant
              pour une durée de maximum 18 mois afin de garantir la finalité du
              business modèle.
            </Description>
            <SubTitle>7. Droits des personnes concernées</SubTitle>
            <Description>
              <p>
                Vous disposez de différents droits relatifs au traitement des
                données à caractère personnel. Vous disposez (selon le cas) du
                droit de demander l’accès aux données à caractère personnel
                (article 15 RGPD), d’un droit de rectification (article 16 RGPD)
                ou d’un droit de suppression (article 17 du RGPD), d’un droit à
                la limitation du traitement (article 18 RGPD), d’un droit
                d’opposition (article 21 RGPD) et d’un droit à la portabilité
                des données (article 20 RGPD).{' '}
              </p>
              <br />
              <p>
                Si le traitement est fondé sur le consentement, vous pouvez
                retirer votre consentement à tout moment.
              </p>{' '}
              <br />
              <p>
                Si vous pensez que le traitement de vos données à caractère
                personnel est en violation du RGPD, vous avez le droit de
                déposer une réclamation auprès d’une autorité de contrôle.
              </p>
            </Description>
            <SubTitle>
              8. Existe-t-il une obligation de fournir des renseignements
              personnels ?
            </SubTitle>
            <Description>
              <p>
                Si vous voulez créer un compte MyFirstJob ou si vous voulez
                utiliser nos services en tant que client professionnel, vous
                devez fournir certaines données dans le cadre du contrat à
                conclure. Nous préciserons ces données. Dans tout autre
                contexte, la communication de données à caractère personnel
                n’est ni exigée par la loi ni par contrat, et vous n’êtes pas
                non plus tenu de fournir des renseignements personnels.
              </p>
              <br />
              <p>
                Toutefois, la communication de données à caractère personnel
                pour l’utilisation de nos services peut également être
                partiellement requise dans le cadre des services que nous
                fournissons. En d’autres termes, si vous ne nous communiquez pas
                les renseignements que nous spécifions comme étant nécessaires,
                nous pourrions ne pas être en mesure de vous fournir la totalité
                de nos services.
              </p>
            </Description>
            <SubTitle>
              9. Modification de la politique de confidentialité ; changement de
              finalité
            </SubTitle>
            <Description>
              Nous nous réservons le droit de modifier notre déclaration de
              confidentialité conformément à la réglementation sur la protection
              des données. La version en vigueur est disponible ici ou à un
              autre endroit facile à trouver sur notre plateforme. Si nous
              décidons de traiter vos données à des fins autres que celles pour
              lesquelles elles ont été collectées, nous vous en informerons au
              préalable conformément à la loi.
            </Description>
          </Wrapper>
        </DialogContent>
      </Dialog>
    </>
  );
}
