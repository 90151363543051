import { TextField, Typography } from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';
import { InputFieldProps } from './InputField.props';

export const InputField = forwardRef(
  (
    { fieldError, ...rest }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ): ReactElement => (
    <div>
      <TextField inputRef={ref} error={!!fieldError} {...rest} />
      {fieldError && (
        <Typography sx={{ color: 'red' }} variant="caption" display="block">
          {fieldError?.message}
        </Typography>
      )}
    </div>
  )
);

InputField.displayName = 'InputField';
