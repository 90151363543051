import { Typography } from '@mui/material';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: inline-grid;
  margin-top: 1rem;
  grid-template-columns: 500px 1fr;
  grid-gap: 200px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: inline-flex;
    flex-direction: column;
    grid-gap: 10px;
  }
`;

export const Title = styled(Typography)`
  font-size: 34px;
  font-weight: bold;
  white-space: pre-line;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 16px;
  }
`;

export const Description = styled(Typography)`
  font-size: 14px;
  text-align: justify;
  white-space: pre-line;
  font-weight: bold;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const AboutUsImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-top: 16px;
`;
