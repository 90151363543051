import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import SearchCandidate from '../../components/SearchCandidate/SearchCandidate';

export default function SearchCandidatePage() {
  return (
    <Container sx={{ position: 'relative', top: '20px' }}>
      <Grid container>
        <Grid item sm={3}></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{ display: { xs: 'block', md: 'block' } }}
        >
          <SearchCandidate />
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
    </Container>
  );
}
