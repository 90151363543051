import { Container, Grid } from '@mui/material';
import React from 'react';
import ContactUsForm from './ContactUsForm/ContactUsForm';
import ContactUsInfos from './ContactUsInfos/ContactUsInfos';
import { ContactUsWrapper } from './styles';

export default function ContactUs() {
  return (
    <ContactUsWrapper>
      <Container>
        <Grid container component="main">
          <Grid
            item
            xs={12}
            sm={0}
            md={6}
            sx={{ display: { xs: 'flex', md: 'block' } }}
          >
            <ContactUsInfos />
          </Grid>
          <Grid
            item
            xs={12}
            sm={0}
            md={6}
            sx={{ display: { xs: 'flex', md: 'block' } }}
          >
            <ContactUsForm />
          </Grid>
        </Grid>
      </Container>
    </ContactUsWrapper>
  );
}
