import { Button, IconButton, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';
import { colors } from '../../../../lib/theme';

const { text_gray, upload_picture_background, text_blue } = colors;

export const SignUpTitleWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const SignUpTypo = styled(Typography)`
  margin-top: 2rem;
  font-family: 'Impact';
`;

export const IconsWrapper = styled.div`
  position: relative;
`;

export const IconEditWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${text_gray};
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  transform: translate(25%, -25%);
`;

export const IconClearWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${text_gray};
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  transform: translate(25%, 25%);
`;

export const UploadPictureIcon = styled(Button)`
  border-radius: 0.25rem;
  color: white;
  padding: 2.5rem;
  background-color: ${upload_picture_background};
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
`;

export const ImportantInfoWrapper = styled(Typography)`
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

export const RegisterButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  font-weight: bold;
  width: 100%;
  color: white;
  text-transform: none;
  background-color: ${text_blue};
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  color: ${text_gray};
  font-size: 0.75rem;
`;

export const ButtonUpload = styled(Button)`
  font-family: 'Montserrat';
  color: ${text_gray};
  font-size: 0.75rem;
`;
