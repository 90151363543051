import { Card, Typography, Button, Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../../lib/theme';

const { text_blue, businessCardBg, position_text_color, text_gray } = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${businessCardBg};
  align-items: center;
`;

export const Title = styled(Typography)`
  font-family: 'Montserrat';
  font-weight: bold;
`;

export const EditButton = styled(Button)`
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
  text-transform: none;
  background-color: ${text_blue};
`;

export const InfosWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const InfoProp = styled(Typography)`
  width: 50%;
  font-family: 'Montserrat';
  margin-bottom: 0.5rem;
  color: ${position_text_color};
  font-size: 12px;
`;

export const InfoFromDb = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: bold;
`;

export const InfoMainWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const InfoTitle = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  font-family: 'Montserrat';
`;

export const ItemTitle = styled(Typography)`
  font-size: 14px;
  font-family: 'Montserrat';
  color: ${businessCardBg};
`;

export const DateWrapper = styled(Typography)`
  font-size: 12px;
  font-family: 'Montserrat';
  color: ${text_gray};
`;

export const DescriptionWrapper = styled(Typography)`
  font-size: 12px;
  font-family: 'Montserrat';
  color: ${text_gray};
`;
export const ExperienceWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    border-bottom: 1px dashed ${businessCardBg};
  }
  padding: 0.2rem 0 0.2rem 0;
`;

export const ExperienceLogo = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 0.5rem;
  object-fit: cover;
`;

export const ExperienceInfoWrapper = styled.div``;
export const Name = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
`;
export const Position = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 12px;
  color: ${text_gray};
`;

export const DataLocation = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 12px;
  color: ${text_gray};
`;

export const VideoWrapper = styled(Box)`
  margin-top: 1rem;
  position: relative;

  video {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 20px;
  }
`;

export const Wrapper = styled.div`
  :not(:last-child) {
    border-bottom: 1px dashed ${businessCardBg};
  }
  padding: 0.2rem 0 0.2rem 0;
`;
export const AvatarWrapper = styled.div`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: 50%;
  padding: 1rem;
  margin-right: 0.5rem;
`;
