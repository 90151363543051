import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../lib/context/AuthContext';
import { Description, InfoWrapper, MainWrapper, Title } from './styles';

export default function DataVerification() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <>
      {user?.hasRequestProfileModifications && (
        <MainWrapper>
          <InfoWrapper>
            <Title>{t('verification.pending.title')}</Title>
            <Description>{t('verification.pending.description')}</Description>
          </InfoWrapper>
        </MainWrapper>
      )}
    </>
  );
}
