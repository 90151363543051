import { colors } from './../../../../../../../lib/theme';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';

const { text_blue } = colors;

export const CardElementWrapper = styled.div``;

export const PayPlanButton = styled(LoadingButton)`
  width: 100%;
  background-color: ${text_blue};
  margin-top: 1rem;
`;
