import { Typography, Button, MenuItem, Menu, Card } from '@mui/material';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { colors } from '../../../lib/theme';

const { businessCardBg, linkBackgroundColor, personCardBg, text_blue } = colors;

export const LogoIcon = styled.img``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SignUpButton = styled(Link)`
  color: white;
  border: 1px solid white;
  border-radius: 15px;
  margin: 0 2rem 0 2rem;
  padding: 1rem;
`;
export const LoginButton = styled(Link)`
  color: white;
  padding: 1rem;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AvatarWrapper = styled.div<{ isCandidate: boolean }>`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: ${(props) => (props.isCandidate ? '50%' : '10px')};
  padding: 1rem;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;
export const InfoWrapper = styled.div``;
export const Name = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

export const Email = styled(Typography)`
  font-size: 10px;
`;

export const Image = styled.img<{ isCandidate: boolean }>`
  border: 1px solid transparent;
  border-radius: ${(props) => (props.isCandidate ? '50%' : '10px')};
  margin-right: 0.5rem;
  width: 25px;
  height: 25px;
  object-fit: cover;
`;

export const StyledLogoutButton = styled(Button)`
  text-transform: none;
  font-weight: bold;
  width: 100%;
  color: white;
  text-transform: none;
  background-color: ${text_blue};
`;

export const MenuItemWrapper = styled.div`
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.5s;
`;

export const MenuWrapper = styled.div<{ open: boolean }>`
  height: ${(props) => (props.open ? 'auto' : 0)};
  width: ${(props) => (props.open ? '100%' : 0)};
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  text-align: center;
`;

export const StyledLinkMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  margin: 0.5rem 0.5rem;
  font-size: 12px;
  justify-content: space-between;
  &:hover,
  &:active {
    background-color: ${linkBackgroundColor};
    color: ${personCardBg};
  }
`;

export const StyledNoLinkMenuItem = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  margin: 0.5rem 0.5rem;
  font-size: 12px;
  justify-content: space-between;
`;

export const LanguagesButton = styled.div`
  display: flex;
`;

export const LanguageButton = styled(Button)`
  &:hover {
    background-color: transparent;
  }
`;

export const RightSideNavigation = styled.div`
  display: flex;
  align-items: center;
`;

export const AuthButton = styled(Link)<{
  isLoginButton: boolean;
  homePage: boolean;
  small: boolean;
}>`
  color: ${(props) =>
    (!props.homePage || props.small) && props.isLoginButton
      ? businessCardBg
      : 'white'};
  background-color: ${(props) =>
    (!props.homePage || props.small) && !props.isLoginButton && businessCardBg};
  border: ${(props) => (props.isLoginButton ? 'none' : '1px solid white')};
  border-radius: 15px;
  padding: 1rem 2rem;
  font-family: 'Montserrat';
  font-size: 12px;
`;

export const LanguagesWrapper = styled.div`
  border-right: 0.6px solid #dddddd;
  padding-right: 1rem;
  margin-right: 1rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0;
    padding: 0;
  }
`;

export const ProfileMainWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto auto;
  cursor: pointer;
  margin-left: 1rem;
`;

export const ImageWrapper = styled.div``;

export const ProfileInfoWrapper = styled.div``;

export const MenuDesktopInfoWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  border-bottom: 0.5px solid rgba(224, 224, 224, 0.5);
  padding-bottom: 1rem;
`;

export const MenuItemDesktopNav = styled(MenuItem)`
  font-size: 10px;
  color: black;
  font-family: 'Montserrat';
  font-weight: 500;
`;

export const MainInfoWrapperMobileMenu = styled(Card)`
  border: 1px solid transparent;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100vh;
`;

export const ProfileInfoMobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;
`;

export const NoLinksDataMobileMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(200, 200, 200, 0.16);
`;

export const NoLinkItemMobileMenu = styled.div`
  display: flex;
  align-items: baseline;
`;

export const LinksMobileMenu = styled.div`
  background-color: #fafafa;
  margin: 1rem 1rem;
  padding: 1rem 1rem;
`;

export const ItemLabel = styled(Typography)`
  font-size: 10px;
  // align-self: end;
`;
export const ItemCount = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  margin-right: 0.2rem;
`;

export const LinkDataMobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignoutButton = styled(Button)`
  text-transform: none;
  border: 1px solid #72c2cc;
  width: 100%;
  color: #72c2cc;
`;

export const StyledNavLinkHeader = styled(NavLink)`
  font-family: 'Montserrat';
  margin: 0 2rem;
  font-size: 12px;
  font-weight: bold;
  &.active {
    color: #72c2cc;
  }
`;
