import { useMutation, useQueryClient } from '@tanstack/react-query';
import { readNotifications, readNotification } from '../mutations/notification';

export const useReadNotificationsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(['read-notifications'], readNotifications, {
    onSuccess: () => queryClient.fetchQuery(['notifications']),
  });
};

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['read-notification'],
    (id: number) => readNotification(id),
    {
      onSuccess: () => queryClient.fetchQuery(['notifications']),
    }
  );
};
