import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountCreationWrapper, MainWrapper, Title } from '../styles';
import { AccountDescription, AccountTitle, NumberWrapper } from './styles';

export default function HowToCreateAccount() {
  const { t } = useTranslation();
  const list = [
    `${t('home.how_to_create_account.video_presentation.description.1')}`,
    `${t('home.how_to_create_account.video_presentation.description.2')}`,
    `${t('home.how_to_create_account.video_presentation.description.3')}`,
    `${t('home.how_to_create_account.video_presentation.description.4')}`,
    `${t('home.how_to_create_account.video_presentation.description.5')}`,
  ];
  const staticData = [
    {
      id: 1,
      title: t('home.how_to_create_account.create_your_profile.title'),
      desription: t(
        'home.how_to_create_account.create_your_profile.description'
      ),
    },
    {
      id: 2,
      title: t('home.how_to_create_account.video_presentation.title'),
      desription: t(
        'home.how_to_create_account.video_presentation.description'
      ),
    },
    {
      id: 3,
      title: `${t('home.how_to_create_account.get_in_contact.title')}`,
      desription: `${t(
        'home.how_to_create_account.get_in_contact.description'
      )}`,
    },
  ];
  return (
    <Container>
      <MainWrapper>
        <Title>{t('home.how_to_create_account.title')}</Title>
        <AccountCreationWrapper>
          {staticData.map((data) => (
            <div
              key={data.id}
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gap: '10px',
              }}
            >
              <NumberWrapper>{data.id}</NumberWrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <AccountTitle>{data.title}</AccountTitle>
                <AccountDescription>{data.desription}</AccountDescription>
              </div>
            </div>
          ))}
        </AccountCreationWrapper>
      </MainWrapper>
    </Container>
  );
}
