import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import {
  Description,
  DescriptionItem,
  DescriptionWrapper,
  IconWrapper,
  Image,
  InfoWrapper,
  MainWrapper,
  MoreInfoAboutItem,
  Title,
  TitleItem,
  VideoWrapperStyle,
} from './styles';
import home_first from '../../../../assets/icons/home_what_it_is_first.png';
import home_second from '../../../../assets/icons/home_what_it_is_second.png';
import { VideocamRounded } from '@mui/icons-material';
import business_search from '../../../../assets/icons/business-job-search-symbol.png';
import ReactPlayer from 'react-player';
import myfirstjob from '../../../../assets/images/upload_video_presentation.png';

export default function WhatItIsComponent() {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const staticData = [
    {
      id: 1,
      title: `${t('home.what_it_is.aboutFirstItemTitle')}`,
      image: home_first,
      icon: <VideocamRounded sx={{ color: 'white' }} />,
      description: `${t('home.what_it_is.aboutFirstItemDesc')}`,
    },
    {
      id: 2,
      title: `${t('home.what_it_is.aboutSecondItemTitle')}`,
      icon: <img src={business_search} />,
      image: home_second,
      description: `${t('home.what_it_is.aboutSecondItemDesc')}`,
    },
  ];

  return (
    <Container>
      <MainWrapper>
        {/* <Title>{t('home.what_it_is.title')}</Title>
        <Description>
          <Trans
            i18nKey='home.what_it_is.description'
            values={{
              name: `<span>${t('home.what_it_is.name')}</span>`,
            }}
            components={{
              span: <span style={{ color: '#72C2CC', fontWeight: 'bolder' }} />,
            }}
          />
        </Description> */}
        <Box>
          {staticData.map((data) => (
            <MoreInfoAboutItem key={data.id}>
              {data.id === 1 ? (
                <VideoWrapperStyle>
                  <ReactPlayer
                    light={myfirstjob}
                    url='https://myfirstjob.blob.core.windows.net/webapp/video-my1job.mp4'
                    controls
                    width={mobileScreen ? '100%' : '540px'}
                  />
                </VideoWrapperStyle>
              ) : (
                <Image src={data.image} />
              )}
              <InfoWrapper>
                <IconWrapper>{data.icon}</IconWrapper>
                <DescriptionWrapper>
                  <TitleItem>{data.title}</TitleItem>
                  <DescriptionItem>{data.description}</DescriptionItem>
                </DescriptionWrapper>
              </InfoWrapper>
            </MoreInfoAboutItem>
          ))}
        </Box>
      </MainWrapper>
    </Container>
  );
}
