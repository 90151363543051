import axios from '../../utils/axios';

/**
 * read all notifications
 */
export const readNotifications = async () =>
  await axios.post('Notification/markNotificationsAsRead');

/**
 * read notification by id
 */
export const readNotification = async (id: number) =>
  await axios.post(`Notification/readNotification/${id}`);
