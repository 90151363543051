import { ChangeEvent } from 'react';
import { Clear, CropOriginalRounded } from '@mui/icons-material';
import { Avatar, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useFilePreview } from '../../../../../../../../hooks/useFilePreview';
import { AddExperienceModel } from '../../../../../../../../interfaces/types/User';
import PreviewImage from '../../../../../../Register/UploadImage/PreviewImage/PreviewImage';
import { colors } from '../../../../../../../../lib/theme';
import {
  IconsWrapper,
  IconClearWrapper,
} from '../../../ProfileDetails/EditProfileDetails/styles';
import { useDeleteWorkExperienceLogo } from '../../../../../../../../rq/hooks/useEditProfile.mutation';

export default function CompanyLogo({ logo, fieldId, setOpen }: any) {
  const { watch, setValue } = useFormContext<AddExperienceModel>();
  const file = watch('companyLogo');
  const { image, onSetImage } = useFilePreview(file);
  const { mutate: deleteLogo } = useDeleteWorkExperienceLogo();

  const handleDeleteProfilePicture = () => {
    if (logo) {
      deleteLogo({ workExperienceId: fieldId });
      setOpen(false);
    }
    onSetImage(null);
    setValue('companyLogo', null);
  };
  const { upload_picture_background } = colors;

  // handlers
  const handleFileSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files && files.length !== 0) setValue('companyLogo', files[0]);
  };
  return (
    <IconsWrapper>
      {logo ? (
        <Avatar
          src={logo}
          variant="rounded"
          sx={{ width: '100px', height: '100px', objectFit: 'cover' }}
        />
      ) : image ? (
        <PreviewImage imgSrc={image} />
      ) : (
        <Button
          component="label"
          sx={{
            borderRadius: ' 0.25rem',
            color: 'white',
            padding: '2.5rem',
            backgroundColor: `${upload_picture_background}`,
            boxShadow:
              '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
          }}
        >
          <input
            hidden
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleFileSelect}
            // {...register('companyLogo')}
          />
          <CropOriginalRounded className="text-white" />
        </Button>
      )}
      <IconClearWrapper onClick={handleDeleteProfilePicture}>
        <Clear sx={{ padding: '1px' }} />
      </IconClearWrapper>
    </IconsWrapper>
  );
}
