import { useTranslation } from 'react-i18next';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import RegisterBusiness from '../../modals/components/Register/RegisterBusiness/RegisterBusiness';

export default function RegisterBusinessPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      title={t('auth.sign_up.business')}
      content={<RegisterBusiness />}
    />
  );
}
