import { NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const FooterWrapper = styled.div<{ homePage: boolean }>`
  background-color: ${(props) => (props.homePage ? 'black' : 'white')};
  color: ${(props) => (props.homePage ? 'white' : 'black')};
  padding-top: 3rem;
  margin-top: 1rem;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterItemFirst = styled.div`
  // align-items: center;
  // justify-content: center;
`;
export const FooterItemSecond = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
    margin-top: 1rem;
  }
`;

export const FooterSubItemFirst = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const FooterSubItemSecond = styled(Box)`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
  }
`;

export const Icon = styled.img`
  width: 104px;
  height: 24px;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  margin-top: 2rem;
  white-space: pre-line;
`;

export const IconsWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
`;

export const IconSocialMedia = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 1rem;
  object-fit: cover;
`;

export const FooterItem = styled(NavLink)`
  font-size: 14px;
  font-family: 'Montserrat';
`;

export const CustomTypography = styled(Typography)`
  font-size: 14px;
`;
