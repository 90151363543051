import { useEffect } from 'react';
import { DialogContent, DialogActions, Box } from '@mui/material';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import {
  FormWrapper,
  Typo,
  GeneralInfo,
  SaveButton,
  DeleteButton,
  LogoWrapper,
} from '../../styles';
import { FormProvider, useForm } from 'react-hook-form';
import { AddExperienceModel } from '../../../../../../../interfaces/types/User';
import { InputField } from '../../../../../InputField/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useEditExperience,
  useDeleteWorkExperience,
} from '../../../../../../../rq/hooks/useEditProfile.mutation';
import { AddExperienceSchema } from '../../../../../../../validators/EditProfile.validator';
import { months } from '../../monthYear';
import CompanyLogo from './CompanyLogo/CompanyLogo';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../../../../src/components/BootstrapDialog/BootstrapDialog';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { year } from '../../../../../../../static';
import Select from '../../../../../../../components/Select/Select';

export default function EditExperience({ field, open, setOpen }: any) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const methods = useForm<AddExperienceModel>({
    resolver: yupResolver(AddExperienceSchema(t)),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors: { enterpriseName, location, position, startMonth, startYear },
    },
  } = methods;

  useEffect(() => {
    if (field) {
      reset({ ...field });
    }
  }, [field, reset]);
  //   react-mutation
  const { mutate: editExperience, isLoading: editLoading } = useEditExperience(
    field?.candidateWorkExperienceId
  );
  const { mutate: deleteExperience, isLoading: deleteLoading } =
    useDeleteWorkExperience({
      onSuccess: () => {
        setOpen(false);
        toast.success('Deletion was successfull');
        queryClient.fetchQuery(['profile']);
      },
    });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: AddExperienceModel) => {
    const formData: any = new FormData();
    formData.append('enterpriseName', data.enterpriseName);
    formData.append('position', data.position);
    formData.append('startYear', data.startYear);
    formData.append('startMonth', data.startMonth);
    formData.append('endYear', data.endYear);
    formData.append('endMonth', data.endMonth);
    if (data.companyLogo) {
      formData.append('companyLogo', data.companyLogo);
    }
    formData.append('location', data.location);
    editExperience(formData, {
      onSuccess: () => {
        setOpen(false);
        toast.success(t('successMessage.admin_verification'));
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {t('editProfile.edit_candidate_profile.experience.title_edit')}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <FormWrapper>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.general_info')}
                </Typo>
                <GeneralInfo>
                  <InputField
                    size="small"
                    fullWidth
                    label={t(
                      'editProfile.edit_candidate_profile.experience.name'
                    )}
                    defaultValue={field?.enterpriseName}
                    {...register('enterpriseName')}
                    fieldError={enterpriseName}
                  />
                  <InputField
                    size="small"
                    fullWidth
                    label={t(
                      'editProfile.edit_candidate_profile.experience.position'
                    )}
                    defaultValue={field?.position}
                    {...register('position')}
                    fieldError={position}
                  />
                </GeneralInfo>
              </div>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.start_date')}
                </Typo>
                <GeneralInfo>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mr: 1 }}
                    error={!!startMonth}
                  >
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.startMonth ?? ''}
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('startMonth')}
                      fieldError={startMonth}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" error={!!startYear}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.startYear ?? ''}
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('startYear')}
                      fieldError={startYear}
                    />
                  </FormControl>
                </GeneralInfo>
              </div>
              <div>
                <Typo>{t('editProfile.edit_candidate_profile.end_date')}</Typo>
                <GeneralInfo>
                  <FormControl fullWidth size="small" sx={{ mr: 1 }}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.endMonth ?? ''}
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('endMonth')}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small">
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.endYear ?? ''}
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('endYear')}
                    />
                  </FormControl>
                </GeneralInfo>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Typo>
                    {t(
                      'editProfile.edit_candidate_profile.experience.location'
                    )}
                  </Typo>
                  <InputField
                    size="small"
                    fullWidth
                    label={t(
                      'editProfile.edit_candidate_profile.experience.location'
                    )}
                    defaultValue={field?.location}
                    {...register('location')}
                    fieldError={location}
                  />
                </div>
              </div>
              <GeneralInfo>
                <FormControl fullWidth size="small">
                  <Typo>
                    {t('editProfile.edit_candidate_profile.experience.logo')}
                  </Typo>
                  <LogoWrapper>
                    <CompanyLogo
                      logo={field?.companyLogo}
                      fieldId={field?.candidateWorkExperienceId}
                      setOpen={setOpen}
                    />
                  </LogoWrapper>
                </FormControl>
              </GeneralInfo>
            </FormWrapper>
          </DialogContent>
          <DialogActions>
            <DeleteButton
              onClick={() => {
                deleteExperience(field?.candidateWorkExperienceId);
              }}
              loading={deleteLoading}
              variant="contained"
              autoFocus
            >
              {t('editProfile.edit_candidate_profile.delete')}
            </DeleteButton>
            <SaveButton
              loading={editLoading}
              variant="contained"
              type="submit"
              autoFocus
            >
              {t('editProfile.edit_candidate_profile.save')}
            </SaveButton>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </FormProvider>
  );
}
