import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Card, Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../../../lib/theme';
import CheckIcon from '@mui/icons-material/Check';

const {
  businessCardBg,
  position_text_color,
  text_blue,
  discard_button_color,
  text_gray,
  upload_picture_background,
} = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
`;

export const TitleWrapper = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid ${businessCardBg};
`;

export const FormWrapper = styled.div``;
export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  margin-top: 1rem;
  &:not(:first-child) {
    align-items: center;
  }
  grid-template-columns: 120px 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;
export const FormPropWrapper = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

export const AvatarWrapper = styled.img`
  width: 100px;
  height: 100px;
  border: 1px solid transparent;
  border-radius: 15px;
`;

export const ProfileWrapper = styled.div`
  border-bottom: 1px solid ${businessCardBg};
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1rem;
`;
export const DiscardButton = styled(Button)`
  font-family: 'Montserrat';
  font-weight: bold;
  text-transform: none;
  color: black;
  background-color: ${discard_button_color};
  margin-right: 1rem;
  &:hover {
    background-color: ${discard_button_color};
  }
`;
export const SaveChangesButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  color: white;
  text-transform: none;
  border-radius: 15px;
  background-color: #2e98fa;
`;

export const DeactivateButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  color: white;
  text-transform: none;
  border-radius: 15px;
  background-color: #de506e;
  &:hover {
    background-color: #de506e;
  }
`;

export const IconsWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: fit-content;
`;

export const IconClearWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${text_gray};
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  transform: translate(25%, 25%);
  &:hover {
    color: red;
    cursor: pointer;
  }
`;

export const AvatarImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 0.25rem;
  object-fit: cover;
`;

export const BpIcon = styled('span')({
  backgroundColor: '#F4F7F9',
  width: '15px',
  height: '15px',
  color: 'white',
  fontSize: '15px',
  borderRadius: '5px',
});

export const BpCheckedIcon = styled(CheckIcon)({
  backgroundColor: '#137cbd',
  color: 'white',
  fontSize: '15px',
  borderRadius: '5px',
});
