import LoadingButton from '@mui/lab/LoadingButton';
import { colors } from './../../../../../../lib/theme';
import { Dialog, Typography, Button } from '@mui/material';
import styled from 'styled-components';
const { businessCardBg } = colors;

export const DialogWrapper = styled(Dialog)``;

export const DialogContentWrapper = styled.div``;

export const CreditsTitle = styled(Typography)`
  margin: 1rem 0;
`;

export const Icon = styled.img``;

export const ButtonUnclock = styled(LoadingButton)`
  text-transform: none;
  background-color: ${businessCardBg};
  color: white;
  padding: 0.5rem 2rem;
  &:hover {
    background-color: ${businessCardBg};
  }
`;
