import { Container, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from '../../../../../../components/Card/Card';
import CircularIndeterminate from '../../../../../../components/CircularIndeterminate/CircularIndeterminate';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import { useGetAllCandidateCardsQuery } from '../../../../../../rq/hooks/useQueries';
import {
  SharedVideoDescription,
  SharedVideoTitle,
  SharedVideoWrapper,
} from './styles';

export default function SharedCandidateCard() {
  const { id } = useParams();
  const { data, isLoading } = useGetAllCandidateCardsQuery(1, id);
  const { t } = useTranslation();
  return (
    <Container sx={{ position: 'relative', top: '20px' }}>
      <Grid container component="main">
        <Grid item xs={3}></Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '1rem' }}>
          <SharedVideoWrapper>
            <SharedVideoTitle>{t('shared_video.title')}</SharedVideoTitle>
            <SharedVideoDescription>
              {t('shared_video.description')}
            </SharedVideoDescription>
          </SharedVideoWrapper>
          {isLoading ? (
            <CircularIndeterminate />
          ) : (
            data.data.map((card: any) => (
              <Card
                profilePicture={card?.profilePicture}
                candidateUserId={card?.candidateUserId}
                candidateId={card?.candidateId}
                professions={card?.profession}
                businessUserId={card?.businessUserId}
                name={card?.name}
                lastName={card?.lastName}
                country={card?.country}
                video={card?.video}
                videoViews={card?.videoViews}
                likes={card?.likes}
                saves={card?.saves}
                style={{ marginTop: '1rem' }}
              />
            ))
          )}
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Container>
  );
}
