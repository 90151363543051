import { Dialog, Typography } from '@mui/material';
import styled from 'styled-components';

export const CheckIconWrapper = styled.div``;
export const DialogWrapper = styled(Dialog)``;

export const SuccessMessage = styled(Typography)`
  font-size: 20px;
  color: #20be79;
  font-weight: bold;
`;

export const PayInfos = styled.div`
  width: 100%;
`;

export const PayInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  color: #858585;
  font-size: 14px;
`;

export const Value = styled(Typography)``;

export const PriceTitle = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;
