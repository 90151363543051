import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../../lib/theme';
import { Typography } from '@mui/material';

const { businessCardBg, personCardBg, text_blue } = colors;

export const LinkBusiness = styled(Link)`
  position: relative;
  border-radius: 15px;
  padding: 4rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${businessCardBg};
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    padding: 2rem;
  }
`;

export const LinkStudent = styled(Link)`
  position: relative;
  border-radius: 15px;
  padding: 4rem;
  margin-bottom: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${personCardBg};
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    padding: 2rem;
  }
`;

export const Paragraph = styled(Typography)`
  position: absolute;
  bottom: 0;
  color: white;
  padding-bottom: 5px;
  font-weight: bold;
`;

export const SignUpTitleWrapper = styled.div`
  margin-left: 2rem;
`;

export const SignUpTypo = styled(Typography)`
  font-family: 'Impact';
  margin-top: 2rem;
`;

export const SignUpDesc = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  margin-top: 1rem;
`;

export const HaveAnAccount = styled(Typography)`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;

export const ConnectYourself = styled(Link)`
  font-family: 'Montserrat';
  color: ${text_blue};
`;

export const Image = styled('img')`
  width: 180px;
  height: 180px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100px;
    height: 100px;
  }
`;
