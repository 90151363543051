import { useTranslation } from 'react-i18next';
import {
  InfoWrapper,
  InfoProp,
  InfoFromDb,
  InfosWrapper,
} from '../../../student-dashboard/ProfileInfo/BasicInfo/styles';
import { MainWrapper, TitleWrapper, Title } from '../../../styles';
import UnlockFieldModal from '../UnlockFieldModal/UnlockFieldModal';
import LockedData from './LockedData';
import { ButtonsWrapper, CustomButton, UnlockButton } from './styles';
import useCandidateData from './userData';
import { useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';

export default function BasicInformation({ data }: any) {
  const { t } = useTranslation();
  const infoProps = useCandidateData(data);
  const userCV = data?.cv;
  const [openUnlockModal, setOpenUnlockModal] = useState<boolean>(false);

  return (
    <>
      <MainWrapper>
        <TitleWrapper>
          <Title>{t('basic_info.title')}</Title>
          {!data.email && (
            <UnlockButton onClick={() => setOpenUnlockModal(true)}>
              {t('unlock_infos')}
              <LockIcon sx={{ fontSize: '15px' }} />
            </UnlockButton>
          )}
        </TitleWrapper>
        <InfosWrapper>
          {infoProps?.map((info) => (
            <InfoWrapper key={info.id}>
              <InfoProp>{info.title}</InfoProp>
              {data.email ? (
                <InfoFromDb>{info.value ?? 'N/A'}</InfoFromDb>
              ) : (
                <LockedData />
              )}
            </InfoWrapper>
          ))}
        </InfosWrapper>
        <ButtonsWrapper>
          <a
            onClick={() => {
              if (!data.email) {
                setOpenUnlockModal(true);
              }
            }}
            href={data.email && userCV?.filePath}
            download={userCV?.fileTypeName}
          >
            <CustomButton variant="contained" download={true}>
              {t('download_resume')}
            </CustomButton>
          </a>
          <CustomButton
            download={false}
            onClick={(e) => {
              if (data.email) {
                window.location.href = `mailto:${data.email}`;
                e.preventDefault();
              } else {
                setOpenUnlockModal(true);
              }
            }}
          >
            {t('send_email')}
          </CustomButton>
        </ButtonsWrapper>
      </MainWrapper>
      <UnlockFieldModal
        openUnlockModal={openUnlockModal}
        setOpenUnlockModal={setOpenUnlockModal}
        candidateId={data.candidateId}
        candidateUserId={data.userId}
      />
    </>
  );
}
