import { DialogContent, DialogActions, Box, Button } from '@mui/material';
import { CropOriginalRounded, Clear } from '@mui/icons-material';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import {
  FormWrapper,
  Typo,
  GeneralInfo,
  SaveButton,
  LogoWrapper,
  IconClearWrapper,
} from '../../styles';
import { useForm } from 'react-hook-form';
import { AddExperienceModel } from '../../../../../../../interfaces/types/User';
import { InputField } from '../../../../../InputField/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddExperience } from '../../../../../../../rq/hooks/useEditProfile.mutation';
import { AddExperienceSchema } from '../../../../../../../validators/EditProfile.validator';
import { months } from '../../monthYear';
import PreviewImage from '../../../../../Register/UploadImage/PreviewImage/PreviewImage';
import { useFilePreview } from '../../../../../../../hooks/useFilePreview';
import { colors } from '../../../../../../../lib/theme';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../../../../src/components/BootstrapDialog/BootstrapDialog';
import { useTranslation } from 'react-i18next';
import { year } from '../../../../../../../static';
import toast from 'react-hot-toast';
import Select from '../../../../../../../components/Select/Select';
import { ChangeEvent } from 'react';

export default function AddExperience({ open, setOpen, setItem }: any) {
  //hooks
  const { t } = useTranslation();

  // hook form
  const methods = useForm<AddExperienceModel>({
    resolver: yupResolver(AddExperienceSchema(t)),
  });

  // destructures
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: {
      errors: { enterpriseName, position, location, startMonth, startYear },
    },
  } = methods;

  //   react-mutation
  const { mutate: addExperience, isLoading } = useAddExperience();

  //handlers
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: AddExperienceModel) => {
    const formData: any = new FormData();
    formData.append('enterpriseName', data.enterpriseName);
    formData.append('position', data.position);
    formData.append('startYear', data.startYear);
    formData.append('startMonth', data.startMonth);
    formData.append('endYear', data.endYear);
    formData.append('endMonth', data.endMonth);
    if (data.companyLogo) {
      formData.append('companyLogo', data.companyLogo);
    }
    formData.append('location', data.location);
    addExperience(formData, {
      onSuccess: (res) => {
        setOpen(false);
        toast.success(t('successMessage.admin_verification'));
      },
    });
  };

  const file = watch('companyLogo');
  const { image, onSetImage } = useFilePreview(file);
  const { upload_picture_background } = colors;

  // handlers
  const handleFileSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files && files.length !== 0) setValue('companyLogo', files[0]);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {t('editProfile.edit_candidate_profile.experience.title_add')}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <FormWrapper>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.general_info')}
                </Typo>
                <GeneralInfo>
                  <InputField
                    size="small"
                    fullWidth
                    label={t(
                      'editProfile.edit_candidate_profile.experience.name'
                    )}
                    {...register('enterpriseName')}
                    fieldError={enterpriseName}
                  />
                  <InputField
                    size="small"
                    fullWidth
                    label={t(
                      'editProfile.edit_candidate_profile.experience.position'
                    )}
                    {...register('position')}
                    fieldError={position}
                  />
                </GeneralInfo>
              </div>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.start_date')}
                </Typo>
                <GeneralInfo>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mr: 1 }}
                    error={!!startMonth}
                  >
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('startMonth')}
                      fieldError={startMonth}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" error={!!startYear}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('startYear')}
                      fieldError={startYear}
                    />
                  </FormControl>
                </GeneralInfo>
              </div>
              <div>
                <Typo>{t('editProfile.edit_candidate_profile.end_date')}</Typo>
                <GeneralInfo>
                  <FormControl fullWidth size="small" sx={{ mr: 1 }}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('endMonth')}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small">
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('endYear')}
                    />
                  </FormControl>
                </GeneralInfo>
              </div>
              <div style={{ marginBottom: '0.5rem' }}>
                <Typo>
                  {t('editProfile.edit_candidate_profile.experience.location')}
                </Typo>
                <InputField
                  size="small"
                  fullWidth
                  label={t(
                    'editProfile.edit_candidate_profile.experience.location'
                  )}
                  {...register('location')}
                  fieldError={location}
                />
              </div>
              <GeneralInfo>
                <FormControl fullWidth size="small">
                  <Typo>
                    {t('editProfile.edit_candidate_profile.experience.logo')}
                  </Typo>
                  <LogoWrapper>
                    {image ? (
                      <>
                        <PreviewImage imgSrc={image} />
                        <IconClearWrapper
                          onClick={() => {
                            onSetImage(null);
                            setValue('companyLogo', null);
                          }}
                        >
                          <Clear sx={{ padding: '1px' }} />
                        </IconClearWrapper>
                      </>
                    ) : (
                      <Button
                        component="label"
                        sx={{
                          borderRadius: ' 0.25rem',
                          color: 'white',
                          padding: '2.5rem',
                          backgroundColor: `${upload_picture_background}`,
                          boxShadow:
                            '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
                        }}
                      >
                        <input
                          hidden
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={handleFileSelect}
                          // {...register('companyLogo')}
                        />
                        <CropOriginalRounded
                          className="text-white"
                          width="120px"
                          height="150px"
                        />
                      </Button>
                    )}
                  </LogoWrapper>
                </FormControl>
              </GeneralInfo>
            </FormWrapper>
          </DialogContent>
          <DialogActions>
            <SaveButton
              loading={isLoading}
              variant="contained"
              type="submit"
              autoFocus
            >
              {t('editProfile.edit_candidate_profile.save')}
            </SaveButton>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </div>
  );
}
