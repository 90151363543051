import styled from 'styled-components';
import { colors } from '../../../lib/theme';

const { background_jobs_ads, text_gray } = colors;

export const Wrapper = styled.div`
  background-color: ${background_jobs_ads};
  height: 100%;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  align-self: center;
  margin-top: 4rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  font-family: 'Montserrat';
`;

export const ImageWrapper = styled.img``;

export const TitleWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const NameWrapper = styled.div`
  color: white;
`;

export const LocationWrapper = styled.div`
  color: ${text_gray};
`;

export const VideoWrapper = styled.div`
  margin-top: 1rem;
  position: relative;

  margin-bottom: 0.5rem;
  video {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 20px;
  }
`;
