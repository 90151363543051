import { Fragment, useEffect, useState } from 'react';
import { useGetMyLikedVideosQuery } from '../../../../../../rq/hooks/useQueries';
import { FetchDiv, TypoFetch } from './styles';
import CircularIndeterminate from '../../../../../../components/CircularIndeterminate/CircularIndeterminate';
import CardSkeleton from '../../../../../../components/CardSkeleton/CardSkeleton';
import { useInView } from 'react-intersection-observer';
import { useDeleteLikedVideo } from '../../../../../../rq/hooks/useEditProfile.mutation';
import { useQueryClient } from '@tanstack/react-query';
import Card from '../../../../../../components/Card/Card';
import { NoCard } from '../../../../../../components/Card/styles';
import { useTranslation } from 'react-i18next';
import SearchVideos from '../../../../../../components/SearchVideos/SearchVideos';
import useDebounce from '../../../../../../hooks/useDebounce';

export default function MyLikedVideos() {
  const [search, setSearch] = useState<any>('');
  const [results, setResults] = useState<number | undefined>(0);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const {
    refetch,
    status,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetMyLikedVideosQuery(debouncedSearchTerm, {
    getNextPageParam: (lastPage: any, pages: any) => {
      if (lastPage.metaData.pageNumber < lastPage.metaData.pageCount) {
        return lastPage.metaData.pageNumber + 1;
      } else {
        return false;
      }
    },
  });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (debouncedSearchTerm) {
      setResults(data?.pages[0].data.length);
    } else {
      setResults(0);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(
    () => {
      (async () => {
        if (debouncedSearchTerm) {
          setIsSearching(true);
          await refetch();
          setIsSearching(false);
        } else {
          await refetch();
          setIsSearching(false);
        }
      })();
    },
    [debouncedSearchTerm]
    // Only call effect if debounced search term changes
  );

  //   react mutation
  const { t } = useTranslation();
  return (
    <>
      <SearchVideos
        setSearch={setSearch}
        results={results}
        isSearching={isSearching}
        search={search}
      />
      {status === 'loading' ? (
        <CircularIndeterminate />
      ) : (
        <>
          {data?.pages.map((liked, i) => {
            return (
              <>
                <Fragment key={i}>
                  {liked.data.length > 0 ? (
                    <>
                      {liked.data.map((l, index) => (
                        <Card
                          key={index}
                          profilePicture={l.profilePicture}
                          candidateUserId={l.candidateUserId}
                          candidateId={l.candidateId}
                          professions={l.profession}
                          businessId={l.businessId}
                          businessUserId={l.businessUserId}
                          name={l.name}
                          lastName={l.lastName}
                          country={l.country}
                          video={l.video}
                          videoViews={l.videoViews}
                          likes={l.likes}
                          saves={l.saves}
                          isMyFavorite={l.isMyFavorite}
                          likedFromMe={l.likedFromMe}
                          refetch={refetch}
                        />
                      ))}
                    </>
                  ) : (
                    <NoCard>{t('card.no_liked_videos')}</NoCard>
                  )}
                </Fragment>
              </>
            );
          })}
          {data && data?.pages[0].data.length > 0 && (
            <FetchDiv ref={ref} onClick={() => fetchNextPage()}>
              {isFetchingNextPage ? (
                <CardSkeleton />
              ) : hasNextPage ? (
                <TypoFetch>{t('card.search.load_more')}</TypoFetch>
              ) : (
                <TypoFetch>{t('card.search.nothing_more')}</TypoFetch>
              )}
            </FetchDiv>
          )}
        </>
      )}
    </>
  );
}
