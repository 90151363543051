import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../../lib/theme';

const { more_button_color, businessCardBg } = colors;

export const LockButton = styled(Button)`
  border: 1px solid transparent;
  width: fit-content;
  text-transform: none;
  background-color: ${more_button_color};

  padding: 0 1rem 0 1rem;
`;

export const LockedTitle = styled(Typography)`
  font-size: 12px;
  color: black;
  border: 1px solid transparent;
  width: fit-content;
  background-color: ${more_button_color};
  border-radius: 20px;
  padding: 0 1rem 0 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const CustomButton = styled(Button)<{ download: boolean }>`
  display: block;
  width: 100%;
  padding: 0.3rem 0;
  text-align: center;
  font-size: 12px;
  text-transform: none;
  border: 1px solid ${businessCardBg};
  background-color: ${(props) => (props.download ? businessCardBg : 'none')};
  color: ${(props) => (props.download ? 'white' : businessCardBg)};
  &:hover {
    background-color: ${(props) => (props.download ? businessCardBg : 'none')};
  }
`;

export const UnlockButton = styled(Button)`
  background: #fdcd50;
  text-transform: none;
  color: white;
  height: 30px;
  margin-bottom: 0.1rem;
  &:hover {
    background: #fdcd50;
  }
`;
