import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
} from '@mui/material';
import { UseFieldArrayAppend, useForm } from 'react-hook-form';
import { UserInputs } from '../../../../interfaces/types/User';
import { useTranslation } from 'react-i18next';
import { IStudyInformationForm } from '../../../../interfaces/types/EducationForm';
import Select from '../../../../components/Select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import { StudyInformationSchema } from '../../../../validators/StudyInformationForm.validator';

interface StudyInformationDialogProps extends DialogProps {
  appendStudyInformation: UseFieldArrayAppend<
    UserInputs,
    'CandidateSelectedEducations'
  >;
  educations: any;
  studyFields: any;
  onDialogClose: () => void;
}

export default function StudyInformation({
  appendStudyInformation,
  educations,
  studyFields,
  onDialogClose,
  ...rest
}: StudyInformationDialogProps) {
  const { t } = useTranslation();

  const {
    watch,
    reset,
    clearErrors,
    trigger,
    register,
    getValues,
    formState: { errors },
  } = useForm<IStudyInformationForm>({
    mode: 'onChange',
    resolver: yupResolver(StudyInformationSchema(t)),
  });

  // functions
  const clearForm = () => {
    reset();
    clearErrors();
  };

  // handlers

  const handleDialogClose = () => {
    clearForm();
    onDialogClose();
  };
  const handleTriggerForm = async () => {
    const triggered = await trigger(['educationId', 'fieldOfStudyId']);

    if (triggered) {
      appendStudyInformation({
        educationId: getValues('educationId'),
        fieldOfStudyId: getValues('fieldOfStudyId'),
      });
      clearForm();
      onDialogClose();
    }
  };

  return (
    <Dialog
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontFamily: 'Montserrat' }}>
        {t('auth.sign_up.study_informations')}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <Grid container sx={{ mt: 1 }}>
            <FormControl fullWidth size="small" sx={{ mb: '20px' }}>
              <InputLabel id="demo-simple-select-label">
                {t('auth.sign_up.university_or_school')}
              </InputLabel>
              <Select
                label={t('auth.sign_up.university_or_school')}
                value={watch('educationId') ?? ''}
                options={educations}
                error={!!errors.educationId}
                fieldError={errors.educationId}
                {...register('educationId')}
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {t('auth.sign_up.study_orientation')}
              </InputLabel>
              <Select
                label={t('auth.sign_up.study_orientation')}
                value={watch('fieldOfStudyId') ?? ''}
                options={studyFields}
                error={!!errors.fieldOfStudyId}
                fieldError={errors.fieldOfStudyId}
                {...register('fieldOfStudyId')}
              />
            </FormControl>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t('auth.sign_up.cancel')}</Button>
        <Button onClick={handleTriggerForm}>{t('auth.sign_up.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}
