import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import JobsAds from '../../../../components/sharedComponents/JobsAds/JobsAds';
import { VerifyCodeButton } from './styles';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import {
  VerifyEmailModel,
  ResetPasswordCodeValidModel,
} from '../../../../interfaces/types/User';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useIsResetPasswordCodeValid } from '../../../../rq/hooks/useAuth.mutation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InputField } from '../../InputField/InputField';
import { useTranslation } from 'react-i18next';
import { DidntGetCode } from '../../Register/ConfirmEmail/styles';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    resetPasswordCode: yup.string().required('Required'),
  });
  const [error, setError] = useState<any>('');
  const methods = useForm<ResetPasswordCodeValidModel>({
    resolver: yupResolver(validationSchema),
  });
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: {
      errors: { resetPasswordCode },
    },
  } = methods;

  const [searchParams] = useSearchParams();
  const email: any = searchParams.get('email');
  //   // react mutations

  const { mutate: isResetPasswordCodeValid, isLoading } =
    useIsResetPasswordCodeValid();

  const onSubmit = async (data: ResetPasswordCodeValidModel) => {
    isResetPasswordCodeValid(
      { email: email, resetPasswordCode: data.resetPasswordCode },
      {
        onSuccess: (res) => {
          navigate(
            `/new-password?email=${encodeURIComponent(email)}&code=${
              data.resetPasswordCode
            }`
          );
        },
        onError: (res) => setError(res),
      }
    );
  };

  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: { xs: 2, lg: 12 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              component='h1'
              variant='h5'
              align='left'
              fontSize='25px'
              fontFamily='Impact'
            >
              {t('auth.verify_email.title')}
            </Typography>
            <Typography
              variant='caption'
              align='left'
              sx={{ fontFamily: 'Montserrat' }}
            >
              {t('auth.verify_email.description')}&nbsp;
              <b>{email}</b>
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                mt: 1,
                alignItems: 'center',
              }}
            >
              {/* <Box className="flex gap-2 mb-10">
                {getValues('resetPasswordCode')?.map((_, index) => (
                  <TextField
                    key={index}
                    type="number"
                    value={code[index]}
                    inputRef={activeOTPIndex === index ? inputRef : null}
                    onChange={handleInputChange}
                    inputProps={{ onKeyDown: handleKeyDownChange(index) }}
                    InputProps={{
                      sx: { height: 60 },
                    }}
                  />
                ))}
              </Box> */}
              <InputField
                placeholder={t('auth.code')}
                type='input'
                fullWidth
                size='small'
                {...register('resetPasswordCode')}
                fieldError={resetPasswordCode}
              />
              {error && (
                <Typography
                  sx={{ color: 'red' }}
                  variant='caption'
                  display='block'
                >
                  {error}
                </Typography>
              )}
              <VerifyCodeButton
                loading={isLoading}
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3 }}
              >
                Verify
              </VerifyCodeButton>
              <Grid item>
                <DidntGetCode
                  variant='text'
                  // onClick={() => resendEmailConfirmationCode()}
                >
                  {t('auth.confirmation_code.null_code')}
                </DidntGetCode>
                {/* {codeSent && (
                    <Typography sx={{ color: 'blue' }} variant="caption">
                      {codeSent}
                    </Typography>
                  )} */}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
