import { MyExpectedResponseType } from './../mutations/EditProfile.mutation';
import {
  UserInputs,
  UserLoginInputs,
  BusinessInputs,
  ConfirmEmailModel,
  ForgotPasswordModel,
  ConfirmEmailType,
  ResetPasswordCodeValidModel,
  ResetPasswordModel,
} from './../../interfaces/types/User';
import {
  loginMutation,
  registerMutation,
  registerBusinessMutation,
  confirmEmailMutation,
  resendConfirmationCodeMutation,
  forgotPasswordMutation,
  isResetPasswordCodeValid,
  resetPassword,
} from './../mutations/Auth.mutation';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useAuthMutation = () => {
  return useMutation(['register'], (data: UserInputs) =>
    registerMutation(data)
  );
};

export const useLoginMutation = () => {
  return useMutation<
    any,
    AxiosError<MyExpectedResponseType>,
    UserLoginInputs,
    any
  >(['login'], (data: UserLoginInputs) => loginMutation(data));
};

export const useRegisterBusinessMutation = () => {
  return useMutation(['registerBusiness'], (data: BusinessInputs) =>
    registerBusinessMutation(data)
  );
};

export const useConfirmEmail = () => {
  return useMutation(['confirmEmail'], (data: ConfirmEmailModel) =>
    confirmEmailMutation(data)
  );
};

export const useResendConfirmationCode = () => {
  return useMutation(['resendConfirmationCode'], (data: ConfirmEmailType) =>
    resendConfirmationCodeMutation(data)
  );
};

export const useForgotPassword = () => {
  return useMutation(['forgotPassword'], (data: ForgotPasswordModel) =>
    forgotPasswordMutation(data)
  );
};

export const useIsResetPasswordCodeValid = () => {
  return useMutation(
    ['isResetPasswordCodeValid'],
    (data: ResetPasswordCodeValidModel) => isResetPasswordCodeValid(data)
  );
};

export const useResetPassword = () => {
  return useMutation(['isResetPasswordCodeValid'], (data: ResetPasswordModel) =>
    resetPassword(data)
  );
};
