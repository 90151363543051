import MotivationLetter from '../MotivationLetter/MotivationLetter';
import EditProfileDetails from './EditProfileDetails/EditProfileDetails';
import Accomplishments from '../Accomplishments/Accomplishments';
import Educations from '../Educations/Educations';
import Certifications from '../Certifications/Certifications';
import Experiences from '../Experiences/Experiences';
import CV from '../CV/CV';
import VideoPresentation from '../VideoPresentation/VideoPresentation';
import DeactivateMyAccount from '../DeactivateMyAccount/DeactivateMyAccount';
import DigitalBook from '../DigitalBook/DigitalBook';

export default function ProfileDetails() {
  return (
    <>
      <EditProfileDetails />
      <DeactivateMyAccount />
      <CV />
      <MotivationLetter />
      <VideoPresentation />
      <DigitalBook />
      <Experiences />
      <Educations />
      <Accomplishments />
      <Certifications />
    </>
  );
}
