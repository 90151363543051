import { useContext, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import {
  MainWrapper,
  TitleWrapper,
  InfosWrapper,
  InfoWrapper,
  WrapperInfoIcon,
  IconButtonWrapper,
  Title,
  Type,
  Date,
  Desc,
} from '../styles';
import AddCertification from './AddCertification/AddCertification';
import EditCertification from './EditCertification/EditCertification';
import { useTranslation } from 'react-i18next';
import { formatDateWithNumbers } from '../../../../../../utils/formaters/formatDate';

export default function Certifications() {
  const { user } = useContext(AuthContext);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditdModal] = useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t('more_info.certifications')}</Title>
        <IconButton>
          <AddCircleIcon onClick={() => setOpenAddModal(true)} />
        </IconButton>
      </TitleWrapper>
      {user && user.certifications && user.certifications.length > 0 ? (
        user.certifications.map((field, index) => {
          return (
            <InfosWrapper key={index}>
              <WrapperInfoIcon>
                <InfoWrapper>
                  <Type>{field.name}</Type>
                  <Date>
                    {formatDateWithNumbers(
                      field.startMonth,
                      field.startYear,
                      field.endMonth,
                      field.endYear
                    )}
                  </Date>
                </InfoWrapper>
                <InfoWrapper>
                  <Desc>{field.description}</Desc>
                </InfoWrapper>
              </WrapperInfoIcon>
              <IconButtonWrapper size="small">
                <EditIcon
                  onClick={() => {
                    setOpenEditdModal(true);
                    setItem(field);
                  }}
                />
              </IconButtonWrapper>
            </InfosWrapper>
          );
        })
      ) : (
        <Type>
          {t(
            'editProfile.edit_candidate_profile.certification.no_certifications'
          )}
        </Type>
      )}
      <EditCertification
        field={item}
        open={openEditModal}
        setOpen={setOpenEditdModal}
      />
      <AddCertification
        setItem={setItem}
        open={openAddModal}
        setOpen={setOpenAddModal}
      />
    </MainWrapper>
  );
}
