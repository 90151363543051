import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../../lib/theme';

const {
  text_gray,
  like_button_color,
  more_button_color,
  businessCardBg,
  border_bottom_color,
} = colors;

export const FetchDiv = styled.div`
  width: 100%;
  text-align: center;
`;

export const TypoFetch = styled(Typography)``;
