import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';

const translationsEn = en;
const translationsFr = fr;
const translationsNL = nl;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'fr-FR',
    fallbackLng: 'en-GB',
    saveMissing: true,
    resources: {
      en: { translation: translationsEn },
      fr: { translation: translationsFr },
      nl: { translation: translationsNL },
    },
    interpolation: { escapeValue: false },
  });
