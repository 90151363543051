import { MenuItem, Select, FormControl } from '@mui/material';
import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { LanguagesWrapper } from './styles';
import { languages } from '../../../static';
import { useTranslation } from 'react-i18next';
import { readFromStorage } from '../../../utils/storage/storage';
import { useLocation } from 'react-router-dom';
import { useChangeLanguageMutation } from '../../../rq/hooks/useChangeLanguage';
import { AuthContext } from '../../../lib/context/AuthContext';

const useStyles = makeStyles({
  icon: {
    fill: 'white',
    fontSize: '17px',
  },
  iconBlack: {
    fill: '#9D9D9D',
    fontSize: '17px',
  },
});

export default function LanguageChanger({ small, mobile }: any) {
  const { isAuthenticated, languageId, setLanguageId } =
    useContext(AuthContext);
  const classes = useStyles();
  const location = useLocation();
  const { i18n } = useTranslation();
  const homePage = location.pathname === '/home';

  const { mutate: changeUserLanguage } = useChangeLanguageMutation({
    onSuccess: () => {
      if (readFromStorage('i18nextLng') === 'en-GB') {
        i18n.changeLanguage('en-GB');
        setLanguageId(1);
      } else if (readFromStorage('i18nextLng') === 'fr-FR') {
        i18n.changeLanguage('fr-FR');
        setLanguageId(2);
      } else {
        i18n.changeLanguage('nl-NL');
        setLanguageId(3);
      }
      // window.location.reload();
    },
  });
  return (
    <LanguagesWrapper>
      <FormControl>
        <Select
          defaultValue={languageId}
          variant="standard"
          disableUnderline
          inputProps={{
            classes: {
              icon:
                homePage && !small && !mobile
                  ? classes.icon
                  : classes.iconBlack,
            },
          }}
          sx={{
            color: homePage && !small && !mobile ? 'white' : '#9D9D9D',
            fontSize: '12px',
          }}
          MenuProps={{ disableScrollLock: true }}
        >
          {languages?.map((lang: any) => {
            return (
              <MenuItem
                key={lang.id}
                value={lang.id}
                disabled={lang.id === languageId}
                onClick={() => {
                  i18n.changeLanguage(lang.cred);
                  if (isAuthenticated) changeUserLanguage(lang.id);
                }}
              >
                {lang.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </LanguagesWrapper>
  );
}
