import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography, Button } from '@mui/material';
import styled from 'styled-components';

export const ButtonsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoMessage = styled(Typography)`
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 10px;
  }
`;

export const ConfirmButton = styled(LoadingButton)`
  font-size: 12px;
  background: #ed3a60;
  color: white;
  text-transform: none;
  &:hover {
    background: #ed3a60;
  }
  margin-right: 1rem;
`;

export const CancelButton = styled(Button)`
  font-size: 12px;
  color: black;
  text-transform: none;
`;
