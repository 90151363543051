import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreButton, MoreButtonWrapper } from '../styles';
import {
  MoreHorizRounded,
  PhoneEnabled,
  Send,
  StarRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../lib/context/AuthContext';
import UnsaveProfileModal from './UnsaveProfileModal';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    disableScrollLock
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MoreMenu({
  isMyFavorite,
  candidateId,
  businessId,
  handleAddUserToFavorites,
  boughtFromCurrentUser,
  businessUserId,
  email,
  phoneNumber,
  unSaveProfile,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      if (user?.businessId) {
        navigator
          .share({
            url: `${window.location.origin}/candidate-shared/profile/${candidateId}`,
          })
          .then(() => {
            console.log('Sharing successfull');
          })
          .catch(() => {
            console.log('Sharing failed');
          });
      }
      if (user?.candidateId) {
        if (candidateId) {
          navigator
            .share({
              url: `${window.location.origin}/candidate-shared/profile/${candidateId}`,
            })
            .then(() => {
              console.log('Sharing successfull');
            })
            .catch(() => {
              console.log('Sharing failed');
            });
        } else {
          navigator
            .share({
              url: `${window.location.origin}/business-shared/profile/${businessUserId}`,
            })
            .then(() => {
              console.log('Sharing successfull');
            })
            .catch(() => {
              console.log('Sharing failed');
            });
        }
      }
    } else {
      console.log('Sorry! Your browser does not support Web Share API');
    }
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const handleCall = (e: any) => {
    e.preventDefault();
    if (boughtFromCurrentUser) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      navigate(`/candidate/profile/${candidateId}`);
    }
  };
  const [openUnsaveProfile, setOpenUnsaveProfile] =
    React.useState<boolean>(false);

  return (
    <>
      <MoreButtonWrapper>
        <MoreButton onClick={handleClick}>
          <MoreHorizRounded sx={{ color: 'black' }} />
        </MoreButton>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {user?.businessId && (
            <MenuItem onClick={handleCall} disableRipple>
              <PhoneEnabled />
              {t('card.call')}
            </MenuItem>
          )}
          <MenuItem onClick={handleShareButton} disableRipple>
            <Send fontSize="small" />
            {t('card.send')}
          </MenuItem>
          {user?.businessId && (
            <MenuItem onClick={handleAddUserToFavorites} disableRipple>
              <StarRounded />
              {isMyFavorite ? t('card.unsave') : t('card.save')}
            </MenuItem>
          )}
        </StyledMenu>
      </MoreButtonWrapper>
      <UnsaveProfileModal
        open={openUnsaveProfile}
        setOpen={setOpenUnsaveProfile}
        unSaveProfile={unSaveProfile}
      />
    </>
  );
}
