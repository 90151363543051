import { FC, ReactElement } from 'react';
import { Box, TableRow, Typography } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

interface EmptyViewProps {
  colSpan?: number;
  condition: boolean;
  children: ReactElement | ReactElement[];
}

export const EmptyView: FC<EmptyViewProps> = ({
  condition,
  colSpan,
  children,
}): ReactElement => {
  return condition ? (
    <>{children}</>
  ) : colSpan ? (
    <tbody>
      <TableRow>
        <Box component="td" colSpan={colSpan} p={4} textAlign="center">
          <ManageSearchIcon />
          <Typography fontStyle="italic">No data to display!</Typography>
        </Box>
      </TableRow>
    </tbody>
  ) : (
    <Box p={2} textAlign="center">
      <ManageSearchIcon />
      <Typography fontStyle="italic">No data to display!</Typography>
    </Box>
  );
};
