import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Description,
  Link,
  PackagesWrapper,
  Title,
  TitleWrapper,
} from './styles';
import { usePackagePlansQuery } from '../../../../rq/hooks/useQueries';
import CircularIndeterminate from '../../../../components/CircularIndeterminate/CircularIndeterminate';
import CreditPurchasePlan from './CreditPurchasePlan/CreditPurchasePlan';
import { NavLink } from 'react-router-dom';

export default function CreditsPurchase() {
  const { t } = useTranslation();
  const { data, isLoading } = usePackagePlansQuery();

  return (
    <>
      <Container>
        <TitleWrapper>
          <Title>
            <span style={{ color: '#A8E8F5' }}>{t('credits.credits')}</span>
            &nbsp;
            {t('credits.purchase')}
          </Title>
          <Description>{t('credits.overview_packages')}</Description>
        </TitleWrapper>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <PackagesWrapper>
            {data?.map((plan: any) => (
              <CreditPurchasePlan
                key={plan.id}
                plan={plan}
                identifier={plan.id}
              />
            ))}
          </PackagesWrapper>
        )}
        <Link to="/home">Choose later</Link>
      </Container>
    </>
  );
}
