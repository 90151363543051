import { Typography } from '@mui/material';
import styled from 'styled-components';

export const NotificationTitle = styled(Typography)`
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 12px;
  }
`;

export const NotificationDescription = styled(Typography)`
  font-size: 12px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 10px;
  }
`;

export const Date = styled(Typography)`
  font-size: 10px;
`;
