import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ContactUsModel } from '../../../interfaces/ContactUs.model';
import { InputField } from '../../../modals/components/InputField/InputField';
import { useContactUsFormMutation } from '../../../rq/hooks/useContactUs.mutation';
import { ContactUsFormSchema } from '../../../validators/ContactUsForm.validator';
import {
  ContactFormWrapper,
  FormInputTitle,
  FormInputWrapper,
  FormsWrapper,
  SendMessageButton,
} from '../styles';

export default function ContactUsForm() {
  const { t } = useTranslation();
  const methods = useForm<ContactUsModel>({
    resolver: yupResolver(ContactUsFormSchema(t)),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors: { firstName, lastName, email, phoneNumber, message },
    },
  } = methods;

  const { mutate: contactUs, isLoading } = useContactUsFormMutation();
  const onSubmit = (data: ContactUsModel) => {
    contactUs(data, {
      onSuccess: () => {
        reset();
        toast.success(
          'Thank you for your message! We will contact you shortly'
        );
      },
    });
  };
  return (
    <>
      <FormProvider {...methods}>
        <ContactFormWrapper>
          <Box
            sx={{
              my: 3,
              mx: { xs: 2, md: 4 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <FormsWrapper>
                <FormInputWrapper>
                  <FormInputTitle>{t('contact_us.name')}</FormInputTitle>
                  <InputField
                    placeholder={t('contact_us.name')}
                    size="small"
                    fullWidth
                    fieldError={firstName}
                    {...register('firstName')}
                  />
                </FormInputWrapper>
                <FormInputWrapper>
                  <FormInputTitle>{t('contact_us.last_name')}</FormInputTitle>
                  <InputField
                    placeholder={t('contact_us.last_name')}
                    size="small"
                    fullWidth
                    fieldError={lastName}
                    {...register('lastName')}
                  />
                </FormInputWrapper>
              </FormsWrapper>
              <FormInputWrapper>
                <FormInputTitle>{t('contact_us.mail')}</FormInputTitle>
                <InputField
                  placeholder={t('contact_us.mail')}
                  size="small"
                  fullWidth
                  fieldError={email}
                  {...register('email')}
                />
              </FormInputWrapper>
              <FormInputWrapper>
                <FormInputTitle>{t('contact_us.phone_number')}</FormInputTitle>
                <InputField
                  placeholder={t('contact_us.phone_number')}
                  size="small"
                  fullWidth
                  fieldError={phoneNumber}
                  {...register('phoneNumber')}
                />
              </FormInputWrapper>
              <FormInputWrapper>
                <FormInputTitle>{t('contact_us.message')}</FormInputTitle>
                <InputField
                  multiline
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  rows={5}
                  placeholder={t('contact_us.message')}
                  size="small"
                  style={{
                    backgroundColor: '#F9F9FB',
                    borderRadius: '10px',
                    padding: '0 1rem',
                  }}
                  fullWidth
                  fieldError={message}
                  {...register('message')}
                />
              </FormInputWrapper>
              <SendMessageButton
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                {t('contact_us.send_button')}
              </SendMessageButton>
            </Box>
          </Box>
        </ContactFormWrapper>
      </FormProvider>
    </>
  );
}
