import { AppBar } from '@mui/material';
import styled from 'styled-components';

export const AppBarWrapper = styled(AppBar)<{
  homePage: boolean;
  small: boolean;
}>`
  z-index: 30;
  position: ${(props) => (!props.homePage ? 'sticky' : 'absolute sticky')};
  background-color: ${(props) =>
    !props.homePage || props.small ? 'white' : 'transparent'};
  color: ${(props) => (!props.homePage || props.small ? 'black' : 'white')};
  box-shadow: ${(props) =>
    !props.homePage || props.small
      ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
      : 'none'};
`;
