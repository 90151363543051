import { Container, Grid, Box } from '@mui/material';
import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../../lib/context/AuthContext';
import { useMyProfileSideBarQuery } from '../../../../rq/hooks/useQueries';
import LeftNavigationStudentProfile from './LeftNavigationStudentProfile/LeftNavigationStudentProfile';
import RightSideStudent from './RightSideStudent/RightSideStudent';

export default function StudentLeftSideNavigation() {
  //global state
  const { role } = useContext(AuthContext);

  //react query
  const { data, isLoading } = useMyProfileSideBarQuery({
    enabled: role === 'Candidate',
  });

  const { pathname } = useLocation();
  const editProfilePage = pathname === '/Candidate/profile/settings';

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container>
        <Grid container spacing={3}>
          <Grid
            item
            lg={3}
            md={3}
            xs={0}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
              },
              position: 'sticky',
              top: '60px',
              height: '100%',
            }}
          >
            <LeftNavigationStudentProfile data={data} isLoading={isLoading} />
          </Grid>
          <Outlet />
          <Grid
            item
            lg={3}
            xs={0}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
              },
              position: 'sticky',
              top: '60px',
              height: '100%',
            }}
          >
            {!editProfilePage && <RightSideStudent data={data} />}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
