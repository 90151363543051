import { Card, Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  border-radius: 20px;
  background-color: #fdf0e5;
  margin-bottom: 1rem;
`;

export const InfoWrapper = styled(Box)`
  padding: 1rem;
`;

export const Title = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

export const Description = styled(Typography)`
  font-size: 12px;
  color: #9d9d9d;
`;
