import { Box, Grid, Paper, Typography } from '@mui/material';
import JobsAds from '../../../../components/sharedComponents/JobsAds/JobsAds';
import { SendVerificationCodeEmail } from './styles';
import { useForm, FormProvider } from 'react-hook-form';
import { ForgotPasswordModel } from '../../../../interfaces/types/User';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForgotPassword } from '../../../../rq/hooks/useAuth.mutation';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../../InputField/InputField';
import { writeToStorage } from '../../../../utils/storage/storage';
import { useTranslation } from 'react-i18next';
import { ConnectYourself, HaveAnAccount } from '../../Register/styles';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    email: yup.string().email('Invalid email').required('Required'),
  });
  const methods = useForm<ForgotPasswordModel>({
    resolver: yupResolver(validationSchema),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: {
      errors: { email },
    },
  } = methods;

  //   // react mutations

  const { mutate: forgotPassword, isLoading } = useForgotPassword();

  const onSubmit = async (data: ForgotPasswordModel) => {
    forgotPassword(
      { email: data.email, languageId: 'English' },
      {
        onSuccess: (res) => {
          navigate(`/verify-email?email=${encodeURIComponent(data.email)}`);
        },
      }
    );
  };

  const { t } = useTranslation();
  return (
    <FormProvider {...methods}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: { xs: 2, lg: 20 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              component='h1'
              variant='h5'
              align='left'
              fontFamily='Impact'
              fontSize='25px'
            >
              {t('auth.forgot_password.page_title')}
            </Typography>
            <Typography
              variant='caption'
              align='left'
              sx={{ fontFamily: 'Montserrat' }}
              fontSize='14px'
              fontWeight='bold'
            >
              {t('auth.forgot_password.description')}
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                mt: 1,
                alignItems: 'center',
              }}
            >
              <InputField
                placeholder='Email'
                type='input'
                fullWidth
                size='small'
                {...register('email')}
                fieldError={email}
              />
              <SendVerificationCodeEmail
                loading={isLoading}
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3 }}
              >
                {t('auth.send')}
              </SendVerificationCodeEmail>
            </Box>
          </Box>
          <HaveAnAccount>
            {t('auth.sign_up.already_have_account')}&nbsp;
            <ConnectYourself to='/login'>
              {t('auth.login.login_button')}
            </ConnectYourself>
          </HaveAnAccount>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
