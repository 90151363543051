import { Box } from '@mui/material';
import styled from 'styled-components';

export const PrivacyTitle = styled.span<{ color: string }>`
  cursor: pointer;
  color: ${(props) => props.color && props.color};
  font-family: 'Montserrat';
  text-decoration: underline;
`;

export const Wrapper = styled(Box)``;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.p`
  font-weight: bold;
`;
