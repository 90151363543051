import { DialogContent, DialogActions, Box } from '@mui/material';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { FormWrapper, Typo, GeneralInfo, SaveButton } from '../../styles';
import { useForm } from 'react-hook-form';
import { AddCertificationModel } from '../../../../../../../interfaces/types/User';
import { InputField } from '../../../../../InputField/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddCertification } from '../../../../../../../rq/hooks/useEditProfile.mutation';
import { AddCertificationSchema } from '../../../../../../../validators/EditProfile.validator';
import { months } from '../../monthYear';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../../../../src/components/BootstrapDialog/BootstrapDialog';
import { useTranslation } from 'react-i18next';
import { year } from '../../../../../../../static';
import toast from 'react-hot-toast';
import Select from '../../../../../../../components/Select/Select';

export default function AddCertification({ open, setOpen, setItem }: any) {
  const { t } = useTranslation();
  const methods = useForm<AddCertificationModel>({
    resolver: yupResolver(AddCertificationSchema(t)),
  });
  const {
    register,
    handleSubmit,
    formState: {
      errors: { name, organisation, description, startMonth, startYear },
    },
  } = methods;

  //   react-mutation
  const { mutate: addCertification, isLoading } = useAddCertification();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: AddCertificationModel) => {
    addCertification(data, {
      onSuccess: (res) => {
        setOpen(false);
        toast.success(t('successMessage.admin_verification'));
      },
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Certification
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <FormWrapper>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.general_info')}
                </Typo>
                <GeneralInfo>
                  <InputField
                    size="small"
                    fullWidth
                    placeholder="Certification Name"
                    fieldError={name}
                    {...register('name')}
                  />
                  <InputField
                    fullWidth
                    size="small"
                    placeholder="Organisation"
                    {...register('organisation')}
                    fieldError={organisation}
                  />
                </GeneralInfo>
              </div>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.start_date')}
                </Typo>
                <GeneralInfo>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mr: 1 }}
                    error={!!startMonth}
                  >
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('startMonth')}
                      fieldError={startMonth}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" error={!!startYear}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('startYear')}
                      fieldError={startYear}
                    />
                  </FormControl>
                </GeneralInfo>
              </div>
              <div>
                <Typo>{t('editProfile.edit_candidate_profile.end_date')}</Typo>
                <GeneralInfo>
                  <FormControl fullWidth size="small" sx={{ mr: 1 }}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('endMonth')}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small">
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue=""
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('endYear')}
                    />
                  </FormControl>
                </GeneralInfo>
              </div>

              <Typo>{t('editProfile.edit_candidate_profile.description')}</Typo>
              <InputField
                fullWidth
                size="small"
                multiline
                fieldError={description}
                {...register('description')}
              />
            </FormWrapper>
          </DialogContent>
          <DialogActions>
            <SaveButton
              loading={isLoading}
              variant="contained"
              type="submit"
              autoFocus
            >
              {t('editProfile.edit_candidate_profile.save')}
            </SaveButton>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </div>
  );
}
