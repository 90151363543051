import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../lib/context/AuthContext';
import Footer from '../partials/Footer/Footer';
import Header from '../partials/Header/Header';
import CircularIndeterminate from '../components/CircularIndeterminate/CircularIndeterminate';
import { useMyBusinessProfileSideBarQuery } from '../rq/hooks/useQueries';

const MainSharedLayout = () => {
  //hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, role } = useContext(AuthContext);

  //constants
  const homePage = location.pathname === '/home';
  const aboutPage = location.pathname === '/about';

  //react query
  const { data } = useMyBusinessProfileSideBarQuery({
    enabled: role === 'Business',
  });

  //effects
  useEffect(() => {
    if (data?.credits === 0) {
      navigate('/credits-purchase');
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (loading) return <CircularIndeterminate />;
  return (
    <>
      <Header />
      <Outlet />
      {(homePage || aboutPage) && <Footer />}
    </>
  );
};

export default MainSharedLayout;
