import { ReactElement, useContext } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../lib/context/AuthContext';

export const RequireAuthRoute = ({ allowedRole }: any): ReactElement => {
  // hooks
  const location = useLocation();
  const { isAuthenticated, role } = useContext(AuthContext);

  return allowedRole.includes(role) && isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: '/login' }} state={{ from: location }} />
  );
};
