import { Grid } from '@mui/material';
import Notification from '../../components/Notification/Notification';

export default function NotificationPage() {
  return (
    <Grid item lg={6} md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <Notification />
    </Grid>
  );
}
