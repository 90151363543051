import VideoPresentation from '../VideoPresentation/VideoPresentation';
import EditBusinessProfileDetails from './EditBusinessProfileDetails/EditBusinessProfileDetails';

export default function BusinessProfileDetails() {
  return (
    <>
      <EditBusinessProfileDetails />
      <VideoPresentation />
    </>
  );
}
