import { NavLink } from 'react-router-dom';
import { colors } from './../../../../lib/theme';
import { Typography, Box, Button } from '@mui/material';
import styled from 'styled-components';

const { text_blue } = colors;

export const TitleWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled(Typography)`
  font-size: 45px;
`;

export const Description = styled(Typography)`
  font-size: 16px;
  margin-bottom: 2rem;
`;

export const PackagesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 50px;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: flex;
    grid-gap: 10px;
    flex-direction: column;
  }
`;
export const PackageWrapper = styled.div`
  border: 1px solid #efefef;
  display: flex;
  padding: 1rem 1rem;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
`;

export const PackageTitle = styled(Typography)`
  font-family: 'Impact';
  font-size: 30px;
`;

export const PackagePriceWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const PackagePrice = styled(Typography)`
  font-size: 40px;
  font-weight: bold;
`;

export const PackageCredits = styled(Typography)`
  font-size: 20px;
`;

export const ExclusiveVAT = styled(Typography)`
  font-family: 'Georgia';
  color: #999a99;
  font-size: 12px;
  width: 90%;
  text-align: center;
  border-bottom: 1px solid rgba(238, 238, 238, 0.5);
`;

export const ChoosePlanButton = styled(Button)<{ name: string }>`
  text-transform: none;
  margin-top: 1rem;
  background-color: ${(props) => (props.name === 'Pro' ? text_blue : '')};
  color: ${(props) => (props.name === 'Pro' ? 'white' : 'black')};
  width: 100%;
  border: ${(props) => props.name !== 'Pro' && ' 1px solid #9D9D9D'};
`;

export const PackageListWrapper = styled(Box)``;
export const ListWrapper = styled(Box)`
  display: flex;
  margin-top: 1rem;
  align-items: center;
`;
export const ListItem = styled.li`
  font-size: 12px;
  color: #999a99;
`;

export const Link = styled(NavLink)`
  text-align: center;
  margin-top: 2rem;
  font-size: 26px;
  color: #4a4d4b;
  font-family: 'Montserrat';
  text-decoration: underline;
  display: flex;
  justify-content: center;
`;

export const FieldSet = styled.fieldset`
  padding: 1rem 1rem;
  border: 1px solid #487bd9;
  box-shadow: 0px 2px 34px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: -0.9rem;
  flex-direction: column;
`;

export const Legend = styled.legend`
  background: #487bd9;
  border-radius: 3px;
  text-align: center;
  color: white;
  padding: 0.5rem;
  font-family: 'Montserrat';
  font-size: 10px;
  text-transform: uppercase;
`;
