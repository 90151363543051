import { Container } from '@mui/material';
import { AboutUsImage, Description, MainWrapper, Title } from '../styles';
import aboutusImage from '../../../../assets/images/about.png';
import { useTranslation, Trans } from 'react-i18next';

export default function Objective() {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <MainWrapper>
          <Title>
            <Trans
              i18nKey="about.firstPartTitle"
              values={{
                type: `<span>${t('about.type')}</span>`,
              }}
              components={{ span: <span style={{ color: '#72C2CC' }} /> }}
            />
          </Title>
          <Description>{t('about.firstPartDescription')}</Description>
        </MainWrapper>
      </Container>
      <AboutUsImage src={aboutusImage} loading="lazy" />
    </>
  );
}
