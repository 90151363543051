export const colors = {
  button: '#2E98FA',
  my_gray: '#93DCE5',
  businessCardBg: '#72C2CC',
  personCardBg: '#ED6465',
  text_blue: '#487BD9',
  text_gray: '#9D9D9D',
  upload_picture_background: '#C4C4C4',
  background_jobs_ads: '#1A2948',
  position_text_color: '#A7A7A7',
  discard_button_color: '#F4F7F9',
  linkBackgroundColor: '#FEF7F6',
  like_button_color: '#EEF3FC',
  more_button_color: '#F4F4F4',
  border_bottom_color: '#EDEDED',
  plan_border: '#EFEFEF',
};
