import { forwardRef, MouseEvent, useState } from 'react';
import {
  Select as MUISelect,
  FormControl,
  MenuItem,
  Chip,
  SelectChangeEvent,
  Box,
  Typography,
  InputLabel,
} from '@mui/material';
import { MultiSelectProps } from './MultiSelect.props';

const MultiSelect = forwardRef<HTMLSelectElement, MultiSelectProps>(
  ({ options, items, fieldError, disableItem, onSelect, ...rest }, ref) => {
    // local state
    const [selectedItems, setSelectedItems] = useState<number[]>(items);

    // destructures
    const { label } = rest;

    // handlers
    const handleMouseDown = (event: MouseEvent) => event.stopPropagation();
    const handleChange = (e: SelectChangeEvent<unknown>) => {
      const values = e.target.value as number[];
      setSelectedItems(values);
      onSelect(values);
    };
    const handleDeleteItem = (id: number) => () => {
      setSelectedItems(selectedItems.filter((item) => item !== id));
      onSelect(selectedItems.filter((item) => item !== id));
    };

    return (
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <MUISelect
          multiple
          id={`${label}-multi-select`}
          labelId={`${label}-label`}
          value={selectedItems}
          defaultValue={selectedItems}
          onChange={handleChange}
          inputRef={ref}
          renderValue={(selected) => {
            const selectedItems = selected as number[];
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedItems.map((value) => {
                  return (
                    <Chip
                      key={value}
                      label={
                        options?.find(({ id }) => id === value)?.name || ''
                      }
                      onDelete={handleDeleteItem(value)}
                      onMouseDown={handleMouseDown}
                    />
                  );
                })}
              </Box>
            );
          }}
          {...rest}
        >
          {options?.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </MUISelect>
        {fieldError && (
          <Typography sx={{ color: 'red' }} variant="caption" display="block">
            {fieldError?.message}
          </Typography>
        )}
      </FormControl>
    );
  }
);

MultiSelect.displayName = 'MultiSelect';
export default MultiSelect;
