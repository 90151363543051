import { useContext } from 'react';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import { MainWrapper, TitleWrapper } from '../MotivationLetter/styles';
import { useTranslation } from 'react-i18next';
import UploadDigitalBook from './UploadDigitalBook';
import EditDigitalBook from './EditDigitalBook';

export default function DigitalBook() {
  const { user } = useContext(AuthContext);
  const userDigitalBook = user?.files?.find((cv) => cv.fileTypeId === 5);
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <TitleWrapper>{t('digital_book')}</TitleWrapper>
      {userDigitalBook ? (
        <EditDigitalBook userDigitalBook={userDigitalBook} />
      ) : (
        <UploadDigitalBook />
      )}
    </MainWrapper>
  );
}
