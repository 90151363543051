import styled from 'styled-components';
import { Card, Typography, Button, IconButton, Box } from '@mui/material';
import { colors } from '../../../../../lib/theme';
import { LoadingButton } from '@mui/lab';

const {
  businessCardBg,
  position_text_color,
  text_gray,
  discard_button_color,
  text_blue,
  personCardBg,
} = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${businessCardBg};
`;

export const InfosWrapper = styled.div`
  border-bottom: 1px dashed ${businessCardBg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0 0.2rem 0;
`;
export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const WrapperInfoIcon = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
`;

export const IconButtonWrapper = styled(IconButton)`
  align-self: start;
`;

export const Title = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
`;

export const Type = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  margin-right: 0.5rem;
`;
export const Date = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 12px;
  color: ${text_gray};
`;
export const Desc = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 12px;
  color: ${text_gray};
`;

export const FormWrapper = styled.div``;

export const Typo = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 12px;
  padding-bottom: 0.3rem;
  font-weight: bold;
`;
export const GeneralInfo = styled.div`
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const InputDate = styled.input`
  border: 1px solid ${text_gray};
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
`;

export const SaveButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  color: white;
  text-transform: none;
  border-radius: 10px;
  padding: 0.2rem 3rem;
  background-color: #2e98fa;
  width: 100px;
`;

export const DeleteButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  color: white;
  text-transform: none;
  background-color: ${personCardBg};
  &:hover {
    background-color: ${personCardBg};
  }
  width: 100px;
  border-radius: 10px;
  padding: 0.2rem 3rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1rem;
`;

export const NoFileWrapper = styled.div``;

export const NoFileTitle = styled(Typography)`
  font-size: 12px;
  color: ${text_gray};
  font-family: 'Montserrat';
`;

export const UploadIcon = styled.img`
  width: 20px;
  height: 25px;
`;

export const TypesOfFile = styled(Typography)`
  font-size: 10px;
  color: ${text_gray};
`;

export const NoFileUploadButtonWrapper = styled.div`
  border: 1px dashed ${text_gray};
  border-radius: 15px;
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const NoFile = styled.div`
  display: flex;
  justify-content: center;
`;

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled(Typography)`
font-size: 10px;
font-family: 'Montserrat';
font-weight: bold;
margin-left: .5rem;

}
`;

export const DiscardButton = styled(Button)`
  border-radius: 15px;
  font-family: 'Montserrat';
  text-transform: none;
  color: black;
  background-color: ${discard_button_color};
  margin-right: 1rem;
  &:hover {
    background-color: ${discard_button_color};
  }
`;
export const SaveChangesButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
  text-transform: none;
  background-color: ${text_blue};
`;

export const ExperienceLogo = styled.img`
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-right: 0.5rem;
  object-fit: cover;
`;

export const AvatarWrapper = styled.div`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: 50%;
  min-width: 45px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const LogoWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: fit-content;
`;

export const IconClearWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${text_gray};
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  transform: translate(25%, 25%);
  &:hover {
    color: red;
    cursor: pointer;
  }
`;

export const InfoContentWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0 0.5rem 0;
`;

export const PreviewFile = styled(Typography)`
  color: ${text_gray};
  font-size: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const VideoInfoWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
