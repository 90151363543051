import React, { useContext } from 'react';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import { TitleWrapper } from '../MotivationLetter/styles';
import { MainWrapper } from '../styles';
import UploadCV from './UploadCV/UploadCV';

export default function CV() {
  const { user } = useContext(AuthContext);
  const userCV = user?.files?.find((cv) => cv.fileTypeId === 1);
  return (
    <MainWrapper>
      <TitleWrapper>CV</TitleWrapper>
      <UploadCV cv={userCV} />
    </MainWrapper>
  );
}
