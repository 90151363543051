import { AuthContext } from './lib/context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
export const languages: any = [
  {
    id: 1,
    name: 'EN',
    cred: 'en-GB',
  },
  { id: 2, name: 'FR', cred: 'fr-FR' },
  { id: 3, name: 'NL', cred: 'nl-NL' },
];

export const useNavMenu = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const navMenu = [
    {
      id: 1,
      title: `${t('home.title')}`,
      to: '/home',
    },
    {
      id: 2,
      title: `${t('about.title')}`,
      to: '/about',
    },
    {
      id: 3,
      title: `${t('contact_us.title')}`,
      to: '/contact-us',
    },
  ];
  const newNavMenu = [...navMenu];
  if (!isAuthenticated) {
    newNavMenu.push(
      {
        id: 4,
        title: `${t('login')}`,
        to: '/login',
      },
      {
        id: 5,
        title: `${t('sign_up')}`,
        to: '/register',
      }
    );
  }
  return [navMenu, newNavMenu];
};

export const year = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 1970; i <= currentYear; i++) {
    years.push({ id: i, name: `${i}` });
  }
  return years;
};
