import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import { Typography, TextField, Button, Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../lib/theme';
import { InputField } from '../InputField/InputField';

const { text_blue } = colors;

export const SignInTitleWrapper = styled(Typography)`
  align-text: start;
`;

export const SignUpTypo = styled(Typography)`
  margin-top: 2rem;
  font-family: 'Montserrat';
  font-weight: bold;
`;

export const LinkPassword = styled(Link)`
  font-family: Montserrat;
  color: ${text_blue};
  text-transform: none;
  &:hover {
    background-color: transparent;
  }
  font-size: 13px;
  float: right;
  margin-top: 0.5rem;
`;

export const ButtonConfirmEmail = styled(Button)`
  text-transform: none;
  &:hover {
    background-color: transparent;
  }
  font-size: 13px;
  float: left;
  color: red;
`;

export const TextFieldLogin = styled(InputField)`
  margin-top: 0.5rem;
`;

export const LoginButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  font-weight: bold;
  width: 100%;
  color: white;
  text-transform: none;
  background-color: ${text_blue};
`;

export const HaveAnAccount = styled(Typography)`
  font-family: 'Montserrat';
  bottom: 0;
`;

export const SignUpLink = styled(Link)`
  font-family: 'Montserrat';
  color: ${text_blue};
`;

export const CustomBox = styled(Box)`
  margin: 16px 64px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 16px 16px;
  }
`;
