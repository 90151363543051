import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import { TitleWrapper } from '../MotivationLetter/styles';
import { MainWrapper } from '../styles';
import UploadVideoPresentation from './UploadVideoPresentation/UploadVideoPresentation';

export default function VideoPresentation() {
  //hooks
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  //constants
  const userVideo = user?.files?.find((cv) => cv.fileTypeId === 2);
  return (
    <MainWrapper>
      <TitleWrapper>{t('editProfile.video_presentation')}</TitleWrapper>
      <UploadVideoPresentation video={userVideo} />
    </MainWrapper>
  );
}
