import * as React from 'react';
import { DialogContent, DialogActions, Box } from '@mui/material';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import {
  FormWrapper,
  Typo,
  GeneralInfo,
  DeleteButton,
  SaveButton,
} from '../../styles';
import { useForm } from 'react-hook-form';
import { EditAccomplishmentModel } from '../../../../../../../interfaces/types/User';
import { InputField } from '../../../../../InputField/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useDeleteAccomplishment,
  useEditAccomplishment,
} from '../../../../../../../rq/hooks/useEditProfile.mutation';
import { AddAccomplishmentSchema } from '../../../../../../../validators/EditProfile.validator';
import { months } from '../../monthYear';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../../../../src/components/BootstrapDialog/BootstrapDialog';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import { year } from '../../../../../../../static';
import { useGetAllAccomplishmentsQuery } from '../../../../../../../rq/hooks/useQueries';
import Select from '../../../../../../../components/Select/Select';

export default function EditAccomplishment({ field, open, setOpen }: any) {
  const { t } = useTranslation();
  const methods = useForm<EditAccomplishmentModel>({
    resolver: yupResolver(AddAccomplishmentSchema(t)),
  });
  const queryClient = useQueryClient();
  const { data: accomplishments = [] } = useGetAllAccomplishmentsQuery();

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors: { accomplishmentTypeId, description, startMonth, startYear },
    },
  } = methods;

  React.useEffect(() => {
    if (field) {
      reset({ ...field });
    }
  }, [field, reset]);
  //   react-mutation
  const { mutate: editAccomplishment, isLoading: editLoading } =
    useEditAccomplishment(field?.candidateAccomplishmentId);
  const { mutate: deleteAccomplishment, isLoading: deleteLoading } =
    useDeleteAccomplishment({
      onSuccess: () => {
        setOpen(false);
        toast.success('Deletion was successfull');
        queryClient.fetchQuery(['profile']);
      },
    });

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (data: EditAccomplishmentModel) => {
    editAccomplishment(data, {
      onSuccess: (res) => {
        setOpen(false);
        toast.success(t('successMessage.admin_verification'));
      },
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {t('editProfile.edit_candidate_profile.accomplishment.title_edit')}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <FormWrapper>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.general_info')}
                </Typo>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ mr: 1 }}
                  error={!!accomplishmentTypeId}
                >
                  <InputLabel id="test-select-label">
                    {t(
                      'editProfile.edit_candidate_profile.accomplishment.name'
                    )}
                  </InputLabel>
                  <Select
                    defaultValue={field?.accomplishmentId ?? ''}
                    options={accomplishments}
                    variant="outlined"
                    input={
                      <OutlinedInput
                        label={t(
                          'editProfile.edit_candidate_profile.accomplishment.name'
                        )}
                      />
                    }
                    {...register('accomplishmentTypeId')}
                    fieldError={accomplishmentTypeId}
                  />
                </FormControl>
              </div>
              <div>
                <Typo>
                  {t('editProfile.edit_candidate_profile.start_date')}
                </Typo>
                <GeneralInfo>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mr: 1 }}
                    error={!!startMonth}
                  >
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.startMonth ?? ''}
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('startMonth')}
                      fieldError={startMonth}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" error={!!startYear}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.startYear ?? ''}
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('startYear')}
                      fieldError={startYear}
                    />
                  </FormControl>
                </GeneralInfo>
              </div>
              <div>
                <Typo>{t('editProfile.edit_candidate_profile.end_date')}</Typo>
                <GeneralInfo>
                  <FormControl fullWidth size="small" sx={{ mr: 1 }}>
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.month')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.endMonth ?? ''}
                      options={months}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.month')}
                        />
                      }
                      {...register('endMonth')}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small">
                    <InputLabel id="test-select-label">
                      {t('editProfile.edit_candidate_profile.year')}
                    </InputLabel>
                    <Select
                      defaultValue={field?.endYear ?? ''}
                      options={year()}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          label={t('editProfile.edit_candidate_profile.year')}
                        />
                      }
                      {...register('endYear')}
                    />
                  </FormControl>
                </GeneralInfo>
              </div>
              <Typo>{t('editProfile.edit_candidate_profile.description')}</Typo>
              <InputField
                fullWidth
                size="small"
                fieldError={description}
                defaultValue={field?.description}
                {...register('description')}
              />
            </FormWrapper>
          </DialogContent>
          <DialogActions>
            <DeleteButton
              loading={deleteLoading}
              onClick={() => {
                deleteAccomplishment(field?.candidateAccomplishmentId);
              }}
              variant="contained"
              autoFocus
            >
              {t('editProfile.edit_candidate_profile.delete')}
            </DeleteButton>
            <SaveButton loading={editLoading} variant="contained" type="submit">
              {t('editProfile.edit_candidate_profile.save')}
            </SaveButton>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </div>
  );
}
