import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../../../../../components/PageTemplate/PageTemplate';

import ProfileDetails from './ProfileDetails/ProfileDetails';

export default function EditStudentProfile() {
  const { t } = useTranslation();
  return (
    <Grid item md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate
        title={t('editProfile.edit_button')}
        content={<ProfileDetails />}
      />
    </Grid>
  );
}
