import { Card, Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../lib/theme';

const { text_blue, businessCardBg, position_text_color } = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
`;

export const TitleWrapper = styled(Typography)`
  color: ${position_text_color};
  font-size: 14px;
  font-weight: bold;
`;

export const TargetedVideosWrapper = styled.div`
  margin-top: 1rem;
`;
export const InfosWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const IconWrapper = styled.div``;
export const InfoWrapper = styled.div`
  // display: flex;
  // flex-direction: column;
`;
export const Name = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
export const Location = styled(Typography)`
  font-size: 12px;
`;

export const PositionWrapper = styled.div``;

export const VideoWrapper = styled(Box)`
  border-radius: 10px;
  position: relative;
  // width: 100%;
  // height: 100px;
  video {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 20px;
  }
`;

export const TargetedVideoWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

export const Icon = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  // margin-right: 0.5rem;
`;

export const NoIconWrapper = styled.div`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const PlayerIconWrapper = styled(Box)`
  position: absolute;
  z-index: 10;
  color: white;
  display: inline-block;
  padding: 5px;
  top: 30%;
  left: 40%;
  background: #000000;
  opacity: 0.7;
  border-radius: 50%;
  cursor: pointer;
`;
