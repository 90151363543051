import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import JobsAds from '../../../../components/sharedComponents/JobsAds/JobsAds';
import { useForm, FormProvider } from 'react-hook-form';
import { ResetPasswordModel } from '../../../../interfaces/types/User';
import { yupResolver } from '@hookform/resolvers/yup';
import { useResetPassword } from '../../../../rq/hooks/useAuth.mutation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InputField } from '../../InputField/InputField';
import { VerifyNewPassword } from './styles';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { NewPasswordSchema } from '../../../../validators/NewPassword.validator';

export default function CreateNewPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<ResetPasswordModel>({
    resolver: yupResolver(NewPasswordSchema(t)),
  });

  // Show Password
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const {
    register,
    handleSubmit,
    formState: {
      errors: { newPassword, confirmNewPassword },
    },
  } = methods;

  //   // react mutations

  const { mutate: resetPassword, isLoading } = useResetPassword();

  const [searchParams] = useSearchParams();
  const email: any = searchParams.get('email');
  const code: any = searchParams.get('code');

  const onSubmit = async (data: ResetPasswordModel) => {
    resetPassword(
      {
        ...data,
        email,
        resetPasswordCode: code,
        newPassword: data.newPassword,
        confirmNewPassword: data.confirmNewPassword,
        languageId: 'English',
      },
      {
        onSuccess: (res) => {
          navigate('/login');
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: { xs: 2, lg: 20 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              component='h1'
              variant='h5'
              align='left'
              fontFamily='Impact'
            >
              {t('auth.create_new_password.title')}
            </Typography>
            <Typography
              variant='caption'
              align='left'
              fontSize='14px'
              fontWeight='bold'
            >
              {t('auth.create_new_password.description')}
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                mt: 1,
                alignItems: 'center',
              }}
            >
              <>
                <InputField
                  placeholder={t('auth.create_new_password.new_password')}
                  type={showNewPassword ? 'text' : 'password'}
                  fullWidth
                  size='small'
                  {...register('newPassword')}
                  fieldError={newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <InputField
                  placeholder={t('auth.create_new_password.confirm_password')}
                  type={showConfirmPassword ? 'text' : 'password'}
                  fullWidth
                  size='small'
                  {...register('confirmNewPassword')}
                  fieldError={confirmNewPassword}
                  sx={{ mt: '0.5rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <VerifyNewPassword
                  loading={isLoading}
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3 }}
                >
                  {t('auth.verify')}
                </VerifyNewPassword>
              </>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
