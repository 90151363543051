import { useNavigate } from 'react-router-dom';
import {
  MainWrapper,
  TitleWrapper,
  Title,
  InfosWrapper,
  InfoWrapper,
  InfoProp,
  InfoFromDb,
  InfoWithIcon,
  EditProfileButton,
} from './styles';
import useUserData from '../../EditStudentProfile/ProfileDetails/userData';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import CircularIndeterminate from '../../../../../../components/CircularIndeterminate/CircularIndeterminate';

export default function BasicInfo() {
  const { role, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [infoProps, businessUserProps] = useUserData();
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t('basic_info.title')}</Title>
        <EditProfileButton
          onClick={() => navigate(`/${role}/profile/settings`)}
        >
          {t('editProfile.edit_button')}
        </EditProfileButton>
      </TitleWrapper>
      {!user ? (
        <CircularIndeterminate />
      ) : (
        user && (
          <InfosWrapper>
            {user?.candidateId
              ? infoProps.map((infoProp: any) => (
                  <InfoWrapper key={infoProp.id}>
                    <InfoProp>{infoProp.title}</InfoProp>
                    {infoProp.icon && infoProp.value ? (
                      <InfoWithIcon>
                        <img
                          src={infoProp.icon}
                          style={{ marginRight: '.5rem' }}
                        />
                        <InfoFromDb>{infoProp.value}</InfoFromDb>
                      </InfoWithIcon>
                    ) : (
                      <InfoFromDb>
                        {infoProp.value ? infoProp.value : 'N/A'}
                      </InfoFromDb>
                    )}
                  </InfoWrapper>
                ))
              : businessUserProps.map((infoProp: any) => (
                  <InfoWrapper key={infoProp.id}>
                    <InfoProp>{infoProp.title}</InfoProp>
                    {infoProp.icon && infoProp.value ? (
                      <InfoWithIcon>
                        <img
                          src={infoProp.icon}
                          style={{ marginRight: '.5rem' }}
                        />
                        <InfoFromDb>{infoProp.value}</InfoFromDb>
                      </InfoWithIcon>
                    ) : (
                      <InfoFromDb>
                        {infoProp.value ? infoProp.value : 'N/A'}
                      </InfoFromDb>
                    )}
                  </InfoWrapper>
                ))}
          </InfosWrapper>
        )
      )}
    </MainWrapper>
  );
}
