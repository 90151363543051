import { Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Description, MainWrapper, Title } from '../styles';

export default function Mission() {
  const { t } = useTranslation();
  return (
    <Container sx={{ mt: 2 }}>
      <MainWrapper>
        <Title>
          <span style={{ color: '#72C2CC' }}>
            {t('about.mission.firstPartTitle')}
          </span>
          &nbsp;
          {t('about.mission.secondPartTitle')}
        </Title>
        <Description>{t('about.mission.description')}</Description>
      </MainWrapper>
    </Container>
  );
}
