import React, { useContext } from 'react';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import { MainWrapper, TitleWrapper } from './styles';
import EditMotivationLetter from './EditMotivationLetter/EditMotivationLetter';
import UploadMotivationLetter from './UploadMotivationLetter/UploadMotivationLetter';
import { useTranslation } from 'react-i18next';

export default function MotivationLetter() {
  const { user } = useContext(AuthContext);
  const userMotivationLetter = user?.files?.find((cv) => cv.fileTypeId === 4);
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <TitleWrapper>{t('more_info.motivation_letter')}</TitleWrapper>
      {userMotivationLetter ? (
        <EditMotivationLetter userMotivationLetter={userMotivationLetter} />
      ) : (
        <UploadMotivationLetter />
      )}
    </MainWrapper>
  );
}
