import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HaveAnAccount,
  LinkPassword,
  LoginButton,
  SignUpLink,
  TextFieldLogin,
} from './styles';
import JobsAds from '../../../components/sharedComponents/JobsAds/JobsAds';
import { UserLoginInputs } from '../../../interfaces/types/User';
import {
  useLoginMutation,
  useResendConfirmationCode,
} from '../../../rq/hooks/useAuth.mutation';
import { AuthContext } from '../../../lib/context/AuthContext';
import { LoginFormSchema } from '../../../validators/Login.validator';
import {
  Paper,
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const { t } = useTranslation();
  const methods = useForm<UserLoginInputs>({
    resolver: yupResolver(LoginFormSchema(t)),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: {
      errors: { email, password },
    },
  } = methods;
  // Show Password
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  // react mutations
  const { mutate: loginMutation, isLoading } = useLoginMutation();
  const { mutate: resendCode } = useResendConfirmationCode();
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = (data: UserLoginInputs) => {
    loginMutation(data, {
      onSuccess: (token) => login(token),
      onError: ({ response }) => {
        if (response?.data?.errors?.[0]?.code === 'unconfirmed_emailAddress') {
          resendEmailConfirmationCode();
        }
      },
    });
  };

  const emailFromLoginToConfirmationCode = getValues('email');

  const resendEmailConfirmationCode = () => {
    resendCode(
      { email: emailFromLoginToConfirmationCode },
      {
        onSuccess: (res) => {
          navigate(
            `/confirm-email?email=${encodeURIComponent(
              emailFromLoginToConfirmationCode
            )}`
          );
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: { xs: 2, lg: 20 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              component='h1'
              variant='h5'
              align='left'
              fontFamily='Impact'
            >
              {t('auth.login.title')} My First Job
            </Typography>
            <Typography
              variant='caption'
              align='left'
              sx={{ fontFamily: 'Montserrat' }}
            >
              {t('auth.login.description')}
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                mt: 1,
                alignItems: 'center',
              }}
            >
              <TextFieldLogin
                placeholder={t('auth.email')}
                type='input'
                fullWidth
                size='small'
                {...register('email')}
                fieldError={email}
              />
              <TextFieldLogin
                fullWidth
                placeholder={t('auth.password')}
                type={showPassword ? 'text' : 'password'}
                size='small'
                {...register('password')}
                fieldError={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LinkPassword to='/forgot-password'>
                {t('auth.forgot_password.title')}
              </LinkPassword>
              <LoginButton
                loading={isLoading}
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                {t('auth.login.login_button')}
              </LoginButton>
              <Grid container alignItems='center' justifyContent='center'>
                <Grid item>
                  <HaveAnAccount>
                    {t('auth.login.dont_have_account')}&nbsp;
                    <SignUpLink to='/register'>
                      {t('auth.login.sign_up_button')}
                    </SignUpLink>
                  </HaveAnAccount>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
