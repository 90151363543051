import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Card from '../../../../../components/Card/Card';
import {
  FetchDiv,
  NoCard,
  TypoFetch,
} from '../../../../../components/Card/styles';
import CardSkeleton from '../../../../../components/CardSkeleton/CardSkeleton';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import SearchVideos from '../../../../../components/SearchVideos/SearchVideos';
import useDebounce from '../../../../../hooks/useDebounce';
import { useGetMyFavoriteUsersQuery } from '../../../../../rq/hooks/useQueries';

export default function SavedProfiles() {
  const [search, setSearch] = useState<any>('');
  const [results, setResults] = useState<number | undefined>(0);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const {
    refetch,
    status,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetMyFavoriteUsersQuery(debouncedSearchTerm, {
    getNextPageParam: (lastPage: any, pages: any) => {
      if (lastPage.metaData.pageNumber < lastPage.metaData.pageCount) {
        return lastPage.metaData.pageNumber + 1;
      } else {
        return false;
      }
    },
  });
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);
  useEffect(
    () => {
      (async () => {
        if (debouncedSearchTerm) {
          setIsSearching(true);
          await refetch();
          setIsSearching(false);
        } else {
          await refetch();
          setIsSearching(false);
        }
      })();
    },
    [debouncedSearchTerm]
    // Only call effect if debounced search term changes
  );
  const { t } = useTranslation();
  return (
    <div>
      <SearchVideos
        setSearch={setSearch}
        results={results}
        isSearching={isSearching}
        search={search}
      />
      {status === 'loading' ? (
        <CircularIndeterminate />
      ) : (
        data?.pages.map((liked, i) => {
          return (
            <>
              <Fragment key={i}>
                {liked.data.length > 0 ? (
                  <>
                    {liked.data.map((l, index) => (
                      <Card
                        key={index}
                        profilePicture={l.profilePicture}
                        candidateUserId={l.candidateUserId}
                        candidateId={l.candidateId}
                        professions={l.profession}
                        businessId={l.businessId}
                        businessUserId={l.businessUserId}
                        name={l.name}
                        lastName={l.lastName}
                        country={l.country}
                        video={l.video}
                        videoViews={l.videoViews}
                        likes={l.likes}
                        saves={l.saves}
                        isMyFavorite={l.isMyFavorite}
                        likedFromMe={l.likedFromMe}
                        boughtFromCurrentUser={l.boughtFromCurrentUser}
                        refetch={refetch}
                      />
                    ))}
                  </>
                ) : (
                  <NoCard>{t('card.no_profiles_saved')}</NoCard>
                )}
              </Fragment>
            </>
          );
        })
      )}
      {data && data?.pages[0].data.length > 0 && (
        <FetchDiv ref={ref} onClick={() => fetchNextPage()}>
          {isFetchingNextPage ? (
            <CardSkeleton />
          ) : hasNextPage ? (
            <TypoFetch>{t('card.search.load_more')}</TypoFetch>
          ) : (
            <TypoFetch>{t('card.search.nothing_more')}</TypoFetch>
          )}
        </FetchDiv>
      )}
    </div>
  );
}
