import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CircularIndeterminate from '../../../../../../components/CircularIndeterminate/CircularIndeterminate';
import { PackagePlan } from '../../../../../../interfaces/types/User';
import { usePackagePlansQuery } from '../../../../../../rq/hooks/useQueries';
import { MainWrapper, Title, TitleWrapper } from '../../../styles';
import PlanCard from './PlanCard/PlanCard';
import { PlansWrapper } from './PlanCard/styles';

export default function Plans() {
  const { t } = useTranslation();
  const { data: plans, isLoading } = usePackagePlansQuery();

  return (
    <MainWrapper>
      <Title>{t('plans.title')}</Title>
      <PlansWrapper>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          plans?.map((plan: any) => <PlanCard key={plan.id} plan={plan} />)
        )}
      </PlansWrapper>
    </MainWrapper>
  );
}
