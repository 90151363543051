import { readFromStorage } from './storage/storage';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { DEV_URL } from '../config/app';

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.baseURL = `${DEV_URL}/${readFromStorage('i18nextLng')}`;
  if (config?.headers != undefined) {
    const jwtToken = readFromStorage('auth-token');
    if (jwtToken) config.headers.Authorization = `Bearer ${jwtToken}`;
  }

  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    const errorStatus = error.response?.status;
    if (errorStatus === 401) window.location.href = '/login';
    if (error) return Promise.reject(error);
  }
);

export { default } from 'axios';
