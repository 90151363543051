import { Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  SharedVideoDescription,
  SharedVideoTitle,
  SharedVideoWrapper,
} from '../../modals/components/layouts/business-dashboard/BusinessViewStudent/SharedCandidateCard/styles';
import { useGetAllBusinessCardsQuery } from '../../rq/hooks/useQueries';
import Card from '../Card/Card';
import CircularIndeterminate from '../CircularIndeterminate/CircularIndeterminate';

export default function SharedBusinessCard() {
  const { id } = useParams();
  const { data, isLoading } = useGetAllBusinessCardsQuery(1, null, id);
  const { t } = useTranslation();

  return (
    <Container sx={{ position: 'relative', top: '20px' }}>
      <Grid container component="main">
        <Grid item xs={3}></Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '1rem' }}>
          <SharedVideoWrapper>
            <SharedVideoTitle>{t('shared_video.title')}</SharedVideoTitle>
            <SharedVideoDescription>
              {t('shared_video.description')}
            </SharedVideoDescription>
          </SharedVideoWrapper>
          {isLoading ? (
            <CircularIndeterminate />
          ) : (
            data.data.map((card: any) => (
              <Card
                profilePicture={card?.profilePicture}
                candidateUserId={card?.candidateUserId}
                candidateId={card?.candidateId}
                professions={card?.profession}
                businessUserId={card?.businessUserId}
                name={card?.name}
                lastName={card?.lastName}
                country={card?.country}
                video={card?.video}
                videoViews={card?.videoViews}
                likes={card?.likes}
                saves={card?.saves}
                style={{ marginTop: '1rem' }}
              />
            ))
          )}
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Container>
  );
}
