import { Typography } from '@mui/material';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const CookiesConsent = () => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={t('cookies.accept_button')}
        cookieName="cookie"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        <Typography>{t('cookies.title')}</Typography>
      </CookieConsent>
    </div>
  );
};

export default CookiesConsent;
