import { DialogContent } from '@mui/material';
import { useGetAllBusinessCardsQuery } from '../../../../../rq/hooks/useQueries';
import {
  CompanyInfoWrapper,
  Country,
  Email,
  Icon,
  IconWrapper,
  Info,
  InfoProp,
  InfoWrapper,
  Name,
  PersonalInfoWrapper,
  Video,
  VideoWrapper,
  PlayerIconWrapper,
} from './styles';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../components/BootstrapDialog/BootstrapDialog';
import { useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReactPlayer from 'react-player';

interface Props {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function StudentViewBusiness({ id, open, setOpen }: Props) {
  const { data, isLoading } = useGetAllBusinessCardsQuery(1, null, id, {
    enabled: open,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const [showControls, setShowControls] = useState<boolean>(false);
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '20px',
        },
      }}
    >
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <InfoWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconWrapper>
                  <Icon src={data?.data[0]?.profilePicture} />
                </IconWrapper>
                <PersonalInfoWrapper>
                  <Name>{data?.data[0]?.name ?? 'N/A'}</Name>
                  <Country>{data?.data[0]?.country ?? 'N/A'}</Country>
                  <Email>{data?.data[0]?.email ?? 'N/A'}</Email>
                </PersonalInfoWrapper>
              </div>
              <CompanyInfoWrapper>
                <InfoProp>
                  VAT:{' '}
                  <span style={{ fontSize: '12px' }}>
                    {data?.data[0]?.vat ?? 'N/A'}
                  </span>
                </InfoProp>
                <InfoProp>
                  Phone number:{' '}
                  <span
                    style={{
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      (window.location.href = `tel:${data?.data[0]?.phoneNumber}`)
                    }
                  >
                    {data?.data[0]?.phoneNumber ?? 'N/A'}
                  </span>
                </InfoProp>
                <InfoProp>
                  Website:{' '}
                  <a
                    href={data?.data[0]?.website}
                    target="_blank"
                    style={{ fontSize: '12px' }}
                  >
                    {data?.data[0]?.website ?? 'N/A'}
                  </a>
                </InfoProp>
              </CompanyInfoWrapper>
            </InfoWrapper>
          </BootstrapDialogTitle>
          <DialogContent>
            <VideoWrapper>
              {!showControls && (
                <PlayerIconWrapper onClick={() => setShowControls(true)}>
                  <PlayArrowIcon fontSize="large" sx={{ color: 'white' }} />
                </PlayerIconWrapper>
              )}
              <ReactPlayer
                url={data?.data[0]?.video}
                controls={showControls}
                width="100%"
                height="200px"
                playing={showControls}
                onEnded={() => setShowControls(false)}
              />
            </VideoWrapper>
          </DialogContent>
        </>
      )}
    </BootstrapDialog>
  );
}
