import { useContext, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AuthContext } from '../../../../../../lib/context/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import {
  MainWrapper,
  TitleWrapper,
  InfosWrapper,
  InfoWrapper,
  WrapperInfoIcon,
  IconButtonWrapper,
  Title,
  Type,
  Date,
  Desc,
  ExperienceLogo,
  AvatarWrapper,
} from '../styles';
import AddExperience from './AddExperience/AddExperience';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import EditExperience from './EditExperience/EditExperience';
import { useTranslation } from 'react-i18next';
import { formatDateWithNumbers } from '../../../../../../utils/formaters/formatDate';

export default function Experiences() {
  const { user } = useContext(AuthContext);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditdModal] = useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const { t } = useTranslation();

  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t('more_info.experiences')}</Title>
        <IconButton>
          <AddCircleIcon onClick={() => setOpenAddModal(true)} />
        </IconButton>
      </TitleWrapper>
      {user && user.workExperience && user.workExperience.length > 0 ? (
        user.workExperience.map((field, index) => {
          return (
            <InfosWrapper key={index}>
              {field.companyLogo ? (
                <ExperienceLogo src={field?.companyLogo} />
              ) : (
                <AvatarWrapper>
                  <BusinessCenterRoundedIcon sx={{ color: 'white' }} />
                </AvatarWrapper>
              )}
              <WrapperInfoIcon>
                <InfoWrapper>
                  <Type>{field.enterpriseName}</Type>

                  <Date>
                    {formatDateWithNumbers(
                      field.startMonth,
                      field.startYear,
                      field.endMonth,
                      field.endYear
                    )}
                  </Date>
                </InfoWrapper>
                <InfoWrapper>
                  <Desc>{field.position}</Desc>
                </InfoWrapper>
              </WrapperInfoIcon>
              <IconButtonWrapper size="small">
                <EditIcon
                  onClick={() => {
                    setOpenEditdModal(true);
                    setItem(field);
                  }}
                />
              </IconButtonWrapper>
            </InfosWrapper>
          );
        })
      ) : (
        <Type>
          {t('editProfile.edit_candidate_profile.experience.no_experiences')}
        </Type>
      )}
      <EditExperience
        field={item}
        open={openEditModal}
        setOpen={setOpenEditdModal}
      />
      <AddExperience
        setItem={setItem}
        open={openAddModal}
        setOpen={setOpenAddModal}
      />
    </MainWrapper>
  );
}
