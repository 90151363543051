import * as yup from 'yup';

export const ContactUsFormSchema = (t: any) =>
  yup.object({
    email: yup
      .string()
      .email(t('formErrors.email'))
      .required(t('formErrors.required')),
    firstName: yup.string().required(t('formErrors.required')),
    lastName: yup.string().required(t('formErrors.required')),
    phoneNumber: yup.string().required(t('formErrors.required')),
    message: yup.string().required(t('formErrors.required')),
  });
