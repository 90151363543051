import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../lib/context/AuthContext';
import { MainWrapper } from '../LeftSideBar/styles';
import { Description, Info, Title } from './styles';

export default function Notification() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const notificationViews: any = {
    Reached100Views: {
      title: `${t('notifications.notification.100views.title')}`,
      subTitle: `${t('notifications.notification.100views.subtitle')}`,
      description: `${t('notifications.notification.100views.description')}`,
    },
    Reached50Likes: {
      title: `${t('notifications.notification.50likes.title')}`,
      subTitle: `${t('notifications.notification.50likes.subtitle')}`,
      description: `${t('notifications.notification.50likes.description')}`,
    },
    Reached10Stars: {
      title: `${t('notifications.notification.10stars.title')}`,
      subTitle: `${t('notifications.notification.10stars.subtitle')}`,
      description: `${t('notifications.notification.10stars.description')}`,
    },
  };
  const { type } = useParams();
  return (
    <MainWrapper style={{ padding: '1rem' }}>
      {type === 'Reached100Views' && (
        <Title>{notificationViews.Reached100Views.title}</Title>
      )}
      {type === 'Reached50Likes' && (
        <Title>{notificationViews.Reached50Likes.title}</Title>
      )}
      {type === 'Reached10Stars' && (
        <Title>{notificationViews.Reached10Stars.title}</Title>
      )}
      <Info>
        {t('notifications.notification.info', {
          name: user?.firstName || user?.company,
        })}
      </Info>
      {type === 'Reached100Views' && (
        <Description>{notificationViews.Reached100Views.subTitle}</Description>
      )}
      {type === 'Reached50Likes' && (
        <Description>{notificationViews.Reached50Likes.subTitle}</Description>
      )}
      {type === 'Reached10Stars' && (
        <Description>{notificationViews.Reached10Stars.subTitle}</Description>
      )}
      {type === 'Reached100Views' && (
        <Info>{notificationViews.Reached100Views.description}</Info>
      )}
      {type === 'Reached50Likes' && (
        <Info>{notificationViews.Reached50Likes.description}</Info>
      )}
      {type === 'Reached10Stars' && (
        <Info>{notificationViews.Reached10Stars.description}</Info>
      )}
      <Info>
        {t('notifications.notification.support')}&nbsp;
        <span
          style={{
            textDecoration: 'underline',
            color: '#487BD9',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            window.location.href = 'mailto:info@my1job.be';
            e.preventDefault();
          }}
        >
          info@my1job.be
        </span>
      </Info>
    </MainWrapper>
  );
}
