/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Dayjs } from 'dayjs';
import { getMonth } from '../getMonth';

export const formatDateWithNumbers = (
  startMonth?: number | null,
  startYear?: number | null,
  endMonth?: number | null,
  endYear?: number | null
): string => {
  let output: string = '';

  if (startMonth && startYear) output += `${getMonth(startMonth)} ${startYear}`;
  if (endMonth && endYear) output += ` - ${getMonth(endMonth)} ${endYear}`;

  return output;
};

export const formatDate = (
  startMonth?: Dayjs | null,
  startYear?: Dayjs | null,
  endMonth?: Dayjs | null,
  endYear?: Dayjs | null
): string => {
  let output: string = '';

  if (startMonth && startYear)
    output += `${startMonth.format('MMM')} ${startYear.year()}`;
  if (endMonth && endYear)
    output += ` - ${endMonth.format('MMM')} ${endYear.year()}`;

  return output;
};
