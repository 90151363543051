import {
  Icon,
  InfosWrapper,
  InfoWrapper,
  Location,
  Name,
  NoIconWrapper,
} from './styles';
import { BusinessRounded } from '@mui/icons-material';
import StudentViewBusiness from '../StudentViewBusiness/StudentViewBusiness';
import { useState } from 'react';

export default function RightSideStudentItem({ company }: any) {
  const [openBusinessModal, setOpenBusinessModal] = useState<boolean>(false);
  return (
    <>
      <InfosWrapper onClick={() => setOpenBusinessModal(true)}>
        {company.profilePicture ? (
          <Icon src={company.profilePicture} />
        ) : (
          <NoIconWrapper>
            <BusinessRounded sx={{ color: 'white' }} />
          </NoIconWrapper>
        )}
        <InfoWrapper>
          <Name>{company.name}</Name>
          <Location>{company.country}</Location>
        </InfoWrapper>
      </InfosWrapper>
      <StudentViewBusiness
        open={openBusinessModal}
        setOpen={setOpenBusinessModal}
        id={company.userId}
      />
    </>
  );
}
