import { Box } from '@mui/material';
import styled from 'styled-components';

export const VideoWrapperStyle = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 20px;
  }
  & > div {
    border-radius: 20px;
    overflow: hidden;
  }
`;
