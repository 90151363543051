import { Box, Typography, Button } from '@mui/material';
import styled from 'styled-components';

export const MainWrapper = styled(Box)`
  display: inline-grid;

  margin-top: 1rem;
  grid-template-columns: 500px 1fr;
  grid-gap: 100px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: inline-flex;
    flex-direction: column;
    grid-gap: 10px;
    div:nth-child(2) {
      text-align: center;
    }
  }
`;

export const Description = styled(Box)``;

export const Text = styled(Typography)`
  font-size: 14px;
  text-align: justify;
  white-space: pre-line;

  color: #b1b1b1;
`;

export const ContactButton = styled(Button)`
  background: #2e98fa;
  text-transform: none;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    align-items: center;
  }
`;
