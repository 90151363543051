import { Button, Typography } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { FileName } from '../UploadCV/styles';
import { UserInputs } from '../../../../interfaces/types/User';
import { useTranslation } from 'react-i18next';

export function UploadDigitalBook() {
  const {
    register,
    watch,
    formState: {
      errors: { digitalBook },
    },
  } = useFormContext<UserInputs>();
  const file: any = watch('digitalBook')?.[0];
  const { t } = useTranslation();
  return (
    <div style={{ marginTop: '.5rem' }}>
      <Typography component='p' className='font-montserrat font-bold mb-2'>
        {t('digital_book')}
      </Typography>
      <Typography variant='subtitle2' className='font-montserrat mb-2'>
        {t('upload_digital_book_in_pdf')}
      </Typography>
      <Button variant='outlined' component='label' startIcon={<FileUpload />}>
        {t('auth.sign_up.upload_button')}
        <input
          id='cv'
          hidden
          accept='application/pdf'
          type='file'
          {...register('digitalBook')}
        />
      </Button>
      {digitalBook ? (
        <Typography sx={{ color: 'red' }} variant='caption' display='block'>
          <>{digitalBook?.message}</>
        </Typography>
      ) : file ? (
        <FileName>{file.name}</FileName>
      ) : (
        ''
      )}
    </div>
  );
}
