import { Card, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../../../../lib/theme';

const { businessCardBg, position_text_color } = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${businessCardBg};
  align-items: center;
`;

export const Title = styled(Typography)`
  font-weight: bold;
`;

export const InfosWrapper = styled.div`
  margin-top: 0.5rem;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 0.5rem;
  grid-template-columns: 200px 1fr;
  grid-gap: 20px;
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: flex;
    grid-gap: 0;
    flex-direction: column;
  }
`;

export const InfoProp = styled(Typography)`
  color: ${position_text_color};
  font-size: 12px;
`;

export const InfoFromDb = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

export const InfoWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const EditProfileButton = styled(Button)`
  text-transform: none;
  background-color: #2e98fa;
  color: white;
  border-radius: 10px;
  &:hover {
    background-color: #2e98fa;
  }
`;
