import { colors } from './../../../../lib/theme';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';

const { businessCardBg, text_gray } = colors;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Typography)`
  font-family: 'Impact';
  font-size: 30px;
  color: ${businessCardBg};
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 16px;
  }
`;

export const Description = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  word-wrap: break-word;
  width: 80%;
  text-align: center;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
    font-size: 14px;
  }
`;

export const MoreInfoAbout = styled.div`
  width: 100%;
`;
export const MoreInfoAboutItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  &:nth-child(2) {
    img {
      order: 2;
      box-shadow: 300px 0px 0px 50px rgba(138, 212, 227, 0.15);
    }
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
    &:nth-child(2) {
      div {
        order: 2;
      }
    }
  }
`;
export const Image = styled.img`
  height: auto;
  width: 460px;
  box-shadow: -300px 0px 0px 50px rgba(138, 212, 227, 0.15);
  border-radius: 15px;
`;
export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 20px;
  margin: 2rem 0 0 1rem;
`;
export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${businessCardBg};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TitleItem = styled(Typography)`
  font-size: 16px;
  font-weight: bolder;
  color: #535353;
`;

export const DescriptionItem = styled(Typography)`
  font-size: 16px;
  margin-top: 1rem;
  white-space: pre-line;
  color: ${text_gray};
`;

export const DescriptionWrapper = styled.div`
  margin-left: 1rem;
`;

export const VideoWrapperStyle = styled(Box)`
  video {
    aspect-ratio: 16 / 9;
    object-fit: contain;
    border-radius: 20px;
  }
  & > div {
    border-radius: 20px;
    overflow: hidden;
  }
`;
