import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../BootstrapDialog/BootstrapDialog';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { DialogContent } from '@mui/material';
import {
  ButtonsWrapper,
  CancelButton,
  ConfirmButton,
  InfoMessage,
} from './styles';
import { useTranslation } from 'react-i18next';

export default function UnsaveProfileModal({
  setOpen,
  open,
  unSaveProfile,
}: any) {
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          //   height: '200px',
          width: '400px',
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: 20,
        }}
      >
        <ErrorOutlineRoundedIcon
          sx={{
            fontSize: '100px',
            color: '#FDC01C',
            borderRadius: '50%',
            padding: '0.5rem',
          }}
        />
        <InfoMessage>{t('card.unsave_applicant')}</InfoMessage>
      </BootstrapDialogTitle>

      <DialogContent>
        <ButtonsWrapper>
          <ConfirmButton
            variant="contained"
            onClick={() => {
              unSaveProfile();
              handleClose();
            }}
          >
            {t('card.confirm')}
          </ConfirmButton>
          <CancelButton onClick={handleClose}>{t('card.cancel')}</CancelButton>
        </ButtonsWrapper>
      </DialogContent>
    </BootstrapDialog>
  );
}
