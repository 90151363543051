import { useTranslation } from 'react-i18next';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import { MainWrapper, TitleWrapper, TargetedVideosWrapper } from './styles';
import RightSideSingleBusiness from './RightSideSingleBusiness';

export default function RightSideBusiness({ data, isLoading }: any) {
  const { t } = useTranslation();

  return (
    <MainWrapper>
      <TitleWrapper>{t('right_side.latest_presentations')}</TitleWrapper>
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <TargetedVideosWrapper>
          {data?.latestPresentations?.map((presentation: any, index: any) => (
            <RightSideSingleBusiness data={presentation} key={index} />
          ))}
        </TargetedVideosWrapper>
      )}
    </MainWrapper>
  );
}
