import { Button, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { colors } from '../../../../lib/theme';
import PreviewImage from './PreviewImage/PreviewImage';
import { CropOriginalRounded, Clear } from '@mui/icons-material';
import { AllowedTypes, IconClearWrapper, IconsWrapper } from './styles';
import { useFilePreview } from '../../../../hooks/useFilePreview';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';

export function UploadImage() {
  const {
    setValue,
    watch,
    formState: {
      errors: { profilePicture },
    },
  } = useFormContext();
  const { upload_picture_background } = colors;
  const file = watch('profilePicture');
  const { image, onSetImage } = useFilePreview(file);
  const { t } = useTranslation();

  // handlers
  const handleFileSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files && files.length !== 0) setValue('profilePicture', files[0]);
  };

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item>
        <IconsWrapper>
          {image ? (
            <PreviewImage imgSrc={image} />
          ) : (
            <Button
              component='label'
              sx={{
                borderRadius: ' 0.25rem',
                color: 'white',
                padding: '2.5rem',
                backgroundColor: `${upload_picture_background}`,
                boxShadow:
                  '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
              }}
            >
              <input
                hidden
                type='file'
                accept='image/png, image/gif, image/jpeg'
                onChange={handleFileSelect}
              />
              <CropOriginalRounded className='text-white' />
            </Button>
          )}
          <IconClearWrapper
            onClick={() => {
              onSetImage(null);
              setValue('profilePicture', null);
            }}
          >
            <Clear sx={{ padding: '1px' }} />
          </IconClearWrapper>
        </IconsWrapper>
        <AllowedTypes>{t('auth.sign_up.allowed_types')}</AllowedTypes>
      </Grid>
      <Grid item>
        {profilePicture && (
          <Typography sx={{ color: 'red' }} variant='caption' display='block'>
            <>{profilePicture?.message}</>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
