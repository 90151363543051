import { useTranslation } from 'react-i18next';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import ForgotPassword from '../../modals/components/ForgotPassword/ForgotPassword/ForgotPassword';

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      title={t('auth.forgot_password.title')}
      content={<ForgotPassword />}
    />
  );
}
