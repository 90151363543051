import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeedCards from '../../../components/FeedCards/CandidateFeedCards';
import PageTemplate from '../../../components/PageTemplate/PageTemplate';

export default function FeedCandidate() {
  const { t } = useTranslation();
  return (
    <Grid item lg={6} md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate title={t('left_side_bar.feed')} content={<FeedCards />} />
    </Grid>
  );
}
