import { Box, Typography, Button } from '@mui/material';
import styled from 'styled-components';
import img from '../../../../assets/images/register_now.png';

export const Wrapper = styled(Box)`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 400px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    // background-size: contain;
    margin-top: 1rem;
  }
`;

export const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 50px;
  color: white;
  justify-content: center;
  align-items: center;
`;

export const Text = styled(Typography)`
  font-size: 30px;
  margin-top: 2rem;
  text-align: center;
  white-space: pre-line;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 14px;
  }
`;

export const RegisterButton = styled(Button)`
  background: linear-gradient(90deg, #3edafe -25.3%, #1c8af0 77.8%);
  text-transform: none;
  color: white;
  width: 100%;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 200px;
  align-items: center;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100px;
  }
`;
