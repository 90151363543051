import { useMyBusinessProfileSideBarQuery } from '../../../../../rq/hooks/useQueries';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import LeftSideBar from '../../../../../components/LeftSideBar/LeftSideBar';
import { useContext } from 'react';
import { AuthContext } from '../../../../../lib/context/AuthContext';

export default function LeftSideBusinessProfile({ data, isLoading }: any) {
  return (
    <>
      {isLoading && !data ? (
        <CircularIndeterminate />
      ) : (
        <LeftSideBar data={data} />
      )}
    </>
  );
}
