import { useMyProfileSideBarQuery } from './../../rq/hooks/useQueries';
import { useTranslation } from 'react-i18next';
import { Favorite, Visibility, Star, Remove } from '@mui/icons-material';
import { AuthContext } from '../../lib/context/AuthContext';
import { useContext } from 'react';

export const useLinkList = () => {
  const { role } = useContext(AuthContext);
  const { t } = useTranslation();
  const linkList = [
    {
      id: 1,
      href: `/${role}/profile/overview`,
      title: `${t('left_side_bar.overview')}`,
      icon: Remove,
    },
    {
      id: 2,
      href: `/${role}/profile/feed`,
      title: `${t('left_side_bar.feed')}`,
      icon: Remove,
    },

    {
      id: 4,
      href: `/${role}/profile/liked-profiles`,
      title: `${t('left_side_bar.liked_videos')}`,
      icon: Remove,
    },
    {
      id: 5,
      href: `/${role}/profile/interested-on-you`,
      title: `${t('left_side_bar.interested_on_you')}`,
      icon: Remove,
    },
    {
      id: 6,
      href: `/${role}/profile/settings`,
      title: `${t('left_side_bar.settings')}`,
      icon: Remove,
    },
  ];
  const businessLink = [
    {
      id: 1,
      href: `/${role}/profile/overview`,
      title: `${t('left_side_bar.overview')}`,
      icon: Remove,
    },
    {
      id: 2,
      href: `/${role}/profile/feed`,
      title: `${t('left_side_bar.feed')}`,
      icon: Remove,
    },

    {
      id: 4,
      href: `/${role}/profile/liked-profiles`,
      title: `${t('left_side_bar.liked_videos')}`,
      icon: Remove,
    },
    {
      id: 3,
      href: `/${role}/profile/saved-profiles`,
      title: `${t('left_side_bar.saved_profiles')}`,
      icon: Remove,
    },
    {
      id: 6,
      href: `/${role}/profile/settings`,
      title: `${t('left_side_bar.settings')}`,
      icon: Remove,
    },
    {
      id: 7,
      href: `/${role}/profile/packets`,
      title: `${t('left_side_bar.history_of_payments')}`,
      icon: Remove,
    },
  ];

  const navList = [
    {
      id: 1,
      href: `/home`,
      title: `${t('home.title')}`,
      icon: Remove,
    },
    {
      id: 2,
      href: `/about`,
      title: `${t('about.title')}`,
      icon: Remove,
    },
    {
      id: 3,
      href: `/contact-us`,
      title: `${t('contact_us.title')}`,
      icon: Remove,
    },
  ];
  return [linkList, businessLink, navList];
};

export const useNoLinkList = () => {
  const { role } = useContext(AuthContext);
  const { data } = useMyProfileSideBarQuery({ enabled: role === 'Candidate' });
  const { t } = useTranslation();
  const noLinkList = [
    {
      id: 1,
      title: `${t('left_side_bar.likes')}`,
      icon: Favorite,
      count: data?.likes ? data?.likes : 0,
    },
    {
      id: 2,
      title: `${t('left_side_bar.views')}`,
      icon: Visibility,
      count: data?.profileViews ? data?.profileViews : 0,
    },
    {
      id: 3,
      title: `${t('left_side_bar.stars')}`,
      icon: Star,
      count: data?.saves ? data?.saves : 0,
    },
  ];

  return [noLinkList];
};
