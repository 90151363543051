import {
  CustomTypography,
  Description,
  FooterItem,
  FooterItemFirst,
  FooterItemSecond,
  FooterSubItemFirst,
  FooterSubItemSecond,
  FooterWrapper,
  Icon,
  IconSocialMedia,
  IconsWrapper,
  Wrapper,
} from './styles';
import icon from '../../assets/icons/my_first_job_icon.png';
import icon_black from '../../assets/icons/my_first_job_icon_black.png';
import { Container, Typography } from '@mui/material';
import fbLogo from '../../assets/icons/facebook-icon.png';
import instaLogo from '../../assets/icons/instagram-icon.png';
import linkedInLogo from '../../assets/icons/linkedin_icon.png';
import ytLogo from '../../assets/icons/youtube_icon.png';
import { useLocation, useNavigate } from 'react-router-dom';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function Footer() {
  const location = useLocation();
  const homePage = location.pathname === '/home';
  const { t } = useTranslation();
  return (
    <FooterWrapper homePage={homePage}>
      <Container>
        <Wrapper>
          <FooterItemFirst>
            <Icon src={homePage ? icon : icon_black} />
            <Description>{t('footer.description')}</Description>
            <IconsWrapper>
              <IconSocialMedia
                onClick={() =>
                  window.open(
                    'https://www.instagram.com/myfirstjob01/',
                    '_blank'
                  )
                }
                src={instaLogo}
              />
              <IconSocialMedia
                onClick={() =>
                  window.open('https://www.facebook.com/my1job.be', '_blank')
                }
                src={fbLogo}
              />
              <IconSocialMedia
                onClick={() =>
                  window.open(
                    'https://www.linkedin.com/company/89610471/admin/',
                    '_blank'
                  )
                }
                src={linkedInLogo}
              />
              <IconSocialMedia
                src={ytLogo}
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/channel/UCXQw5DktyC03RsGBKEHt0_w',
                    '_blank'
                  )
                }
              />
            </IconsWrapper>
          </FooterItemFirst>
          <FooterItemSecond>
            <FooterSubItemFirst>
              <FooterItem to="/home">{t('home.title')}</FooterItem>
              <FooterItem to="/about">{t('about.title')}</FooterItem>
              <FooterItem to="/contact-us">{t('contact_us.title')}</FooterItem>
            </FooterSubItemFirst>
            <FooterSubItemSecond>
              <div>
                <CustomTypography>
                  {t('footer.guidelines')}: {t('footer.companies')}
                </CustomTypography>
                <CustomTypography>
                  {t('footer.guidelines')}: {t('footer.students')}
                </CustomTypography>
              </div>
              <div>
                <CustomTypography>
                  <PrivacyPolicy
                    name={t('auth.sign_up.privacy_policy')}
                    color={homePage ? 'white' : 'black'}
                  />
                </CustomTypography>
                <CustomTypography>
                  Copyright - MyFirstJob - {dayjs().year()}
                </CustomTypography>
              </div>
            </FooterSubItemSecond>
          </FooterItemSecond>
        </Wrapper>
      </Container>
    </FooterWrapper>
  );
}
