import { createContext, ReactElement, useState, useEffect } from 'react';
import { SecretProps } from '../../interfaces/types/AuthContext';

export const PaymentContext = createContext<SecretProps>({
  clientSecret: '',
  setClientSecret: (_clientSecret) => null,
});

export const PaymentProvider = ({ children }: { children: ReactElement }) => {
  const [clientSecret, setClientSecret] = useState<string>('');

  return (
    <PaymentContext.Provider value={{ clientSecret, setClientSecret }}>
      {children}
    </PaymentContext.Provider>
  );
};
