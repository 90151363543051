import { Button, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../lib/theme';

const { text_blue, text_gray } = colors;

export const ImportantInfoWrapper = styled(Typography)`
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
`;

export const AddButton = styled(Button)`
  font-family: 'Montserrat';
  font-weight: bold;
  color: black;
  text-transform: none;
  padding: 0.5rem;
  background-color: #a8e8f5;
  margin-bottom: 0.5rem;
`;

export const DeleteIcon = styled(IconButton)`
  color: red;
  float: right;
`;

export const ItemWrapper = styled.div`
  border: 1px solid ${text_gray};
  border-radius: 15px;
  padding: 0.5rem;
  width: 100%;
  margin: 0 0.5rem 0.5rem 0;
  font-family: 'Montserrat';
  font-size: 12px;
`;
