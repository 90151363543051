import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, SelectChangeEvent } from '@mui/material';
import {
  useFieldsOfStudyQuery,
  useProfessionsQuery,
  useUniversityQuery,
} from '../../../rq/hooks/useQueries';
import { FilterButtonWrapper } from '../styles';
import {
  Filter,
  FilterApplyButton,
  FilterLabel,
  FiltersWrapper,
  FilterWrapper,
} from './styles';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { StyledMenu } from '../../Card/MoreMenu/MoreMenu';
import Select from '../../Select/Select';
import MultiSelect from '../../MultiSelect/MultiSelect';

export default function FilterSearch({
  setProfessionId,
  setEducationId,
  setFieldOfStudyId,
  refetch,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data: universities = [] } = useUniversityQuery();
  const { data: fields_of_study = [] } = useFieldsOfStudyQuery();

  const { data: professions = [] } = useProfessionsQuery();

  const handleSearchEducationId = (e: SelectChangeEvent<any>) => {
    const { target } = e;

    setEducationId(+target.value);
  };
  const handleSearchFieldOfStudyId = (e: SelectChangeEvent<any>) => {
    const { target } = e;
    setFieldOfStudyId(+target.value);
  };
  const { t } = useTranslation();

  const handleSelectSkills = (selectedItems: number[]) => {
    setProfessionId(selectedItems);
  };

  return (
    <FiltersWrapper>
      <FilterButtonWrapper onClick={handleClick}>
        <TuneRoundedIcon sx={{ color: 'white' }} />
      </FilterButtonWrapper>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FilterWrapper style={{ width: '300px', padding: ' 10px 30px' }}>
          <Filter>
            <FilterLabel>{t('filterSearch.university')}</FilterLabel>
            <FormControl fullWidth>
              <Select
                size="small"
                defaultValue=""
                options={universities}
                onChange={handleSearchEducationId}
              />
            </FormControl>
          </Filter>
          <Filter>
            <FilterLabel>{t('filterSearch.field_of_study')}</FilterLabel>
            <FormControl fullWidth>
              <Select
                size="small"
                defaultValue=""
                options={fields_of_study}
                onChange={handleSearchFieldOfStudyId}
              />
            </FormControl>
          </Filter>
          <Filter>
            <FilterLabel>{t('filterSearch.job_type')}</FilterLabel>
            <MultiSelect
              size="small"
              options={professions}
              items={[]}
              onSelect={handleSelectSkills}
              variant="outlined"
            />
          </Filter>
          <FilterApplyButton onClick={refetch}>
            {t('filterSearch.apply')}
          </FilterApplyButton>
        </FilterWrapper>
      </StyledMenu>
    </FiltersWrapper>
  );
}
