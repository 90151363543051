import { DialogContent } from '@mui/material';
import { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../../../components/BootstrapDialog/BootstrapDialog';
import { PayPlanButton } from './styles';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

export default function StripePayment({
  openPayModal,
  setOpenPayModal,
  price,
  points,
  priceWithVat,
}: any) {
  const handleClose = () => {
    setOpenPayModal(false);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>('');

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/business/profile/packets`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setLoading(false);
      setError(error.message);
    } else {
      setOpenPayModal(false);
      setOpenSuccessModal(true);
      setLoading(false);

      // window.location.reload();
      // navigate('/completion');
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPayModal}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {t('payment.title')}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <PayPlanButton
              disabled={!stripe}
              type="submit"
              variant="contained"
              loading={loading}
            >
              {t('payment.button_pay')} €{priceWithVat.toFixed(2)}
            </PayPlanButton>
            {error}
          </form>
        </DialogContent>
      </BootstrapDialog>
      <ConfirmModal
        setOpenSuccessModal={setOpenSuccessModal}
        openSuccessModal={openSuccessModal}
        price={price}
        points={points}
      />
    </>
  );
}
