import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../../../components/PageTemplate/PageTemplate';
import HistoryOfPackage from '../../../modals/components/layouts/business-dashboard/HistoryOfPackage/HistoryOfPackage';

export default function HistoryOfPackagePage() {
  const { t } = useTranslation();
  return (
    <Grid item md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate
        title={t('left_side_bar.history_of_payments')}
        content={<HistoryOfPackage />}
      />
    </Grid>
  );
}
