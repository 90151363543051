import { Container, Grid, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import { useGetCandidateProfileFromBusinessQuery } from '../../../../../rq/hooks/useQueries';
import BasicInformation from './BasicInformation/BasicInformation';
import LeftSideData from './LeftSideData/LeftSideData';
import MoreInformation from './MoreInformation/MoreInformation';

export default function BusinessViewStudent() {
  const { id } = useParams();
  const { data, isLoading } = useGetCandidateProfileFromBusinessQuery(id);
  return (
    <>
      {isLoading && !data ? (
        <CircularIndeterminate />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Container>
            <Grid container spacing={3}>
              <Grid
                item
                lg={3}
                xs={0}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                  },
                }}
              >
                <LeftSideData data={data} />
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'none',
                    md: 'block',
                  },
                }}
              >
                <BasicInformation data={data} />
                <MoreInformation data={data} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
