import React from 'react';
import BillingHistory from './BillingHistory/BillingHistory';
import Plans from './Plans/Plans';

export default function HistoryOfPackage() {
  return (
    <>
      <Plans />
      <BillingHistory />
    </>
  );
}
