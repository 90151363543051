import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { UserInputs } from '../../../../interfaces/types/User';
import { ImportantInfoWrapper, AddButton } from './styles';
import {
  useFieldsOfStudyQuery,
  useLanguageSkillQuery,
  useLanguagesQuery,
  useProfessionsQuery,
  useUniversityQuery,
} from '../../../../rq/hooks/useQueries';
import LanguageKnowledge from '../LanguageKnowledge/LanguageKnowledge';
import StudyInformation from '../StudyInformation/StudyInformation';
import MultiSelect from '../../../../components/MultiSelect/MultiSelect';

export default function ImportantInformation() {
  // local state
  const [openDialog, setOpenDialog] = useState<1 | 2 | null>(null);

  const { data: professionsList = [] } = useProfessionsQuery();
  const { data: languages = [] } = useLanguagesQuery();
  const { data: languageSkillLevels = [] } = useLanguageSkillQuery();
  const { data: educations = [] } = useUniversityQuery();
  const { data: studyFields = [] } = useFieldsOfStudyQuery();

  const {
    control,
    setValue,
    formState: {
      errors: {
        CandidateSelectedEducations,
        CandidateSelectedLanguages,
        professions,
      },
    },
  } = useFormContext<UserInputs>();

  const {
    fields: fieldsLanguages,
    append: appendLanguages,
    remove: removeLanguages,
  } = useFieldArray<UserInputs, 'CandidateSelectedLanguages', 'id'>({
    control,
    name: 'CandidateSelectedLanguages',
  });

  const {
    fields: fieldsEducations,
    append: appendEducations,
    remove: removeEducations,
  } = useFieldArray<UserInputs, 'CandidateSelectedEducations', 'id'>({
    control,
    name: 'CandidateSelectedEducations',
  });

  const { t } = useTranslation();

  const filterLanguages = languages.filter((f: any) =>
    fieldsLanguages.every((e: any) => e.languageId !== f.id)
  );

  const handleOpenDialog = (type: 1 | 2 | null) => () => setOpenDialog(type);

  const handleSelectSkills = (selectedItems: number[]) => {
    setValue('professions', selectedItems, { shouldDirty: true });
  };
  return (
    <>
      <ImportantInfoWrapper>
        {t('register.important_information')}
      </ImportantInfoWrapper>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={6}>
          {fieldsEducations &&
            fieldsEducations.map(
              ({ id, educationId, fieldOfStudyId }, index) => (
                <Chip
                  key={id}
                  label={`${
                    educations.find(
                      (education) => education?.id === educationId
                    )?.name ?? ''
                  } - ${
                    studyFields.find(
                      (studyField) => studyField.id === fieldOfStudyId
                    )?.name ?? ''
                  }`}
                  onDelete={() => removeEducations(index)}
                  sx={{ mr: 1, mb: 1 }}
                />
              )
            )}
          <AddButton type="button" size="small" onClick={handleOpenDialog(1)}>
            {t('auth.sign_up.add_study_information')}
          </AddButton>
          {CandidateSelectedEducations && (
            <Typography sx={{ color: 'red' }} variant="caption" display="block">
              {CandidateSelectedEducations?.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box>
            {fieldsLanguages &&
              fieldsLanguages.map(
                ({ id, languageId, languageSkillLevelId }, index) => (
                  <Chip
                    key={id}
                    label={`${
                      languages.find((language) => language.id === languageId)
                        ?.name ?? ''
                    } - ${
                      languageSkillLevels.find(
                        (languageSkillLevel) =>
                          languageSkillLevel.id === languageSkillLevelId
                      )?.name ?? ''
                    }`}
                    onDelete={() => removeLanguages(index)}
                    sx={{ mr: 1, mb: 1 }}
                  />
                )
              )}
          </Box>
          <AddButton type="button" onClick={handleOpenDialog(2)}>
            {t('auth.sign_up.add_linguistic_knowledge')}
          </AddButton>
          {CandidateSelectedLanguages && (
            <Typography sx={{ color: 'red' }} variant="caption" display="block">
              {CandidateSelectedLanguages?.message}
            </Typography>
          )}
        </Grid>
        <LanguageKnowledge
          open={openDialog === 2}
          languages={filterLanguages}
          languageSkillLevels={languageSkillLevels}
          appendLinguisticKnowledge={appendLanguages}
          onDialogClose={handleOpenDialog(null)}
        />
        <StudyInformation
          open={openDialog === 1}
          educations={educations}
          studyFields={studyFields}
          appendStudyInformation={appendEducations}
          onDialogClose={handleOpenDialog(null)}
        />
        <Grid item xs={12}>
          <MultiSelect
            label={t('auth.sign_up.looking_for_position')}
            fieldError={professions}
            options={professionsList}
            items={[]}
            onSelect={handleSelectSkills}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  );
}
