import { Card, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../lib/theme';

const { text_blue, businessCardBg, position_text_color } = colors;

export const MainWrapper = styled(Card)`
  border: 1px solid transparent;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
`;

export const TitleWrapper = styled(Typography)`
  color: ${businessCardBg};
  font-size: 14px;
  font-weight: bold;
`;

export const CompaniesWrapper = styled.div`
  margin-top: 1rem;
`;
export const InfosWrapper = styled.div`
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;
export const IconWrapper = styled.div`
  margin-right: 0.5rem;
`;
export const InfoWrapper = styled.div``;
export const Name = styled(Typography)`
  :hover {
    text-decoration: underline;
  }
  font-size: 14px;
  font-weight: bold;
`;
export const Location = styled(Typography)`
  font-size: 12px;
`;

export const Icon = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 0.5rem;
`;

export const NoIconWrapper = styled.div`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: 10px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
