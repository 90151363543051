import { Grid, InputAdornment, IconButton, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { UserInputs } from '../../../../interfaces/types/User';
import { InputField } from '../../InputField/InputField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function BasicInformation() {
  const {
    register,
    formState: {
      errors: {
        firstName,
        lastName,
        email,
        password,
        address,
        phoneNumber,
        postalCode,
      },
    },
  } = useFormContext<UserInputs>();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={12} sm={6}>
        <InputField
          label={t('auth.sign_up.name')}
          type='input'
          fullWidth
          size='small'
          {...register('firstName')}
          fieldError={firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          label={t('auth.sign_up.last_name')}
          type='input'
          fullWidth
          size='small'
          {...register('lastName')}
          fieldError={lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          label={t('auth.email')}
          type='input'
          fullWidth
          size='small'
          {...register('email')}
          fieldError={email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          label={t('auth.password')}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          size='small'
          {...register('password')}
          fieldError={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          label='Postal Code'
          type='input'
          fullWidth
          size='small'
          {...register('postalCode')}
          fieldError={postalCode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          label={t('auth.address')}
          type='input'
          fullWidth
          size='small'
          {...register('address')}
          fieldError={address}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          label={t('auth.phone_number')}
          type='input'
          fullWidth
          size='small'
          {...register('phoneNumber')}
          fieldError={phoneNumber}
        />
      </Grid>
    </Grid>
  );
}
