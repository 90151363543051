import { Typography } from '@mui/material';
import styled from 'styled-components';

export const MeetTeamWrapper = styled.div`
  background-color: black;
  color: white;
  padding: 2rem 0 2rem 0;
  margin-top: 16px;
`;

export const Icon = styled.img`
  width: 330px;
  height: 120px;
`;

export const TeamWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    grid-gap: 10px;
    align-items: center;
    flex-direction: column;
  }
`;

export const TeamMember = styled.div``;

export const Avatar = styled.img`
  width: 246px;
  height: 273px;
  border-radius: 10px;
  object-fit: cover;
`;

export const Name = styled(Typography)`
  font-size: 16px;
  margin-top: 1rem;
`;

export const Position = styled(Typography)`
  font-size: 14px;
  color: #b1b1b1;
`;
