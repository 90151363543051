import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const InfoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
    align-items: start;
  }
`;

export const IconWrapper = styled(Box)``;

export const Icon = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 15px;
`;

export const PersonalInfoWrapper = styled(Box)`
  border-right: 2px solid #dfdfdf;
  margin: 0 0 0 1rem;
  padding-right: 1rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    border: none;
  }
`;

export const Name = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

export const Country = styled(Typography)`
  color: #9d9d9d;
  font-size: 14px;
`;

export const Email = styled(Typography)`
  font-size: 12px;
`;

export const CompanyInfoWrapper = styled(Box)`
  margin: 0 0 0 1rem;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0;
  }
`;

export const Info = styled(Typography)`
  font-size: 12px;
`;

export const InfoProp = styled(Typography)`
  font-size: 13px;
  color: #9d9d9d;
  a:hover {
    text-decoration: underline;
  }
`;

export const VideoWrapper = styled(Box)`
  position: relative;
  video {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 20px;
  }
`;
export const Video = styled.video`
  border-radius: 15px;
  width: 100%;
  height: 150px;
  margin-bottom: 0.5rem;
  object-fit: fill;
`;

export const PlayerIconWrapper = styled(Box)`
  position: absolute;
  z-index: 10;
  color: white;
  display: inline-block;
  padding: 5px;
  align-self: center;
  top: 40%;
  left: 45%;
  background: #000000;
  opacity: 0.7;
  border-radius: 50%;
  cursor: pointer;
`;
