import { useState } from 'react';
import { FileWrapper, UploadIcon, FileName, PreviewFile } from '../../styles';
import cv_icon from '../../../../../../../assets/icons/cv-icon.png';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import { useDeleteMotivationLetter } from '../../../../../../../rq/hooks/useEditProfile.mutation';
import { LoadingButton } from '@mui/lab';
import PdfViewer from '../../../../../../../components/PdfViewer/PdfViewer';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

export default function EditMotivationLetter({ userMotivationLetter }: any) {
  const regex = /[^\/]+$/;
  const queryClient = useQueryClient();

  const { mutate: deleteMotivationLetter, isLoading } =
    useDeleteMotivationLetter({
      onSuccess: () => {
        toast.success('Deletion was successfull');
        queryClient.fetchQuery(['profile']);
      },
    });
  const handleDeleteMotivationLetter = () =>
    deleteMotivationLetter({ fileId: userMotivationLetter.candidateFileId });

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <FileWrapper>
        <UploadIcon src={cv_icon} />
        <FileName>{userMotivationLetter.filePath.match(regex)}</FileName>
        <PreviewFile onClick={() => setOpen(true)}>(preview)</PreviewFile>
      </FileWrapper>
      {isLoading ? (
        <LoadingButton loading />
      ) : (
        <Tooltip title="Delete motivation letter">
          <IconButton>
            <DeleteIcon onClick={handleDeleteMotivationLetter} />
          </IconButton>
        </Tooltip>
      )}
      <PdfViewer
        name="Motivation Letter Preview"
        open={open}
        setOpen={setOpen}
        file={userMotivationLetter.filePath}
      />
    </div>
  );
}
