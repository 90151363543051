import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Info = styled(Typography)`
  font-size: 14px;
  white-space: pre-line;
  margin-bottom: 1rem;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  color: #72c2cc;
`;
