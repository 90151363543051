import {
  MainWrapper,
  Title,
} from '../../modals/components/layouts/student-dashboard/EditStudentProfile/styles';
import {
  ContentWrapper,
  FilterButtonWrapper,
  SearchInputWrapper,
  SearchResult,
  SearchResultCount,
} from './styles';
import { Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import FilterSearch from './FilterSearch/FilterSearch';
import { useContext } from 'react';
import { AuthContext } from '../../lib/context/AuthContext';
import { useLocation } from 'react-router-dom';

export default function SearchVideos({
  search,
  setSearch,
  results,
  isSearching,
  setProfessionId,
  setFieldOfStudyId,
  setEducationId,
  refetch,
}: any) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };
  const { pathname } = useLocation();
  const feedPage = pathname === '/Business/profile/feed';
  return (
    <MainWrapper>
      <Title>{t('card.search.title')}</Title>
      <ContentWrapper>
        <SearchInputWrapper>
          <TextField
            size="small"
            fullWidth
            defaultValue={search}
            id="input-with-icon-textfield"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                borderRadius: '10px',
                backgroundColor: '#F9FBFC',
                paddingLeft: '10px',
              },
              disableUnderline: true,
            }}
            variant="standard"
          />
          {user?.businessId && feedPage && (
            <FilterSearch
              setProfessionId={setProfessionId}
              setFieldOfStudyId={setFieldOfStudyId}
              setEducationId={setEducationId}
              refetch={refetch}
            />
          )}
        </SearchInputWrapper>
        {search && (
          <SearchResult>
            <SearchResultCount>
              {isSearching
                ? 'Searching'
                : results === 0
                ? t('card.search.title')
                : `${results}  ${t('card.search.results')}`}
            </SearchResultCount>
          </SearchResult>
        )}
      </ContentWrapper>
    </MainWrapper>
  );
}
