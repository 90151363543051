import { Container, Grid, Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { useMyBusinessProfileSideBarQuery } from '../../../../rq/hooks/useQueries';
import LeftSideBusinessProfile from './LeftSideBusinessProfile/LeftSideBusinessProfile';
import RightSideBusiness from './RightSideBusiness/RightSideBusiness';

export default function BusinessLeftSideNavigation() {
  const { data, isLoading } = useMyBusinessProfileSideBarQuery();
  const location = useLocation();
  const historyOfPackagePage =
    location.pathname === '/Business/profile/packets';
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container>
        <Grid container spacing={3}>
          <Grid
            item
            lg={3}
            md={3}
            xs={0}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
              },
              position: 'sticky',
              top: '60px',
              height: '100%',
            }}
          >
            <LeftSideBusinessProfile data={data} isLoading={isLoading} />
          </Grid>
          <Outlet />
          <Grid
            item
            lg={3}
            xs={0}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
              },
              position: 'sticky',
              top: '60px',
              height: '100%',
            }}
          >
            {!historyOfPackagePage && (
              <RightSideBusiness data={data} isLoading={isLoading} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
