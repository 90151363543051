import Goal from './Goal/Goal';
import HaveQuestion from './HaveQuestion/HaveQuestion';
import MeetTeam from './MeetTeam/MeetTeam';
import Mission from './Mission/Mission';
import Objective from './Objective/Objective';

export default function About() {
  return (
    <>
      <Objective />
      <Mission />
      <Goal />
      {/* <Figures /> */}
      <MeetTeam />
      <HaveQuestion />
    </>
  );
}
