import { Fragment, useContext } from 'react';
import { Grid } from '@mui/material';
import DataVerification from '../../../../../components/DataVerification/DataVerification';
import PageTemplate from '../../../../../components/PageTemplate/PageTemplate';
import { AuthContext } from '../../../../../lib/context/AuthContext';
import VideoPresentation from '../../business-dashboard/VideoPresentation/VideoPresentation';
import BasicInfo from './BasicInfo/BasicInfo';
import MoreInfos from './MoreInfos/MoreInfos';

export default function ProfileInfo() {
  const { user } = useContext(AuthContext);
  const video = user?.videoPresentation;
  return (
    <Grid item lg={6} md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate
        title={
          user?.candidateId
            ? `${user?.firstName} ${user?.lastName}`
            : `${user?.company}`
        }
        content={
          <Fragment>
            <DataVerification />
            <BasicInfo />
            {user?.candidateId ? (
              <MoreInfos />
            ) : (
              <VideoPresentation video={video} />
            )}
          </Fragment>
        }
      />
    </Grid>
  );
}
