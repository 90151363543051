import { forwardRef } from 'react';
import { Select as MUISelect, MenuItem, Typography } from '@mui/material';
import { SelectProps } from './Select.props';

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, defaultValues, fieldError, onDelete, ...rest }, ref) => {
    // destructures
    const { label } = rest;

    return (
      <>
        <MUISelect
          id={`${label}-select`}
          labelId={`${label}-label`}
          inputRef={ref}
          {...rest}
        >
          {options?.map(({ id, name }: any) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </MUISelect>
        {fieldError && (
          <Typography sx={{ color: 'red' }} variant="caption" display="block">
            {fieldError?.message}
          </Typography>
        )}
      </>
    );
  }
);

Select.displayName = 'Select';
export default Select;
