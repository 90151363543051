import { useState, useContext } from 'react';
import CircularIndeterminate from '../../../components/CircularIndeterminate/CircularIndeterminate';
import { AuthContext } from '../../../lib/context/AuthContext';
import CompaniesCards from './CompaniesCards/CompaniesCards';
import CoverSection from './CoverSection/CoverSection';
import HowToCreateAccount from './HowToCreateAccount/HowToCreateAccount';
import RegisterNow from './RegisterNow/RegisterNow';
import StudentsCards from './StudentsCards/StudentsCards';
import VideoWrapper from './VideoWrapper/VideoWrapper';
import WhatItIsComponent from './WhatItIsComponent/WhatItIsComponent';
import { Description, MainWrapper, Title } from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

export default function Home() {
  const { user, isAuthenticated, loading } = useContext(AuthContext);
  const [searchWord, setSearchWord] = useState<string>('');
  const [search, setSearch] = useState<boolean>(false);
  const shouldDisplay = !isAuthenticated || user?.candidateId;
  const { t } = useTranslation();

  if (loading) return <CircularIndeterminate />;
  return (
    <>
      <CoverSection
        setSearchWord={setSearchWord}
        searchWord={searchWord}
        setSearch={setSearch}
        search={search}
      />
      <Container>
        <MainWrapper>
          <Title>{t('home.what_it_is.title')}</Title>
          <Description>
            <Trans
              i18nKey='home.what_it_is.description'
              values={{
                name: `<span>${t('home.what_it_is.name')}</span>`,
              }}
              components={{
                span: (
                  <span style={{ color: '#72C2CC', fontWeight: 'bolder' }} />
                ),
              }}
            />
          </Description>
        </MainWrapper>
      </Container>
      <StudentsCards />
      <WhatItIsComponent />
      <VideoWrapper />
      <RegisterNow />
      <HowToCreateAccount />
      {shouldDisplay && (
        <CompaniesCards searchWord={searchWord} search={search} />
      )}
    </>
  );
}
