import { ChangeEvent, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import {
  NoFileWrapper,
  UploadIcon,
  FileWrapper,
  FileName,
  DiscardButton,
  ButtonsWrapper,
  VideoInfoWrapper,
} from '../../styles';
import video_icon from '../../../../../../../assets/icons/video_icon.png';
import { useForm } from 'react-hook-form';
import { useAddVideoPresenatition } from '../../../../../../../rq/hooks/useEditProfile.mutation';
import { AddVideoPresentationModel } from '../../../../../../../interfaces/types/User';
import { SaveChangesButton } from '../../ProfileDetails/EditProfileDetails/styles';
import EditIcon from '@mui/icons-material/Edit';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { VideoPresentationSchema } from '../../../../../../../validators/EditProfile.validator';
import { useTranslation } from 'react-i18next';

export default function UploadVideoPresentation({ video }: any) {
  const { t } = useTranslation();
  const methods = useForm<AddVideoPresentationModel>({
    resolver: yupResolver(VideoPresentationSchema(t)),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: {
      errors: { file },
    },
  } = methods;
  // react mutation
  const { mutate: addVideoPresentation, isLoading } =
    useAddVideoPresenatition();

  const onSubmit = async (data: AddVideoPresentationModel) => {
    const formData: any = new FormData();
    formData.append('file', data.file, 'video.mp4');
    addVideoPresentation(formData, {
      onSuccess: (res) => {
        toast.success(t('successMessage.admin_verification'));
        setValue('file', '');
      },
    });
  };
  const fileUploaded: any = watch('file');
  const [state, setState] = useState(video?.filePath);
  const handleFileSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files && files.length !== 0) {
      setState(files[0].name);
      setValue('file', files[0]);
    }
  };
  const handleDiscard = () => {
    setValue('file', '');
    setState(video.filePath);
    reset();
  };
  const regex = /[^\/]+$/;
  return (
    <NoFileWrapper>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <VideoInfoWrapper>
          <FileWrapper>
            <UploadIcon src={video_icon} />
            <FileName>{state?.match(regex)}</FileName>
          </FileWrapper>
          {!fileUploaded && (
            <IconButton component="label" sx={{ marginBottom: '0.5rem' }}>
              <input
                id="cv"
                hidden
                accept="video/mp4,video/x-m4v,video/*"
                type="file"
                name="file"
                onChange={handleFileSelect}
              />
              <EditIcon />
            </IconButton>
          )}
        </VideoInfoWrapper>
        {fileUploaded && (
          <ButtonsWrapper>
            <DiscardButton onClick={handleDiscard} variant="contained">
              Discard
            </DiscardButton>
            <SaveChangesButton
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              Save changes
            </SaveChangesButton>
          </ButtonsWrapper>
        )}
      </Box>
      {file && (
        <Typography sx={{ color: 'red' }} variant="caption" display="block">
          <>{file?.message}</>
        </Typography>
      )}
    </NoFileWrapper>
  );
}
