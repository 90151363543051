import { Fragment, useEffect } from 'react';
import {
  FetchDiv,
  TypoFetch,
} from '../EditStudentProfile/MyLikedVideos/styles';
import { useInView } from 'react-intersection-observer';
import { useGetInterestedOnMeQuery } from '../../../../../rq/hooks/useQueries';
import CardSkeleton from '../../../../../components/CardSkeleton/CardSkeleton';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import Card from '../../../../../components/Card/Card';
import { NoCard } from '../../../../../components/Card/styles';
import { useTranslation } from 'react-i18next';

export default function InterestedOnYou() {
  const {
    status,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useGetInterestedOnMeQuery({
    getNextPageParam: (lastPage: any, pages) => {
      if (lastPage.metaData.pageNumber < lastPage.metaData.pageCount) {
        return lastPage.metaData.pageNumber + 1;
      } else {
        return false;
      }
    },
  });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const { t } = useTranslation();
  return (
    <>
      {status === 'loading' ? (
        <CircularIndeterminate />
      ) : (
        <>
          {data?.pages.map((liked, i) => (
            <Fragment key={i}>
              {liked.data.length > 0 ? (
                <>
                  {liked.data.map((l, index) => (
                    <Card
                      key={index}
                      profilePicture={l.profilePicture}
                      candidateUserId={l.candidateUserId}
                      name={l.name}
                      professions={l.profession}
                      lastName={l.lastName}
                      country={l.country}
                      video={l.video}
                      videoViews={l.videoViews}
                      likes={l.likes}
                      saves={l.saves}
                      isMyFavorite={l.isMyFavorite}
                      likedFromMe={l.likedFromMe}
                      refetch={refetch}
                    />
                  ))}
                </>
              ) : (
                <NoCard>{t('card.no_interested_on_you')}</NoCard>
              )}
            </Fragment>
          ))}
          {data && data?.pages[0].data.length > 0 && (
            <FetchDiv ref={ref} onClick={() => fetchNextPage()}>
              {isFetchingNextPage ? (
                <CardSkeleton />
              ) : hasNextPage ? (
                <TypoFetch>{t('card.search.load_more')}</TypoFetch>
              ) : (
                <TypoFetch>{t('card.search.nothing_more')}</TypoFetch>
              )}
            </FetchDiv>
          )}
        </>
      )}
    </>
  );
}
