import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';
import { colors } from '../../../../lib/theme';

const { text_blue } = colors;

export const VerifyNewPassword = styled(LoadingButton)`
  font-family: 'Montserrat';
  font-weight: bold;
  width: 100%;
  color: white;
  text-transform: none;
  background-color: ${text_blue};
`;
