import { CoverContainer, CoverContent, CoverImage } from './styles';
import coverImg from '../../../../assets/icons/homepage_logo.png';

import { useTranslation } from 'react-i18next';

interface SearchProps {
  searchWord: string;
  search: any;
  setSearch: (search: any) => void;
  setSearchWord: (searchWord: string) => void;
}

export default function CoverSection({
  setSearchWord,
  setSearch,
  searchWord,
}: SearchProps) {
  const { t } = useTranslation();
  return (
    <CoverContainer>
      <CoverContent>
        <CoverImage src={coverImg} loading='lazy' />
      </CoverContent>
    </CoverContainer>
  );
}
