import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { StyledEngineProvider } from '@mui/material';
import './styles/main.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './i18n/config';
import { PaymentProvider } from './lib/context/PaymentContext';
import { AxiosError } from 'axios';
import { ErrorResponse } from './interfaces/types/ErrorResponse';
import toast, { Toaster } from 'react-hot-toast';
import { PlayerProvider } from './lib/context/PlayerContext';
import CookiesConsent from './partials/CookiesConsent/CookiesConsent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
    mutations: {
      onError: (error: unknown, _, __) => {
        if (typeof error === 'string') {
          toast.error(error);
          return;
        }
        const { response } = error as AxiosError;
        const { errors } = response?.data as ErrorResponse;
        if (Array.isArray(errors)) {
          return toast.error(errors[0].message);
        } else {
          return toast.error('Something went wrong, Try Again later!');
        }
      },
    },
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <React.Suspense fallback="loading">
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <PlayerProvider>
            <PaymentProvider>
              <App />
            </PaymentProvider>
          </PlayerProvider>
          <Toaster
            position="bottom-left"
            toastOptions={{
              duration: 3000,
              style: { fontFamily: 'Montserrat', fontSize: '12px' },
            }}
          />
          <CookiesConsent />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </StyledEngineProvider>
  </React.Suspense>
  // </React.StrictMode>
);
