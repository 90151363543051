import { Container, Box } from '@mui/material';
import { Title } from '../styles';
import { useTranslation } from 'react-i18next';
import { ContactButton, Description, MainWrapper, Text } from './styles';
import { useNavigate } from 'react-router-dom';

export default function HaveQuestion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: '#f9fdfd', pb: 5 }}>
      <Container>
        <MainWrapper>
          <Title>{t('about.questions.title')}</Title>
          <Description>
            <Text>{t('about.questions.description')}</Text>
            <ContactButton
              variant="contained"
              onClick={() => navigate('/contact-us')}
            >
              {t('about.questions.contact')}
            </ContactButton>
          </Description>
        </MainWrapper>
      </Container>
    </Box>
  );
}
