import { useTranslation } from 'react-i18next';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import Register from '../../modals/components/Register/Register';

export default function RegisterPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate title={t('auth.sign_up.title')} content={<Register />} />
  );
}
