import { createContext, ReactElement, useState } from 'react';
import { PlayerContextProps } from '../../interfaces/PlayerContextProps';

export const PlayerContext = createContext<PlayerContextProps>({
  play: (_playerId: number) => null,
  isPlaying: false,
});

export const PlayerProvider = ({ children }: { children: ReactElement }) => {
  const [playing, setPlaying] = useState<number | null>(null);
  // set playing to the given id
  const play = (playerId: number | null) => setPlaying(playerId);

  // returns true if the given playerId is playing
  const isPlaying = (playerId: number | null) => playerId === playing;

  return (
    <PlayerContext.Provider value={{ play, isPlaying }}>
      {children}
    </PlayerContext.Provider>
  );
};
