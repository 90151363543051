import Box from '@mui/material/Box';
import { BounceLoader } from 'react-spinners';

export default function CircularIndeterminate() {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <BounceLoader size={10} />
    </Box>
  );
}
