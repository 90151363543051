import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MainWrapper, Title, TitleWrapper } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { useGetHistoryOfPaymentQuery } from '../../../../../../rq/hooks/useQueries';
import dayjs from 'dayjs';
import CircularIndeterminate from '../../../../../../components/CircularIndeterminate/CircularIndeterminate';
import { Pagination } from '@mui/material';

export default function BillingHistory() {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);
  const { data, isLoading } = useGetHistoryOfPaymentQuery(page, {
    keepPreviousData: true,
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <MainWrapper>
      <Title>{t('billing_history.title')}</Title>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bolder',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                }}
              >
                {t('billing_history.date')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bolder',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                }}
              >
                {t('billing_history.details')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bolder',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                }}
              >
                {t('billing_history.amount')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bolder',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                }}
              >
                {t('billing_history.download')}
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <CircularIndeterminate />
          ) : (
            <TableBody>
              {data?.data?.map((row: any) => (
                <TableRow key={row.name} sx={{ td: { border: 0 } }}>
                  <TableCell>
                    {dayjs(row.insertedDate).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>{row.packageName}</TableCell>
                  <TableCell>{row.pointsAmount}</TableCell>
                  <TableCell>
                    <a
                      href={row.invoiceInPdf}
                      download
                      style={{ textDecoration: 'underline', color: '#487BD9' }}
                    >
                      {t('billing_history.invoice')}{' '}
                      {dayjs(row.insertedDate).format('MM/DD/YYYY')}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <Pagination
          count={data?.metaData?.pageCount}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          hideNextButton
          hidePrevButton
          sx={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            mt: 2,
          }}
        />
      </TableContainer>
    </MainWrapper>
  );
}
