import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import { Typography, TextField, Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../lib/theme';
import { InputField } from '../../InputField/InputField';

const { text_blue } = colors;

export const CodeField = styled(InputField)``;

export const ConfirmCodeButton = styled(LoadingButton)`
  font-family: 'Montserrat';
  font-weight: bold;
  width: 100%;
  color: white;
  text-transform: none;
  background-color: ${text_blue};
`;

export const DidntGetCode = styled(Button)`
  font-family: 'Montserrat';
  color: ${text_blue};
  text-transform: none;
  &:hover {
    background-color: transparent;
  }
`;
