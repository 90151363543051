import { useTranslation } from 'react-i18next';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import RegisterStudent from '../../modals/components/Register/RegisterStudent/RegisterStudent';

export default function RegisterStudentPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      title={t('auth.sign_up.student')}
      content={<RegisterStudent />}
    />
  );
}
