import { Typography, Box } from '@mui/material';
import styled from 'styled-components';

export const AccountTitle = styled(Typography)`
  font-family: 'Inter';
  font-size: 16px;
  color: #535353;
  font-weight: bold;
`;

export const AccountDescription = styled(Box)`
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: justify;
  color: #9d9d9d;
`;

export const NumberWrapper = styled(Box)`
  background: #edf9fb;
  border: 2px solid #72c2cc;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 25px;
  color: #72c2cc;
  font-family: 'Montserrat';
  font-weight: bold;
`;
