import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, RegisterButton, TextWrapper, Wrapper } from './styles';
import icon from '../../../../assets/icons/register_now_icon.png';
import { useTranslation } from 'react-i18next';

export default function RegisterNow() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TextWrapper>
        <Icon src={icon} />
        <RegisterButton onClick={() => navigate('/register')}>
          {t('home.register.register_now')}
        </RegisterButton>
      </TextWrapper>
    </Wrapper>
  );
}
