import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../lib/theme';

const { border_bottom_color } = colors;

export const ContentWrapper = styled.div``;
export const SearchResult = styled.div`
  border: 1px solid transparent;
  padding: 2px;
  color: #72c2cc;
  text-align: center;
  font-size: 14px;
  border-radius: 10px;
  background-color: #f9fbfc;
  margin-top: 0.5rem;
`;
export const SearchResultCount = styled(Typography)``;

export const SearchInputWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FilterButtonWrapper = styled(Box)`
  background: #72c2cc;
  border-radius: 10px;
  padding: 0.2rem;
  cursor: pointer;
`;
