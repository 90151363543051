import { Box, Button } from '@mui/material';
import {
  NoFileWrapper,
  NoFileTitle,
  UploadIcon,
  TypesOfFile,
  NoFileUploadButtonWrapper,
  NoFile,
  FileWrapper,
  FileName,
  DiscardButton,
  ButtonsWrapper,
} from '../../EditStudentProfile/styles';
import upload_icon from '../../../../../../assets/icons/upload_icon.png';
import cv_icon from '../../../../../../assets/icons/cv-icon.png';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { SaveChangesButton } from '../ProfileDetails/EditProfileDetails/styles';
import { useAddDigitalBookMutation } from '../../../../../../rq/hooks/useEditProfile.mutation';

export default function UploadDigitalBook() {
  const methods = useForm<any>();
  const { register, handleSubmit, watch, setValue } = methods;
  const { t } = useTranslation();

  const { mutate: addDigitalBook, isLoading } = useAddDigitalBookMutation({
    onSuccess: () => {
      toast.success(t('successMessage.admin_verification'));
    },
  });

  const onSubmit = async (data: any) => {
    const formData: any = new FormData();
    formData.append('file', data.file?.[0]);
    addDigitalBook(formData);
  };
  const file: any = watch('file')?.[0];
  return (
    <>
      <NoFileWrapper>
        <NoFileTitle>{t('upload_digital_book')}</NoFileTitle>
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
          {file ? (
            <>
              <FileWrapper>
                <UploadIcon src={cv_icon} />
                <FileName>{file.name}</FileName>
              </FileWrapper>
              <ButtonsWrapper>
                <DiscardButton
                  variant='contained'
                  onClick={() => setValue('file', '')}
                >
                  {t('discard')}
                </DiscardButton>
                <SaveChangesButton
                  loading={isLoading}
                  type='submit'
                  variant='contained'
                >
                  {t('save_changes')}
                </SaveChangesButton>
              </ButtonsWrapper>
            </>
          ) : (
            <NoFile>
              <Button component='label' sx={{ marginBottom: '0.5rem' }}>
                <NoFileUploadButtonWrapper>
                  <input
                    id='digital_book'
                    hidden
                    accept='application/pdf'
                    type='file'
                    {...register('file')}
                  />
                  <UploadIcon src={upload_icon} />
                  <TypesOfFile>PDF</TypesOfFile>
                </NoFileUploadButtonWrapper>
              </Button>
            </NoFile>
          )}
        </Box>
      </NoFileWrapper>
    </>
  );
}
